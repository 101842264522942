import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Panel, IconButton, Loader } from 'react-ui-kit-exante';

import { useCreatePersonMutation, useLazyGetApplicationTagsQuery } from 'api';
import { TCreatePersonParams } from '~/api/applications/applications.types';
import { EntryScreenWrapper } from '~/components/EntryScreen';
import { StyledTitle } from '~/pages/ApplicationEntry/components/EnrtyScreenStructure/Section/Section.styled';
import { TDefaultValue } from '~/pages/ApplicationEntry/contexts/FormContext/ApplicationFormContext.types';
import {
  getDefaultFormValues,
  mapTagData,
} from '~/pages/ApplicationEntry/contexts/FormContext/helpers';
import { TParams } from '~/router/router.types';
import { TDefaultFormValues } from '~/types/form';
import { getDirtyValues } from '~/utils/forms/getDirtyValues';

import {
  DEFAULT_STATIC_PERSON_FORM,
  SECTIONS_FOR_PERSON,
} from './CreatePerson.constants';
import {
  StyledCreatePersonActions,
  StyledCreatePersonForm,
  StyledSectionBody,
} from './CreatePerson.styled';

export const CreatePerson = () => {
  const { applicationId } = useParams<TParams>();

  const navigate = useNavigate();
  const [createPerson, createPersonState] = useCreatePersonMutation();
  const [getApplicationTagsQuery, state] = useLazyGetApplicationTagsQuery();

  const tagsData = state.data;
  // changed tag blocks according to design
  const preparedTagsData = tagsData && [
    tagsData[2],
    tagsData[3],
    tagsData[0],
    tagsData[1],
  ];

  useEffect(() => {
    if (applicationId) {
      getApplicationTagsQuery({
        objectId: applicationId,
        sectionName: SECTIONS_FOR_PERSON,
      });
    }
  }, []);

  const allDefaultTags = tagsData?.map(({ tags }) => tags).flat() || [];

  const defaultValues = getDefaultFormValues([
    ...DEFAULT_STATIC_PERSON_FORM,
    ...allDefaultTags,
  ]);

  const methods = useForm<TDefaultFormValues>({
    defaultValues,
  });

  const {
    handleSubmit,
    getValues,
    formState: { dirtyFields, isDirty },
  } = methods;

  const handleOnClose = () => navigate(-1);

  const onSave = async () => {
    const formData = getDirtyValues(dirtyFields, getValues());
    const tagsKeys = Object.keys(formData);
    const tagsForSave = state.data
      ?.flatMap(({ tags }) => tags)
      .filter(({ name }) => tagsKeys.includes(name));

    const tagData =
      tagsForSave?.map((tag) =>
        mapTagData({
          tag,
          value: formData[tag.name] as TDefaultValue,
          tagNameProp: 'tag',
        }),
      ) || [];

    const data: TCreatePersonParams = {
      application: applicationId || '',
      tagvalues: tagData,
    };

    const affiliatedType = formData.affiliated_type;
    const firstName = formData.first_name;
    const lastName = formData.last_name;

    if (typeof affiliatedType === 'string') {
      data.affiliated_type = affiliatedType;
    }
    if (typeof firstName === 'string') {
      data.first_name = firstName;
    }
    if (typeof lastName === 'string') {
      data.last_name = lastName;
    }

    const response = await createPerson({ data });
    if ('data' in response) {
      handleOnClose();
    }
  };

  if (state.isLoading) {
    return (
      <EntryScreenWrapper>
        <Loader size={32} isCentered />
      </EntryScreenWrapper>
    );
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSave)}>
        <Panel
          title="Create Person"
          action={
            <StyledCreatePersonActions className="CreatePersonActions">
              <IconButton
                title="Save withdrawal data"
                label="Save"
                type="submit"
                iconName="SaveIcon"
                iconColor="action"
                iconSize={24}
                disabled={!isDirty || createPersonState.isLoading}
              />
              <IconButton
                type="button"
                iconSize={32}
                iconName="CloseIcon"
                iconColor="promo"
                onClick={handleOnClose}
              />
            </StyledCreatePersonActions>
          }
        >
          {preparedTagsData?.map(({ tags = [], title, name }) => {
            return (
              <StyledCreatePersonForm key={name} className="CreatePersonForm">
                {(title || name === 'sumsub_liveness_check') && (
                  <StyledTitle className="StyledTitle">
                    {name === 'sumsub_liveness_check'
                      ? 'Liveness Check'
                      : title}
                  </StyledTitle>
                )}
                <StyledSectionBody
                  className="SectionBody"
                  withoutText
                  tags={
                    name === 'person'
                      ? [...DEFAULT_STATIC_PERSON_FORM, ...tags]
                      : tags
                  }
                />
              </StyledCreatePersonForm>
            );
          })}
        </Panel>
      </form>
    </FormProvider>
  );
};
