import { useLocation, useNavigate } from 'react-router-dom';

import { useGetApplicationQuery } from '~/api';
import { PATHS } from '~/router';
import { TLocationState } from '~/types/location';
import { paramsSerializer } from '~/utils/apiRequest/helpers';

import { DepositCreateFormProvider, DepositForm } from '../DepositForm';

import { initialDeposit } from './DepositCreateEntry.constants';
import { StyledRecordEntryForm } from './DepositCreateEntry.styled';
import { DepositCreateEntryProps } from './DepositCreateEntry.types';

export const DepositCreateEntry = ({ ids }: DepositCreateEntryProps) => {
  const [id, crmId] = ids.split('_');
  const { data } = useGetApplicationQuery({ id, crmId });

  const applicationId = data?.crm.application.id;
  const fullName = data?.crm.full_name;
  const accountChoices = data?.crm.application.accounts;
  const accounts = data?.crm.accounts;

  const deposit = applicationId
    ? {
        ...initialDeposit,
        application: applicationId,
        account_choices: accountChoices,
        client_name: fullName,
      }
    : null;

  const navigate = useNavigate();
  const location = useLocation();

  const { requestParams } = (location.state as TLocationState) || {};

  const onCloseEntry = () => {
    const { sorting, limit, skip, page, ...rest } = requestParams || {};
    if (ids) {
      return navigate(-1);
    }

    navigate(`${PATHS.DEPOSIT_ENTRY}?${paramsSerializer(rest)}`);
  };

  return (
    deposit && (
      <DepositCreateFormProvider
        initialDeposit={deposit}
        onCloseEntry={onCloseEntry}
        accounts={accounts ?? {}}
      >
        <StyledRecordEntryForm className="RecordEntryForm">
          <DepositForm deposit={deposit} onCloseEntry={onCloseEntry} />
        </StyledRecordEntryForm>
      </DepositCreateFormProvider>
    )
  );
};
