import { ChangeEvent, memo, useCallback, useEffect, useState } from 'react';
import { IconButton } from 'react-ui-kit-exante';

import { ActionWithConfirmationWithSelect } from '~/components/ConfirmationComponents/ActionWithConfirmationWithSelect';
import { IOption } from '~/types/form/options';

import {
  StyledApplicationClosedForm,
  StyledAutocomplete,
  StyledCommentInput,
  StyledSelect,
} from './ActionButtonWithConfirmationWithSelect.styled';
import { ActionButtonWithConfirmationWithSelectProps } from './ActionButtonWithConfirmationWithSelect.types';

const ActionButtonWithConfirmationWithSelect = (
  props: ActionButtonWithConfirmationWithSelectProps,
) => {
  const {
    defaultSelectOptions,
    defaultCommentValue,
    isLoadingActions,
    handleConfirm,
    childrenStaticProps: childrenProps,
    autocompleteStaticProps,
    selectStaticProps,
    commentStaticProps,
    options,
    ...otherProps
  } = props;

  const [commentValue, setCommentValue] = useState('');
  const [selectedOption, setSelectedOption] = useState<IOption[]>();

  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  useEffect(() => {
    if (defaultSelectOptions) {
      setSelectedOption(defaultSelectOptions);
    }
  }, [defaultSelectOptions]);

  useEffect(() => {
    if (defaultCommentValue) {
      setCommentValue(defaultCommentValue);
    }
  }, [defaultCommentValue]);

  const openHandler = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setIsOpen(true);
    setAnchorEl(event.currentTarget);
  }, []);

  const onChangeAutocompleteHandler = useCallback(
    (_: any, value: IOption | IOption[]) => {
      const normalizedValue = Array.isArray(value) ? value : [value];

      setSelectedOption(normalizedValue);
    },
    [],
  );

  const onChangeSelectHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      const normalizedValue = Array.isArray(value)
        ? value.map((item) => ({ label: item, value: item }))
        : [{ label: value, value }];

      setSelectedOption(normalizedValue);
    },
    [],
  );

  const detailsHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setCommentValue(e.target.value),
    [],
  );

  const onClose = useCallback(() => {
    setIsOpen(false);
    setAnchorEl(null);
    setSelectedOption(undefined);
    setCommentValue('');
  }, []);

  const onConfirm = useCallback(() => {
    handleConfirm(commentValue, selectedOption);
    onClose();
  }, [commentValue, handleConfirm, onClose, selectedOption]);

  const renderBlockConfirm = (
    <StyledApplicationClosedForm className="ApplicationClosedForm">
      {autocompleteStaticProps && (
        <StyledAutocomplete
          {...autocompleteStaticProps}
          value={
            autocompleteStaticProps.isMultiple
              ? selectedOption
              : selectedOption?.[0]
          }
          onChange={onChangeAutocompleteHandler}
          aria-required
          options={options ?? []}
        />
      )}
      {selectStaticProps && (
        <StyledSelect
          {...selectStaticProps}
          value={
            selectStaticProps.multiple
              ? selectedOption?.map(({ value }) => value)
              : selectedOption?.[0].value
          }
          onChange={onChangeSelectHandler}
          aria-required
          options={options ?? []}
        />
      )}
      {commentStaticProps && (
        <StyledCommentInput
          {...commentStaticProps}
          value={commentValue}
          onChange={detailsHandler}
        />
      )}
    </StyledApplicationClosedForm>
  );

  return (
    <ActionWithConfirmationWithSelect
      {...otherProps}
      open={isOpen}
      anchorEl={anchorEl}
      onClose={onClose}
      content={renderBlockConfirm}
      handleConfirm={onConfirm}
      handleClose={onClose}
      disabledConfirm={isLoadingActions || !selectedOption}
    >
      <IconButton
        {...childrenProps}
        onClick={openHandler}
        iconSize={32}
        disabled={isLoadingActions}
      />
    </ActionWithConfirmationWithSelect>
  );
};

export const ActionButtonWithConfirmationWithSelectMemoized = memo(
  ActionButtonWithConfirmationWithSelect,
);
