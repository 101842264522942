import { useContext } from 'react';
import { IconButton } from 'react-ui-kit-exante';

import {
  useRequestStatusActionMutation,
  useRequestStatusPersonActionMutation,
} from 'api';
import { ApplicationContext } from '~/pages/ApplicationEntry/contexts/ApplicationContext';

import { TRequestStatusAction } from './RequestStatusAction.types';

export const RequestStatusAction = ({
  action,
  disabled,
  personId,
}: TRequestStatusAction) => {
  const { application } = useContext(ApplicationContext);
  const [
    sendRequestApplicationStatus,
    { isLoading: isLoadingApplicationRequest },
  ] = useRequestStatusActionMutation();
  const [sendRequestPersonalStatus, { isLoading: isLoadingPersonalRequest }] =
    useRequestStatusPersonActionMutation();

  const applicationId = application?.application.id;

  const handleOnSend = () => {
    if (applicationId) {
      return sendRequestApplicationStatus({ applicationId, type: action });
    }

    if (personId) {
      return sendRequestPersonalStatus({ personId, type: action });
    }

    return undefined;
  };

  const isDisabled =
    isLoadingApplicationRequest || isLoadingPersonalRequest || disabled;

  return (
    <IconButton
      iconSize={24}
      iconColor="action"
      onClick={handleOnSend}
      iconName="CircleSendIcon"
      label={
        action === 'check_accuity'
          ? 'Request Accuity Status'
          : 'Request Sum&Sub Status'
      }
      disabled={isDisabled}
      data-test-id={`${action}--reques-status-button`}
    />
  );
};
