import { FC } from 'react';

import { ReactComponent as RadioActiveIcon } from '../../../assets/RadioActiveButton.svg';
import { ReactComponent as RadioIcon } from '../../../assets/RadioButton.svg';
import { DefaultThemeProvider } from '../../../theme';

import { RadioStyled } from './Radio.styled';
import { IRadioProps } from './types';

export const Radio: FC<IRadioProps> = (props) => {
  return (
    <DefaultThemeProvider>
      <RadioStyled
        checkedIcon={<RadioActiveIcon />}
        icon={<RadioIcon />}
        {...props}
      />
    </DefaultThemeProvider>
  );
};
