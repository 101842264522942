import { useContext, useEffect } from 'react';
import { Table } from 'react-ui-kit-exante';

import { useLazyGetRiskScoringTablesQuery } from 'api';
import { useCallbackTriggerHandle, useLogHandleTime } from '~/hooks';
import { ApplicationContext } from '~/pages/ApplicationEntry/contexts/ApplicationContext';

import { COLUMNS, TABLE_ID } from './RiskScoringTable.constants';
import {
  StyledScoringTablesTitle,
  StyledScoringRiskLevel,
  StyledScoringRiskTableFooter,
} from './RiskScoringTable.styled';
import { TRiskScoringTableProps } from './RiskScoringTable.types';

export const RiskScoringTable = ({
  refresh,
  legalEntity,
  setIsDisabled,
}: TRiskScoringTableProps) => {
  const { setStartHandleTime, logHandleTime } = useLogHandleTime(
    `contracts-entry-${TABLE_ID}`,
  );
  const { application } = useContext(ApplicationContext);
  const [getRiskScoringTables, { data, isLoading, isFetching, isSuccess }] =
    useLazyGetRiskScoringTablesQuery();

  const applicationData = application?.application;
  const applicationId = applicationData?.id;
  const risksJson = data?.risks_json;
  const riskLevel = data?.risk_level;
  const riskLevelDisplay = data?.risk_level_display;
  const initialData = risksJson?.initial;
  const ongoingData = risksJson?.ongoing;
  const initialTableData = initialData?.items || [];
  const ongoingTableData = ongoingData?.items || [];
  const isLoadingData = isLoading || isFetching;

  useEffect(() => {
    if (isLoading) {
      setStartHandleTime();
    }
  }, [isLoading, setStartHandleTime]);

  useEffect(() => {
    if (applicationId) {
      getRiskScoringTables({
        applicationId,
        legalEntity,
      });
    }
  }, [legalEntity, refresh]);

  useEffect(() => {
    setIsDisabled(isLoadingData);
  }, [isLoadingData]);

  useCallbackTriggerHandle({
    cb: logHandleTime,
    dataTrigger: risksJson,
    processTrigger: !isLoading && isSuccess,
  });

  return (
    <>
      <StyledScoringTablesTitle className="ScoringTablesTitle">
        {legalEntity}
      </StyledScoringTablesTitle>
      <Table
        manualSortBy
        isFlexLayout
        disableSortBy
        columns={COLUMNS}
        tableId={TABLE_ID}
        isHiddenColumnSelect
        showTableInfo={false}
        data={initialTableData}
        isLoading={isLoadingData}
        skeletonsCount={initialTableData.length}
      />
      <StyledScoringRiskTableFooter className="ScoringRiskTableFooter">
        <div>Total</div>
        <div>{initialData?.total}</div>
      </StyledScoringRiskTableFooter>
      <Table
        manualSortBy
        isFlexLayout
        disableSortBy
        tableId={TABLE_ID}
        columns={COLUMNS}
        isHiddenColumnSelect
        showTableInfo={false}
        data={ongoingTableData}
        isLoading={isLoadingData}
        skeletonsCount={ongoingTableData.length}
      />
      <StyledScoringRiskTableFooter className="ScoringRiskTableFooter">
        <div>Total</div>
        <div>{ongoingData?.total}</div>
      </StyledScoringRiskTableFooter>
      {riskLevelDisplay && riskLevel && (
        <StyledScoringRiskLevel className="ScoringRiskLevel">{`Risk level: ${riskLevelDisplay} (${riskLevel})`}</StyledScoringRiskLevel>
      )}
    </>
  );
};
