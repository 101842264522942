import { Button } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledRowContainer = styled('div')`
  display: flex;
  gap: 24px;
  margin-bottom: 24px;

  .MuiRadioGroup-row {
    gap: 16px;
    .MuiFormControlLabel-root {
      margin-right: 0;
      .MuiFormControlLabel-label {
        margin-right: 0;
      }
    }
  }
`;

export const StyledButton = styled(Button)`
  text-transform: none;
  white-space: nowrap;
`;

export const StyledCommentCell = styled('div')`
  width: 100%;
  display: inline-flex;
  flex-wrap: wrap;
  white-space: normal;
  gap: 4px;
`;
