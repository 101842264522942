import { FC, useContext } from 'react';

import { StaticSectionKey } from '../../../ApplicationEntry.constants';
import { ApplicationFormContext } from '../../../contexts/FormContext';
import { SectionBody } from '../SectionBody';

import {
  HIDDEN_TITLES,
  mapSectionKeyToStaticComponent,
} from './Section.constants';
import { StyledSection, StyledTitle } from './Section.styled';
import { TSectionProps } from './Section.types';

export const Section: FC<TSectionProps> = ({ section, tags }) => {
  const sectionName = section.name;
  const sectionIsStatic = section.resourcetype === StaticSectionKey;
  const title = section.title || '';

  const { newTags } = useContext(ApplicationFormContext);

  const tagKey = section?.children?.[0].name;

  const newTagsForSection =
    tagKey && newTags && tagKey in newTags && sectionName
      ? newTags[tagKey]
      : [];

  const allTags = [...newTagsForSection, ...(tags ?? [])];

  return (
    <StyledSection className="Section">
      {!HIDDEN_TITLES.includes(title) && title && (
        <StyledTitle className="StyledTitle">{title}</StyledTitle>
      )}
      {sectionIsStatic &&
        sectionName &&
        mapSectionKeyToStaticComponent[sectionName]}
      {allTags && <SectionBody tags={allTags} tagKey={tagKey} />}
    </StyledSection>
  );
};
