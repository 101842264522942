import { useCallback, useContext, useEffect, useMemo } from 'react';
import {
  IOnFetchArguments,
  Table,
  useTableData,
  calculateCountOfPages,
} from 'react-ui-kit-exante';

import { useLazyGetApplicationTransactionsQuery } from '~/api';
import { useCallbackTriggerHandle, useLogHandleTime } from '~/hooks';
import usePrevious from '~/hooks/usePrevious';
import { ApplicationContext } from '~/pages/ApplicationEntry/contexts/ApplicationContext';
import { getPaginationParams, getDefaultPagination } from '~/utils/table';

import { TABLE_ID } from './TransactionsTable.constants';
import { getColumns } from './TransactionsTable.helpers';
import { TransactionsTableProps } from './TransactionsTable.types';

export const TransactionsTable = ({
  selectedAccountId,
  canView,
}: TransactionsTableProps) => {
  const { setStartHandleTime, logHandleTime } = useLogHandleTime(
    `application-entry-${TABLE_ID}`,
  );
  const { application, activeLegalEntity } = useContext(ApplicationContext);

  const applicationId = application?.application?.id;
  const isAllSelected = selectedAccountId === 'All';
  const prevAccountId = usePrevious(selectedAccountId);
  const isNewAccountId = prevAccountId !== selectedAccountId;

  const [
    getApplicationTransactions,
    {
      isFetching: isGetApplicationTransactionsFetching,
      isLoading: isGetApplicationTransactionsLoading,
      isSuccess: isGetApplicationTransactionsSuccess,
    },
  ] = useLazyGetApplicationTransactionsQuery();

  useEffect(() => {
    if (isGetApplicationTransactionsLoading) {
      setStartHandleTime();
    }
  }, [isGetApplicationTransactionsLoading, setStartHandleTime]);

  const getTransactions = useCallback(
    async (params: IOnFetchArguments) => {
      const { iDisplayPage: pageNumber, iDisplayLength: pageSize } =
        getPaginationParams(params, true);

      if (applicationId) {
        const accountId = isAllSelected ? undefined : selectedAccountId;

        const response = await getApplicationTransactions({
          applicationId,
          accountId,
          legalEntity: activeLegalEntity,
          pageSize,
          page: pageNumber && !isNewAccountId ? pageNumber + 1 : 1,
        });

        return response.data;
      }

      return null;
    },
    [
      activeLegalEntity,
      applicationId,
      getApplicationTransactions,
      isAllSelected,
      selectedAccountId,
      isNewAccountId,
    ],
  );

  const tableDataArgs = useMemo(
    () => ({
      tableId: TABLE_ID,
      data: { onFetch: getTransactions },
      pagination: {
        getDefaultPagination,
      },
    }),
    [getTransactions],
  );

  const { data, limit, setLimit, setPage, page, skip, isLoading } =
    useTableData(tableDataArgs);

  const transactionsData = data?.results ?? [];

  const total = data?.count ?? 0;

  const serverPaginationProps = useMemo(
    () => ({
      pageSize: limit,
      skip,
      setPage,
      setPageSize: setLimit,
      pageIndex: page,
      total,
      pageCount: calculateCountOfPages(total, limit),
    }),
    [skip, limit, page, setLimit, setPage, total],
  );

  const columns = useMemo(
    () => getColumns(isAllSelected, canView),
    [isAllSelected, canView],
  );

  useEffect(() => {
    if (isNewAccountId) {
      setPage(0);
    }
  }, [isNewAccountId]);

  useCallbackTriggerHandle({
    cb: logHandleTime,
    dataTrigger: data?.results,
    processTrigger:
      !isGetApplicationTransactionsLoading &&
      isGetApplicationTransactionsSuccess,
  });

  return (
    <Table
      className="TransactionsSubTabTable"
      columns={columns}
      tableId={TABLE_ID}
      data={transactionsData}
      isLoading={isLoading || isGetApplicationTransactionsFetching}
      hasPagination
      manualSortBy
      serverPaginationProps={serverPaginationProps}
    />
  );
};
