import { useMemo, useState } from 'react';

import { TWithdrawalsData } from '~/api/withdrawals/withdrawals.types';
import {
  ActionButton,
  ActionButtonProps,
  ActionButtonType,
} from '~/modules/actionButtonsBlock/ActionButton';

import {
  ActionButtonWithConfirmationEnum,
  ActionButtonWithConfirmationWithSelectEnum,
  ActionIconButtonEnum,
} from '../WithdrawalHeader.types';

import { useGetButtonWithConfirmationProps } from './useGetButtonWithConfirmationProps';
import { useGetButtonWithConfirmationWithSelectProps } from './useGetButtonWithConfirmationWithSelectProps';
import { useGetIconButtonProps } from './useGetIconButtonProps';

export const useGetActions = (withdrawal: TWithdrawalsData) => {
  const [isLoadingActions, setIsLoadingActions] = useState(false);

  const loadingActionsHandler = (isLoading: boolean) =>
    setIsLoadingActions(isLoading);

  const {
    type: withdrawalType,
    currency,
    actions: withdrawalActions,
    is_crypto_withdrawn_waiting_result: isCryptoWithdrawnWaitingResult,
    is_crypto_withdrawn: isCryptoWithdrawn,
  } = withdrawal;

  const isLoading =
    isLoadingActions || isCryptoWithdrawnWaitingResult || isCryptoWithdrawn;

  const { getIconButtonProps } = useGetIconButtonProps(
    isLoading,
    loadingActionsHandler,
  );
  const { getButtonWithConfirmationProps } = useGetButtonWithConfirmationProps(
    withdrawalType ?? '',
    currency ?? '',
    isLoading,
    loadingActionsHandler,
  );

  const { getButtonWithConfirmationWithSelectProps } =
    useGetButtonWithConfirmationWithSelectProps(
      isLoading,
      loadingActionsHandler,
    );

  const actions = [
    withdrawalActions?.withdraw
      ? {
          key: ActionButtonWithConfirmationEnum.WithdrawBtn,
          type: ActionButtonType.WithConfirmationBtn,
          props: getButtonWithConfirmationProps(
            ActionButtonWithConfirmationEnum.WithdrawBtn,
          ),
        }
      : null,
    withdrawalActions?.on_hold
      ? {
          key: ActionButtonWithConfirmationWithSelectEnum.HoldBtn,
          type: ActionButtonType.WithConfirmationWithSelect,
          props: getButtonWithConfirmationWithSelectProps(
            ActionButtonWithConfirmationWithSelectEnum.HoldBtn,
          ),
        }
      : null,
    withdrawalActions?.active
      ? {
          key: ActionIconButtonEnum.MakeActiveBtn,
          type: ActionButtonType.IconBtn,
          props: getIconButtonProps(ActionIconButtonEnum.MakeActiveBtn),
        }
      : null,
    withdrawalActions?.reject
      ? {
          key: ActionButtonWithConfirmationWithSelectEnum.RejectBtn,
          type: ActionButtonType.WithConfirmationWithSelect,
          props: getButtonWithConfirmationWithSelectProps(
            ActionButtonWithConfirmationWithSelectEnum.RejectBtn,
          ),
        }
      : null,
    withdrawalActions?.send_funds
      ? {
          key: ActionButtonWithConfirmationEnum.SendFundsToClientBtn,
          type: ActionButtonType.WithConfirmationBtn,
          props: getButtonWithConfirmationProps(
            ActionButtonWithConfirmationEnum.SendFundsToClientBtn,
          ),
        }
      : null,
    withdrawalActions?.export_to_xml
      ? {
          key: ActionIconButtonEnum.ExportToXMLBtn,
          type: ActionButtonType.IconBtn,
          props: getIconButtonProps(ActionIconButtonEnum.ExportToXMLBtn),
        }
      : null,
    withdrawalActions?.convert
      ? {
          key: ActionButtonWithConfirmationEnum.ConvertBtn,
          type: ActionButtonType.WithConfirmationBtn,
          props: getButtonWithConfirmationProps(
            ActionButtonWithConfirmationEnum.ConvertBtn,
          ),
        }
      : null,
    withdrawalActions?.rollback
      ? {
          key: ActionButtonWithConfirmationEnum.RollbackBtn,
          type: ActionButtonType.WithConfirmationBtn,
          props: getButtonWithConfirmationProps(
            ActionButtonWithConfirmationEnum.RollbackBtn,
          ),
        }
      : null,
  ].filter((item) => item !== null);

  // any is to fix the ts error, after raising the version of the typescript package there will be no build error
  const actionButtons = useMemo(
    () =>
      actions.map(({ key, type, props }: any) => {
        const buttonProps = { props, type } as ActionButtonProps;

        return <ActionButton key={key} {...buttonProps} />;
      }),
    [actions],
  );

  return actionButtons;
};
