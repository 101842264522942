import { useCallback, useContext, useEffect } from 'react';

import {
  useApplicationActionsMutation,
  useLazyConvertToApplicationQuery,
  useLazyLoginActionQuery,
} from '~/api';
import { IconBtnProps } from '~/modules/actionButtonsBlock/ActionButton';
import { ApplicationContext } from '~/pages/ApplicationEntry/contexts/ApplicationContext';

import { ActionIconButtonEnum } from '../Header.types';
import {
  applicationSuccessMessage,
  iconButtonKeysToActionsTypeMap,
  staticIconButtonPropsMap,
} from '../constants';

export const useGetIconButtonProps = () => {
  const {
    application,
    isLoadingApplication,
    isDisableActionButtons,
    setIsDisableActionButtons,
  } = useContext(ApplicationContext);
  const applicationId = application?.application?.id;

  const [loginAction, { isFetching: isLoginActionFetching }] =
    useLazyLoginActionQuery();
  const [convertAction, { isFetching: isConvertToApplicationFetching }] =
    useLazyConvertToApplicationQuery();
  const [applicationActions, { isLoading: isApplicationActionsLoading }] =
    useApplicationActionsMutation();

  useEffect(() => {
    setIsDisableActionButtons(
      isLoginActionFetching ||
        isConvertToApplicationFetching ||
        isApplicationActionsLoading,
    );
  }, [
    isApplicationActionsLoading,
    isConvertToApplicationFetching,
    isLoginActionFetching,
    setIsDisableActionButtons,
  ]);

  const handleConvert = useCallback(() => {
    if (applicationId) {
      convertAction({
        applicationId,
      });
    }
  }, [applicationId, convertAction]);

  const redirectToLogin = useCallback(async () => {
    if (applicationId) {
      const { data } = await loginAction({
        applicationId,
      });

      const url = data?.url;

      if (url) {
        window.open(url, '_blank');
      }
    }
  }, [applicationId, loginAction]);

  const handleSendApplicationActions = useCallback(
    (key: Exclude<ActionIconButtonEnum, 'loginBtn' | 'convertBtn'>) => () => {
      if (applicationId) {
        const action = iconButtonKeysToActionsTypeMap[key];

        applicationActions({
          applicationId,
          action,
          successMessage: applicationSuccessMessage[action],
        });
      }
    },
    [applicationActions, applicationId],
  );

  const getOnClickHandler = useCallback(
    (buttonKey: ActionIconButtonEnum) => () => {
      if (buttonKey === 'loginBtn') {
        return redirectToLogin();
      }

      if (buttonKey === 'convertBtn') {
        return handleConvert();
      }

      return handleSendApplicationActions(buttonKey)();
    },
    [handleConvert, handleSendApplicationActions, redirectToLogin],
  );

  const getIconButtonProps = (
    buttonKey: ActionIconButtonEnum,
  ): IconBtnProps['props'] => {
    const staticProps = staticIconButtonPropsMap[buttonKey];

    return {
      onClick: getOnClickHandler(buttonKey),
      disabled: isLoadingApplication || isDisableActionButtons,
      ...staticProps,
    };
  };

  return {
    getIconButtonProps,
  };
};
