import { createTheme } from '@mui/material/styles';
import { merge } from 'lodash';
import { useCallback, useMemo, useState } from 'react';

import { DefaultThemeProvider, useTheme } from '../../../../../theme';
import {
  DEFAULT_DATE_FORMAT,
  INPUT_DATE_PICKER_DATA_TEST_ID,
} from '../../constants';
import { DatePicker } from '../DatePicker';
import { castToDate } from '../DatePicker/helpers/castToDate';
import { DEFAULT_MIN_DATE } from '../constants';

import { DatePickerInput } from './DatePickerInput';
import { StyledIconButton } from './InputDatePicker.styled';
import { getMaskFromFormat, selectDateFormat } from './helpers';
import { IInputDatePickerProps } from './types';

export const InputDatePicker = <WithRange extends boolean = false>({
  dateFormat,
  disabled,
  inputProps,
  locale,
  maxDate,
  minDate = DEFAULT_MIN_DATE,
  onCalendarClose,
  onCalendarOpen,
  onChange,
  onChangeRaw,
  onClear,
  onToday,
  placeholderText,
  selected,
  showSkeleton = false,
  showTimeInput,
  withSeconds,
  timePickerProps,
  selectsRange,
  selectsStart,
  selectsEnd,
  ...rest
}: IInputDatePickerProps<WithRange>) => {
  const theme = useTheme();

  const [open, setOpen] = useState(false);

  const timePickerDefaults = timePickerProps?.defaults;

  const selectedSafe = castToDate(
    selected,
    dateFormat || DEFAULT_DATE_FORMAT,
    timePickerDefaults,
  );
  const minDateSafe = castToDate(
    minDate,
    dateFormat || DEFAULT_DATE_FORMAT,
    timePickerDefaults,
  );
  const maxDateSafe = castToDate(
    maxDate,
    dateFormat || DEFAULT_DATE_FORMAT,
    timePickerDefaults,
  );

  const selectedDateFormat =
    dateFormat || selectDateFormat(locale, showTimeInput, withSeconds);

  // Infer mask from format string
  const mask = useMemo(
    () => getMaskFromFormat(selectedDateFormat),
    [selectedDateFormat],
  );

  // Disable ripple effect for input button
  const newTheme = createTheme(
    merge({}, theme, {
      components: {
        MuiIconButton: { defaultProps: { disableRipple: true } },
      },
    }),
  );

  const handleOpen = useCallback(() => {
    setOpen(true);
    onCalendarOpen?.();
  }, [onCalendarOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
    onCalendarClose?.();
  }, [onCalendarClose]);

  const handleToday = () => {
    setOpen(false);
    onToday?.();
  };

  const handleClear = () => {
    setOpen(false);
    onClear?.();
  };

  return (
    <DefaultThemeProvider theme={newTheme}>
      <DatePicker
        customInput={
          <DatePickerInput
            mask={mask}
            iconRight={
              <StyledIconButton
                onClick={handleOpen}
                iconSize={24}
                disabled={disabled}
                iconName="CalendarIcon"
                dataTestId="InputDatePicker"
                title="Pick the date"
                size={inputProps?.size}
              />
            }
            data-test-id={INPUT_DATE_PICKER_DATA_TEST_ID}
            error={inputProps?.error}
            showSkeleton={showSkeleton}
            {...inputProps}
          />
        }
        dateFormat={selectedDateFormat}
        disabled={disabled}
        locale={locale}
        maxDate={maxDateSafe}
        minDate={minDateSafe}
        onCalendarClose={handleClose}
        onCalendarOpen={handleOpen}
        onChange={onChange}
        onClear={handleClear}
        onToday={handleToday}
        open={open}
        placeholderText={placeholderText || selectedDateFormat?.toLowerCase()}
        selected={selectedSafe}
        showTimeInput={showTimeInput}
        timePickerProps={timePickerProps}
        selectsRange={selectsRange}
        selectsStart={selectsStart}
        selectsEnd={selectsEnd}
        {...rest}
      />
    </DefaultThemeProvider>
  );
};
