import { memo } from 'react';

import { StyledIconButton } from './ActionIconButton.styled';
import { ActionIconButtonProps } from './ActionIconButton.types';

const ActionIconButton = (props: ActionIconButtonProps) => {
  return <StyledIconButton iconSize={32} {...props} />;
};

export const ActionIconButtonMemoized = memo(ActionIconButton);
