import { useTranslator } from '../../../contexts/TranslationContext';
import { Button } from '../../Button/Button';

import { FilterRow } from './components/FilterRow';
import { AUTOCOMPLETE_VALUE } from './constants';
import {
  FilterAutocompleteStyled,
  FilterChipStyled,
  FilterCountStyled,
  FilterHeaderLeftStyled,
  FilterHeaderRightStyled,
  FilterHeaderRowLeftStyled,
  FilterHeaderRowRightStyled,
  FilterHeaderRowStyled,
  FilterRowContainerStyled,
  StyledFiltersAction,
  StyledMobileFiltersAction,
} from './styled';
import { TableFilterProps, ISelectedFiltersState } from './types';
import { useComponentFilters } from './useComponentFilters';

export const TableFilters = <Data extends object>(
  props: TableFilterProps<Data>,
) => {
  const {
    isExpanded,
    filtersRightPanelComponent,
    hasNegativeFilters,
    negative,
    requiredFilters,
    setNegative,
    useBrandedDatePicker,
    withSearchButton,
    showClearButton,
  } = props;

  const { t } = useTranslator();

  const {
    filters,
    base,
    additional,
    selectedFilters,
    filtersOptions,
    hasSelectedFilters,
    handleClearAllFilters,
    handleAvailableFiltersChange,
    handleReactTableFilterChange,
    handleFiltersSubmit,
    handleClearFilter,
  } = useComponentFilters(props);

  return (
    <FilterRowContainerStyled
      className="FilterRowContainer"
      data-test-id="filters__row-container"
    >
      {isExpanded && (
        <>
          <FilterHeaderRowStyled className="FilterHeaderRow">
            <FilterHeaderRowLeftStyled className="FilterHeaderRowLeft">
              <FilterHeaderLeftStyled className="FilterHeaderLeft">
                <FilterCountStyled className="FilterCount">
                  {t('ui__table__filter__title')}
                  <FilterChipStyled
                    label={selectedFilters.length}
                    className="FilterChip"
                    data-test-id="filters__filter-count"
                  />
                </FilterCountStyled>
                <>
                  <FilterAutocompleteStyled<
                    ISelectedFiltersState,
                    true,
                    true,
                    false
                  >
                    disableClearable
                    options={filtersOptions}
                    onChange={handleAvailableFiltersChange}
                    value={AUTOCOMPLETE_VALUE}
                    getOptionLabel={(option) => {
                      return option.id;
                    }}
                    isMultiple
                    data-test-id="filters__input--filters-choice"
                    placeholder={t('ui__table__filter__select_placeholder')}
                    fullWidth
                  />
                  <StyledFiltersAction>
                    {withSearchButton && (
                      <Button
                        color="transparent"
                        textColor="action"
                        size="small"
                        onClick={handleFiltersSubmit}
                        data-test-id="filters__button--search"
                      >
                        {t('ui__table__colselect__search_placeholder')}
                      </Button>
                    )}
                    {hasSelectedFilters && (
                      <Button
                        color="transparent"
                        textColor="secondary"
                        size="small"
                        onClick={handleClearAllFilters}
                        data-test-id="filters__button--search"
                      >
                        {t('ui__table__filter__clear')}
                      </Button>
                    )}
                  </StyledFiltersAction>
                </>
              </FilterHeaderLeftStyled>
            </FilterHeaderRowLeftStyled>
            {filtersRightPanelComponent && (
              <FilterHeaderRowRightStyled>
                <FilterHeaderRightStyled>
                  {filtersRightPanelComponent}
                </FilterHeaderRightStyled>
              </FilterHeaderRowRightStyled>
            )}
          </FilterHeaderRowStyled>
          {hasSelectedFilters && (
            <>
              {selectedFilters.map(({ value, type }) => {
                const currentFilter =
                  type === 'base'
                    ? base.entities[value]
                    : additional.entities[value];

                const filterInputProps = currentFilter?.filterInputProps ?? {};

                if (!currentFilter) {
                  return null;
                }

                return (
                  <FilterRow
                    showClearButton={showClearButton}
                    dataTestId={`filters__filter-row-${value}`}
                    filter={currentFilter}
                    filterInputProps={filterInputProps}
                    filters={filters}
                    hasNegativeFilters={hasNegativeFilters}
                    key={value}
                    negative={negative}
                    onClose={handleClearFilter}
                    requiredFilters={requiredFilters}
                    setFilter={handleReactTableFilterChange}
                    setNegative={setNegative}
                    useBrandedDatePicker={useBrandedDatePicker}
                  />
                );
              })}
            </>
          )}
        </>
      )}
      <StyledMobileFiltersAction>
        {withSearchButton && (
          <Button
            size="small"
            onClick={handleFiltersSubmit}
            data-test-id="filters__button--search"
          >
            {t('ui__table__colselect__search_placeholder')}
          </Button>
        )}
        {hasSelectedFilters && (
          <Button
            size="small"
            color="secondary"
            onClick={handleClearAllFilters}
            data-test-id="filters__button--clear-all-filters"
          >
            {t('ui__table__filter__clear')}
          </Button>
        )}
      </StyledMobileFiltersAction>
    </FilterRowContainerStyled>
  );
};
