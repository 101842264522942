import { DEPOSIT_STATUSES } from '~/api';
import { ActionButtonWithConfirmationStaticProps } from '~/modules/actionButtonsBlock/ActionButton/components';

import { ActionButtonWithConfirmationEnum } from './DepositHeader.types';

export const ON_HOLD_COMMENT_ERROR = 'Comment is required field';

export const staticButtonWithConfirmationPropsMap: Record<
  ActionButtonWithConfirmationEnum,
  ActionButtonWithConfirmationStaticProps
> = {
  bookFundsBtn: {
    placement: 'bottom',
    cancelButtonNameKey: 'Cancel',
    confirmButtonNameKey: 'Confirm',
    title: 'Do you want to book funds in BO?',
    childrenStaticProps: {
      iconName: 'CircleCheckIcon',
      label: 'Book Funds',
      iconColor: 'action',
    },
  },
  onHoldBtn: {
    placement: 'bottom',
    cancelButtonNameKey: 'Cancel',
    confirmButtonNameKey: 'On hold',
    title: 'On hold',
    contentStaticProps: {
      multiline: true,
      label: 'Comment',
      className: 'CommentInput',
    },
    childrenStaticProps: {
      iconName: 'SuspendIcon',
      label: 'On hold',
      iconColor: 'warning',
    },
  },
  makeActiveBtn: {
    placement: 'bottom',
    cancelButtonNameKey: 'Cancel',
    confirmButtonNameKey: 'Confirm',
    title: 'Do you want to make active the deposit?',
    childrenStaticProps: {
      iconName: 'CircleCheckIcon',
      label: 'Make Active',
      iconColor: 'action',
    },
  },
  deleteBtn: {
    placement: 'bottom',
    cancelButtonNameKey: 'Cancel',
    confirmButtonNameKey: 'Delete',
    title: 'Do you want to delete the deposit?',
    childrenStaticProps: {
      iconName: 'DeleteIcon',
      iconColor: 'secondary',
    },
  },
  rejectBtn: {
    placement: 'bottom',
    title: 'Do you want to reject the deposit?',
    cancelButtonNameKey: 'Cancel',
    confirmButtonNameKey: 'Reject',
    childrenStaticProps: {
      iconName: 'CircleCloseIcon',
      label: 'Reject',
      iconColor: 'radical',
    },
  },
};

export const buttonKeyToDepositStatusMap = {
  [ActionButtonWithConfirmationEnum.BookFundsBtn]: DEPOSIT_STATUSES.BOOKED,
  [ActionButtonWithConfirmationEnum.OnHoldBtn]: DEPOSIT_STATUSES.ONHOLD,
  [ActionButtonWithConfirmationEnum.MakeActiveBtn]: DEPOSIT_STATUSES.ACTIVE,
  [ActionButtonWithConfirmationEnum.RejectBtn]: DEPOSIT_STATUSES.REJECTED,
};

export const depositStatusToButtonKeyMap = {
  [DEPOSIT_STATUSES.BOOKED]: ActionButtonWithConfirmationEnum.BookFundsBtn,
  [DEPOSIT_STATUSES.ONHOLD]: ActionButtonWithConfirmationEnum.OnHoldBtn,
  [DEPOSIT_STATUSES.ACTIVE]: ActionButtonWithConfirmationEnum.MakeActiveBtn,
  [DEPOSIT_STATUSES.REJECTED]: ActionButtonWithConfirmationEnum.RejectBtn,
  0: ActionButtonWithConfirmationEnum.DeleteBtn,
};
