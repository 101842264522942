import { TWithdrawalsData } from '~/api/withdrawals/withdrawals.types';

export type TWithdrawalHeader = {
  withdrawal: TWithdrawalsData;
};

export enum ActionIconButtonEnum {
  MakeActiveBtn = 'makeActiveBtn',
  ExportToXMLBtn = 'exportToXMLBtn',
}

export enum ActionButtonWithConfirmationEnum {
  WithdrawBtn = 'withdrawBtn',
  SendFundsToClientBtn = 'sendFundsToClientBtn',
  ConvertBtn = 'convertBtn',
  RollbackBtn = 'rollbackBtn',
}

export enum ActionButtonWithConfirmationWithSelectEnum {
  HoldBtn = 'holdBtn',
  RejectBtn = 'rejectBtn',
}
