import { Chip, TextField } from '@mui/material';

import { blockNonNativeProps } from '../../../helpers';
import { styled } from '../../../theme';
import { IconButton } from '../../Button/IconButton';
import { Autocomplete } from '../../Inputs/Autocomplete';
import { DatePicker, DateTimePicker, InputDatePicker } from '../../Inputs/Date';

export const FilterRowContainerStyled = styled('div')(() => ({
  flexShrink: 1,
}));

export const FilterHeaderRowStyled = styled('div')(() => ({
  display: 'flex',
  flexWrap: 'nowrap',
}));

export const FilterHeaderRowLeftStyled = styled('div')(() => ({
  flex: 1,
}));

export const FilterHeaderRowRightStyled = styled('div')(() => ({
  flexBasis: '30%',
}));

export const FilterHeaderLeftStyled = styled('div')(({ theme }) => ({
  padding: '16px 24px',
  background: theme?.color.table.bg.basic1,
  boxShadow: theme?.color.table.boxShadow.basic1,
  boxSizing: 'border-box',
  [theme?.breakpoints?.up('md') as string]: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '280px 320px 1fr',
    gap: '16px',
    justifyItems: 'start',
    padding: '8px 24px',
  },
}));

export const FilterHeaderRightStyled = styled('div')(({ theme }) => ({
  padding: '16px 24px',
  background: theme?.color.table.bg.basic1,
  boxShadow: theme?.color.table.boxShadow.basic1,
  boxSizing: 'border-box',
  height: '100%',
  [theme?.breakpoints?.up('md') as string]: {
    display: 'grid',
    alignItems: 'center',
    padding: '8px 24px',
  },
}));

export const FilterRowStyled = styled('div')(({ theme }) => ({
  padding: '8px 24px 8px 22px',
  background: theme?.color.table.bg.basic1,
  boxSizing: 'border-box',
  minHeight: '48px',
  borderLeft: `2px solid ${theme?.color.typo.action}`,
  borderTop: `1px solid ${theme?.color.bg.primary}`,
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: 'minmax(0, 100px) 1fr auto',
  gap: '8px',
  justifyItems: 'start',
  [theme?.breakpoints?.up('md') as string]: {
    gridTemplateColumns: '280px 320px 1fr',
    gap: '16px',
  },
}));

export const FilterLabelStyled = styled('div')(({ theme }) => ({
  color: theme.color.typo.primary,
  fontSize: theme?.size.text.lg,
  textTransform: 'capitalize',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  [theme?.breakpoints?.up('md') as string]: {
    width: '100%',
  },
}));

export const FilterChipStyled = styled(Chip)(({ theme }) => ({
  marginLeft: '16px',
  fontFamily: theme.font.main,
  fontSize: theme.size.text.sm,
  lineHeight: '16px',
  minWidth: '30px',
  height: '24px',
  padding: '4px 8px',
  border: `1px solid ${theme.color.typo.action}`,
  color: theme.color.typo.action,

  '& .MuiChip-label': {
    padding: 0,
  },
}));

export const FilterAutocompleteStyled = styled(Autocomplete)(({ theme }) => ({
  [theme?.breakpoints?.down('md') as string]: {
    marginBottom: '16px',
  },
})) as typeof Autocomplete;

type TFilterEqualityStyled = {
  hasNegativeFilters: boolean;
};

export const FilterEqualityStyled = styled('button', {
  shouldForwardProp: blockNonNativeProps(['hasNegativeFilters']),
})<TFilterEqualityStyled>(({ hasNegativeFilters, theme }) => ({
  fontSize: '20px',
  color: theme?.color.typo.secondary,
  marginLeft: '8px',
  background: 'none',
  border: 0,
  padding: 0,
  cursor: hasNegativeFilters ? 'pointer' : 'auto',

  ':hover': {
    color: hasNegativeFilters
      ? theme?.color.typo.primary
      : theme?.color.typo.secondary,
  },

  [theme?.breakpoints?.up('md') as string]: {
    marginLeft: '16px',
  },
}));

export const FilterInputStyled = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  [theme?.breakpoints?.down('md') as string]: {
    flexDirection: 'column',
    alignItems: 'start',
  },
}));

export const FilterCountStyled = styled('div')(({ theme }) => ({
  color: theme.color.typo.primary,
  fontSize: '20px',
  lineHeight: '32px',
  margin: '0 0 16px 0',
  display: 'flex',
  alignItems: 'center',
  [theme?.breakpoints?.up('md') as string]: {
    marginBottom: 0,
  },
}));

export const DateRangePickerStyled = styled('div')(({ theme }) => ({
  [theme?.breakpoints?.up('md') as string]: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
  },
}));

export const DateTimeStyled = styled(DateTimePicker)(({ theme }) => ({
  width: '100%',
  marginBottom: '8px',
  [theme?.breakpoints?.up('md') as string]: {
    width: '184px',
    marginBottom: 0,
  },
}));

export const DatePickerStyled = styled(DatePicker)(({ theme }) => ({
  width: '100%',
  marginBottom: '8px',
  [theme?.breakpoints?.up('md') as string]: {
    width: '144px',
    marginBottom: 0,
  },
}));

export const BrandedDateTimeStyled = styled(InputDatePicker)(({ theme }) => ({
  width: '100%',
  marginBottom: '8px',
  [theme?.breakpoints?.up('md') as string]: {
    width: '184px',
    marginBottom: 0,
  },
})) as typeof InputDatePicker;

export const BrandedDatePickerStyled = styled(InputDatePicker)(({ theme }) => ({
  width: '100%',
  marginBottom: '8px',
  [theme?.breakpoints?.up('md') as string]: {
    width: '144px',
    marginBottom: 0,
  },
})) as typeof InputDatePicker;

export const PredefinedDataRangeStyled = styled('button')(({ theme }) => ({
  fontSize: theme?.size.text.md,
  color: theme?.color.typo.action,
  cursor: 'pointer',
  border: '0',
  background: 'none',
  padding: 0,
  whiteSpace: 'nowrap',
  [theme?.breakpoints?.down('md') as string]: {
    marginRight: '16px',
  },
}));

export const FilterRemoveStyled = styled(IconButton)(() => ({
  marginLeft: 'auto',
}));

export const NumberRangeInputStyled = styled(TextField)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme?.color.bg.primary,
  marginBottom: '8px',
  [theme?.breakpoints?.up('md') as string]: {
    margin: '0 16px 0 0',
  },
  '.MuiOutlinedInput-root': {
    padding: '8px',
    color: theme?.color.typo.primary,
  },
  '.MuiOutlinedInput-root.MuiAutocomplete-inputRoot': {
    padding: '5px',
  },
  '.MuiOutlinedInput-input.MuiAutocomplete-input': {
    padding: 0,
    height: '22px',
  },
  '.MuiOutlinedInput-input': {
    padding: 0,
    height: '16px',
  },
  '.MuiChip-root': {
    height: '16px',
  },
  '.MuiChip-root .MuiSvgIcon-root': {
    height: '13px',
    width: '13px',
  },
}));

export const StyledFiltersAction = styled('div')`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  gap: 16px;
  ${({ theme }) => {
    return {
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    };
  }}
`;

export const StyledMobileFiltersAction = styled('div')`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  height: 56px;
  align-items: center;
  background: ${({ theme }) => theme?.color.table.bg.basic1};
  padding: 0 24px;
  border-bottom: 1px solid ${({ theme }) => theme?.color.bg.primary};
  border-left: 1px solid ${({ theme }) => theme?.color.bg.primary};
  box-shadow: ${({ theme }) => theme?.color.table.boxShadow.basic1};
  ${({ theme }) => {
    return {
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    };
  }}
`;
