import { styled } from 'react-ui-kit-exante';

export const StyledColumns = styled('div')<{ columns?: number }>`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const StyledColumn = styled('div')`
  display: flex;
  flex-direction: column;
  &:not(:first-child) {
    border-left: 1px solid ${({ theme }) => theme.color.line.primary};
  }
`;
