import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import {
  useGetHoldReasonsQuery,
  useGetRejectReasonsQuery,
  useWithdrawalActionsMutation,
} from '~/api';
import { ActionWithConfirmationWithSelectProps } from '~/modules/actionButtonsBlock/ActionButton';
import { TParams } from '~/router/router.types';
import { IOption } from '~/types/form/options';

import { ActionButtonWithConfirmationWithSelectEnum } from '../WithdrawalHeader.types';
import { staticButtonWithConfirmationWithSelectPropsMap } from '../constants';
import { typeHandlerMap } from '../constants/ActionButtonWithConfirmationWithSelect.constants';

export const useGetButtonWithConfirmationWithSelectProps = (
  isLoadingActions: boolean,
  loadingActionsHandler: (isLoading: boolean) => void,
) => {
  const { id: withdrawalId } = useParams<TParams>();

  const { data: holdReasons } = useGetHoldReasonsQuery();
  const { data: rejectReasons } = useGetRejectReasonsQuery();
  const [onWithdrawalAction, { isLoading: isWithdrawalActionsLoading }] =
    useWithdrawalActionsMutation();

  useEffect(() => {
    loadingActionsHandler(isWithdrawalActionsLoading);
  }, [isWithdrawalActionsLoading, loadingActionsHandler]);

  const getReasonOptions = (
    buttonKey: ActionButtonWithConfirmationWithSelectEnum,
  ) => {
    const data =
      buttonKey === ActionButtonWithConfirmationWithSelectEnum.HoldBtn
        ? holdReasons
        : rejectReasons;

    if (data) {
      return data.map(({ id: reasonId, reason_text: reasonText }) => ({
        value: String(reasonId),
        label: reasonText,
      }));
    }

    return [];
  };

  const getHandleConfirm = useCallback(
    (buttonKey: ActionButtonWithConfirmationWithSelectEnum) =>
      (_?: string, closureReason?: IOption[]) => {
        const reasonId = closureReason?.[0].value;
        if (withdrawalId && reasonId) {
          onWithdrawalAction({
            id: withdrawalId,
            reason_id: +reasonId,
            type: typeHandlerMap[buttonKey],
          });
        }
      },
    [onWithdrawalAction, withdrawalId],
  );

  const getButtonWithConfirmationWithSelectProps = (
    buttonKey: ActionButtonWithConfirmationWithSelectEnum,
  ): ActionWithConfirmationWithSelectProps['props'] => {
    const staticProps =
      staticButtonWithConfirmationWithSelectPropsMap[buttonKey];

    const options = getReasonOptions(buttonKey);

    return {
      isLoadingActions,
      handleConfirm: getHandleConfirm(buttonKey),
      options,
      ...staticProps,
    };
  };

  return {
    getButtonWithConfirmationWithSelectProps,
  };
};
