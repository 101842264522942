import { IColumn } from 'react-ui-kit-exante';

import { TDepositInfo } from '~/api/deposits/deposits.types';
import {
  DISPLAYED_COLUMNS_KEYS as DEPOSITS_DISPLAYED_COLUMNS_KEYS,
  getColumns as getDepositColumns,
} from '~/constants/deposits';

export const DISPLAYED_COLUMNS_KEYS = [
  ...DEPOSITS_DISPLAYED_COLUMNS_KEYS,
  'action',
];

export const TABLE_ID = 'finance-tab-deposits-table';

export const getColumns = (): IColumn<TDepositInfo>[] => {
  const columns: IColumn<TDepositInfo>[] = getDepositColumns();

  return columns.map((item) => ({
    ...item,
    accessor: item.id,
    disableFilters: true,
  }));
};
