import { DATE_FORMATS } from '../../Components/Inputs/Date/constants';
import { DEFAULT_FILTER_LOCALE } from '../../Components/Table/TableFilters/constants';
import { DATE_FORMAT } from '../../consts';

export const getBrowserDateFormat = (
  withTime: boolean,
  useLocale: boolean,
  getBrowserLocale: () => string,
) => {
  const inputFormat = useLocale
    ? DATE_FORMATS[getBrowserLocale().toLowerCase()] ||
      DATE_FORMATS[DEFAULT_FILTER_LOCALE]
    : DATE_FORMAT;

  if (withTime) {
    return `${inputFormat} HH:mm`;
  }

  return inputFormat;
};
