import { FC, useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { IconButton, Loader } from 'react-ui-kit-exante';

import { useGetCcyQuery, useGetCountryAQuery } from '~/api';
import { EntryScreenWrapper } from '~/components/EntryScreen';
import { EntryScreenHeader } from '~/components/EntryScreen/EntryScreenHeader';
import { getFormField } from '~/utils/getFormField';

import { SwiftField } from '../../../SwiftField';
import { DepositCreateFormContext } from '../../contexts';

import {
  depositCryptoFields,
  getClientDetailsFields,
  getFileUpload,
} from './DepositCreateForm.constants';
import { getConfirmWireFields, getFields } from './DepositCreateForm.helpers';
import {
  StyleClientDetailsFields,
  StyleCommonDetailsFields,
  StyleDepositDetailsFields,
  StyleDocumentsField,
  StyleFormWrapper,
} from './DepositCreateForm.styled';
import { TDepositFormProps } from './DepositCreateForm.types';

export const DepositCreateForm: FC<TDepositFormProps> = ({
  deposit,
  onCloseEntry,
}) => {
  const {
    hiddenFields,
    onSave,
    isLoading: isLoadingCreated,
  } = useContext(DepositCreateFormContext);
  const { data: stateCountryAOptions, isFetching: isGetCountryAFetching } =
    useGetCountryAQuery();
  const { data: stateCcyOptions, isFetching: isGetCcyFetching } =
    useGetCcyQuery();

  const { watch } = useFormContext();

  const currentFormValues = watch();
  const normalizeDeposit = {
    ...deposit,
    hidden_fields_keys: hiddenFields,
  };

  const clientDetailsFields = getClientDetailsFields(
    normalizeDeposit,
    currentFormValues,
  );
  const confirmWireFields = getConfirmWireFields(normalizeDeposit);

  const countryA = {
    isLoading: isGetCountryAFetching,
    options: stateCountryAOptions,
  };

  const ccy = {
    isLoading: isGetCcyFetching,
    options: stateCcyOptions,
  };

  const depositDetailsFields = getFields({
    countryA,
    ccy,
    deposit,
    hiddenFields,
  });

  const depositType = watch('deposit_type');

  return (
    <EntryScreenWrapper>
      <EntryScreenHeader
        title="Add New Deposit"
        onClose={onCloseEntry}
        actions={[
          isLoadingCreated ? (
            <Loader />
          ) : (
            <IconButton
              label="Save"
              iconName="SaveIcon"
              iconColor="action"
              type="submit"
              key="Save"
              iconSize={32}
              onClick={onSave}
            />
          ),
        ]}
      />
      <StyleFormWrapper className="StyleFormWrapper">
        <StyleDepositDetailsFields className="StyleDepositDetailsFields">
          {depositDetailsFields?.map(
            ({ name, title, type, optionsConfig, disabled, isLoading }) => {
              const isCrypto = depositType === 'crypto';
              const isCryptoField = depositCryptoFields.includes(name);

              if (!isCrypto && isCryptoField) {
                return null;
              }

              if (name === 'swift') {
                return (
                  <SwiftField
                    objectId={deposit.application}
                    name={name}
                    label={title}
                    key={name}
                    size="medium"
                  />
                );
              }

              return getFormField({
                label: title,
                type,
                name,
                options: optionsConfig?.options ?? [],
                size: 'medium',
                disabled,
                isLoading,
              });
            },
          )}
        </StyleDepositDetailsFields>

        {clientDetailsFields.length > 0 && (
          <StyleClientDetailsFields
            className="StyleClientDetailsFields"
            length={clientDetailsFields.length}
          >
            {clientDetailsFields.map(
              ({ name, type, title, optionsConfig, readOnly }) => {
                if (name === 'bank_name') {
                  return (
                    <SwiftField
                      objectId={deposit.application}
                      name={name}
                      label={title}
                      key={name}
                      size="medium"
                    />
                  );
                }

                return getFormField({
                  label: title,
                  name,
                  size: 'medium',
                  type,
                  readOnly,
                  options: optionsConfig?.options,
                });
              },
            )}
          </StyleClientDetailsFields>
        )}

        {confirmWireFields.length > 0 && (
          <StyleCommonDetailsFields className="StyleCommonDetailsFields">
            {confirmWireFields.map(
              ({ name, title, disabled, type, optionsConfig }) => {
                return getFormField({
                  label: title,
                  name,
                  type,
                  size: 'medium',
                  options: optionsConfig?.options,
                  disabled,
                });
              },
            )}
          </StyleCommonDetailsFields>
        )}
        <StyleDocumentsField title="Uploaded documents">
          {getFileUpload.map(({ name, title, type, accept }) =>
            getFormField({
              label: title,
              name,
              type,
              accept,
            }),
          )}
        </StyleDocumentsField>
      </StyleFormWrapper>
    </EntryScreenWrapper>
  );
};
