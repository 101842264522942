import { createContext, FC, useEffect, useMemo } from 'react';
import { FormProvider } from 'react-hook-form';

import { TChildren } from 'types/TChildren';
import {
  useCreateDepositTabRecordMutation,
  useUploadDocumentsMutation,
} from '~/api';
import { TCreateDepositBody } from '~/api/deposits/deposits.types';
import { replaceEmptyString } from '~/utils/forms/replaceEmptyString';

import { getClientDetailsFields } from '../components/DepositCreateForm/DepositCreateForm.constants';
import {
  getConfirmWireFields,
  getFields,
} from '../components/DepositCreateForm/DepositCreateForm.helpers';

import { getDefaultFormValues } from './helpers';
import { useGetFormMethod } from './useGetFormMethod';

type TDepositFormContext = {
  onSave: VoidFunction;
  isLoading: boolean;
  hiddenFields: string[];
  isCreateSuccess: boolean;
};

type TDepositFormProps = {
  initialDeposit: TCreateDepositBody;
  onCloseEntry: VoidFunction;
  accounts: Record<string, string[]>;
};

const initialState: TDepositFormContext = {
  onSave: () => {},
  isLoading: false,
  hiddenFields: [],
  isCreateSuccess: false,
};

export const DepositCreateFormContext =
  createContext<TDepositFormContext>(initialState);

export const DepositCreateFormProvider: FC<TDepositFormProps & TChildren> = ({
  children,
  onCloseEntry,
  initialDeposit,
  accounts,
}) => {
  const [onUploadDocuments, { isLoading: isUploadDocumentsLoading }] =
    useUploadDocumentsMutation();
  const [
    onCreateDepositRecord,
    { isSuccess, isLoading: isCreateDepositTabRecordLoading },
  ] = useCreateDepositTabRecordMutation();

  const initDefault = [
    ...getFields({ deposit: initialDeposit }),
    ...getClientDetailsFields(initialDeposit),
    ...getConfirmWireFields(initialDeposit),
  ];

  const accountToLeMap = useMemo(() => {
    return Object.entries(accounts).reduce((acc, [key, accountList]) => {
      accountList.forEach((accountId) => {
        acc[accountId] = key;
      });

      return acc;
    }, {} as Record<string, string>);
  }, [accounts]);

  const defaultValues = getDefaultFormValues(initialDeposit, initDefault);

  const { handleSubmit, methods, formData, hiddenFields } = useGetFormMethod({
    defaultValues,
    initDefault,
  });

  const accountId = methods.getValues('account_id');

  useEffect(() => {
    if (accountId && typeof accountId === 'string') {
      methods.setValue('legal_entity', accountToLeMap[accountId]);
    }
  }, [accountId]);

  const onSave = async () => {
    const { application } = initialDeposit;
    const { uploads, ...otherData } = replaceEmptyString(formData);

    const normalizeData = { ...otherData };
    const uploadsId = [];
    if (Array.isArray(uploads) && uploads.length > 0) {
      const response = await Promise.allSettled(
        uploads.map((file) => onUploadDocuments({ file })),
      );

      uploadsId.push(
        ...response.reduce<number[]>((acc, res) => {
          if (res.status === 'fulfilled' && 'data' in res.value) {
            acc.push(res.value.data.id);
          }

          return acc;
        }, []),
      );
    }

    const res = await onCreateDepositRecord({
      data: {
        ...normalizeData,
        uploads: uploadsId,
        application,
      },
    });

    if (!('error' in res)) {
      onCloseEntry();
    }
  };

  const value = useMemo(
    (): TDepositFormContext => ({
      onSave: handleSubmit(onSave),
      isLoading: isCreateDepositTabRecordLoading || isUploadDocumentsLoading,
      hiddenFields: hiddenFields ?? [],
      isCreateSuccess: isSuccess,
    }),
    [onSave, formData, hiddenFields],
  );

  return (
    <DepositCreateFormContext.Provider value={value}>
      <FormProvider {...methods}>{children}</FormProvider>
    </DepositCreateFormContext.Provider>
  );
};
