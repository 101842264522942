import { TDepositInfo } from '~/api/deposits/deposits.types';
import {
  ActionButton,
  ActionButtonType,
} from '~/modules/actionButtonsBlock/ActionButton';

import { depositStatusToButtonKeyMap } from '../DepositHeader.constants';
import { StyledDepositEntryActions } from '../DepositHeader.styled';

import { useGetButtonProps } from './useGetButtonProps';

export const useGetActions = (
  deposit: TDepositInfo,
  onCloseEntry: VoidFunction,
) => {
  const { getButtonProps } = useGetButtonProps(deposit, onCloseEntry);

  const availableActions = deposit?.actions || [];

  const actions = (
    <StyledDepositEntryActions className="DepositEntryActions">
      {availableActions?.map((action) => {
        const { key, disabled } = action;
        if (depositStatusToButtonKeyMap[key]) {
          const buttonProps = {
            props: getButtonProps(depositStatusToButtonKeyMap[key], disabled),
            type: ActionButtonType.WithConfirmationBtn,
          };
          return <ActionButton key={key} {...buttonProps} />;
        }
        return '';
      })}
    </StyledDepositEntryActions>
  );

  return [actions];
};
