import {
  FC,
  useCallback,
  Dispatch,
  SetStateAction,
  ChangeEvent,
  KeyboardEventHandler,
} from 'react';

import { useTranslator } from '../../../../contexts/TranslationContext';
import { FilterInputProps } from '../../types';
import { DEFAULT_RANGE_NUMBER_VALUES } from '../constants';
import { NumberRangeInputStyled } from '../styled';
import { TNumberRangeValue } from '../types';

import { parseNumber } from './helpers';

interface INumberRangeInputProps {
  onChange: Dispatch<SetStateAction<TNumberRangeValue[]>>;
  onSubmit: (values: [TNumberRangeValue, TNumberRangeValue]) => void;
  values: TNumberRangeValue[];
  inputProps?: FilterInputProps;
}

export const NumberRangeInput: FC<INumberRangeInputProps> = ({
  onChange,
  onSubmit,
  values: [minValue, maxValue] = DEFAULT_RANGE_NUMBER_VALUES,
  inputProps,
}) => {
  const { t } = useTranslator();

  const handleMinNumberBlur = useCallback(
    ({ target: { value } }: ChangeEvent<{ value: string }>) => {
      onSubmit([parseNumber(value), maxValue]);
    },
    [onSubmit, maxValue],
  );

  const handleMaxNumberBlur = useCallback(
    ({ target: { value } }: ChangeEvent<{ value: string }>) => {
      onSubmit([minValue, parseNumber(value)]);
    },
    [onSubmit, minValue],
  );

  const handleMinNumberKeyDown: KeyboardEventHandler<HTMLInputElement> =
    useCallback(
      ({ key, target, code }) => {
        const { value } = target as HTMLInputElement;

        if (key === '0' || code === 'Enter') {
          onSubmit([parseNumber(value), maxValue]);
        }
      },
      [onSubmit, maxValue],
    );

  const handleMaxNumberKeyDown: KeyboardEventHandler<HTMLInputElement> =
    useCallback(
      ({ key, target, code }) => {
        const { value } = target as HTMLInputElement;

        if (key === '0' || code === 'Enter') {
          onSubmit([minValue, parseNumber(value)]);
        }
      },
      [onSubmit, minValue],
    );

  const handleMinChange = useCallback(
    ({ target: { value } }: ChangeEvent<{ value: string }>) => {
      onChange((prev = DEFAULT_RANGE_NUMBER_VALUES) => [
        parseNumber(value),
        typeof prev === 'string' ? null : prev[1],
      ]);
    },
    [onChange],
  );

  const handleMaxChange = useCallback(
    ({ target: { value } }: ChangeEvent<{ value: string }>) =>
      onChange((prev = DEFAULT_RANGE_NUMBER_VALUES) => [
        typeof prev === 'string' ? null : prev[0],
        parseNumber(value),
      ]),
    [onChange],
  );

  return (
    <>
      <NumberRangeInputStyled
        onKeyDown={handleMinNumberKeyDown}
        onBlur={handleMinNumberBlur}
        onChange={handleMinChange}
        placeholder={t('ui__table__filters__numrange__min_placeholder')}
        value={minValue ?? ''}
        type="number"
        inputProps={inputProps}
        data-test-id="filters__input--number-min"
      />
      <NumberRangeInputStyled
        onKeyDown={handleMaxNumberKeyDown}
        onBlur={handleMaxNumberBlur}
        onChange={handleMaxChange}
        placeholder={t('ui__table__filters__numrange__max_placeholder')}
        value={maxValue ?? ''}
        type="number"
        inputProps={inputProps}
        data-test-id="filters__input--number-max"
      />
    </>
  );
};
