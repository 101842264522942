import { styled } from 'react-ui-kit-exante';

export const StyledReportsTag = styled('span')`
  display: inline-block;
  padding: 2px 4px;

  font-size: 10px;
  font-weight: 700;
  line-height: 14px;

  text-transform: uppercase;

  color: ${({ theme }) => theme.color.typo.inverse};
  white-space: nowrap;

  background-color: ${({ theme }) => theme.color.typo.secondary};
  border-radius: 3px;
`;
