import { object } from 'yup';

import {
  TCreateDepositBody,
  TDepositInfo,
} from '~/api/deposits/deposits.types';
import { FieldTypes } from '~/constants/common';
import { TDefaultFormValues } from '~/types/form';
import { mapTypeToValidationFn } from '~/utils/forms/mapTypeToValidationFn';

import { TFieldConfig } from '../DepositForm.types';

export const getDefaultFormValues = (
  deposit: TDepositInfo | TCreateDepositBody,
  defaultValues: TFieldConfig[],
) => {
  return defaultValues.reduce<TDefaultFormValues>((acc, curr) => {
    const key = curr.name as keyof typeof deposit;

    if (curr.type === FieldTypes.DateTimeField) {
      return {
        ...acc,
        [curr.name]: deposit[key] ? new Date(String(deposit[key])) : null,
      };
    }

    const value = curr?.subKey ? deposit[key]?.[curr.subKey] : deposit[key];

    return {
      ...acc,
      [curr.name]: value,
    };
  }, {});
};

export const getFormValidationScheme = (scheme: TFieldConfig[]) => {
  const resultScheme = scheme.reduce((acc, curr) => {
    if (curr.required) {
      const valueType = curr.valueType;
      return {
        ...acc,
        [curr.name]: mapTypeToValidationFn(valueType)
          .transform((val, orig) =>
            orig === '' || orig === null ? undefined : val,
          )
          .required(`${curr.title} is a required field`)
          .typeError(valueType ? `${curr.title} must be a '${valueType}'` : ''),
      };
    }

    return {
      ...acc,
    };
  }, {});
  return object(resultScheme);
};
