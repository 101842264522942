export const DEFAULT_STATIC_PERSON_FORM = [
  {
    id: -1,
    title: 'Affiliated type',
    name: 'affiliated_type',
    type: 'choice',
    choices: 'individual, corporate',
    tagvalue_set: [],
  },
  {
    id: -2,
    title: 'Name',
    name: 'first_name',
    type: 'input',
    tagvalue_set: [],
  },
  {
    id: -3,
    title: 'Surname',
    name: 'last_name',
    type: 'input',
    tagvalue_set: [],
  },
];
export const SECTIONS_FOR_PERSON =
  'person,accuity,sumsub,sumsub_liveness_check';
