import { blockNonNativeProps } from '../../../../../helpers';
import { styled } from '../../../../../theme';
import { IconButton, IconButtonProps } from '../../../../Button/IconButton';

import { TInputDatePickerIconProps } from './types';

export const StyledIconButton = styled(IconButton, {
  shouldForwardProp: blockNonNativeProps(['size', 'dataTestId']),
})<IconButtonProps & TInputDatePickerIconProps>(({ size }) => ({
  marginRight: size === 'small' ? '-8px' : '-4px',
}));
