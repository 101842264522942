import { useContext, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Checkbox, IconButton } from 'react-ui-kit-exante';

import {
  useDeletePersonMutation,
  useSetMainUboMutation,
  useLazyGetPersonsQuery,
} from 'api';
import { TPersons } from '~/api/applications/applications.types';
import { ActionWithConfirmation } from '~/components/ConfirmationComponents/ActionWithConfirmation';
import { ValueRow } from '~/components/ValueRow';
import { ApplicationContext } from '~/pages/ApplicationEntry/contexts/ApplicationContext';
import { ROOT_PATH } from '~/router';

import {
  StyledPersons,
  StyledPersonsActions,
  StyledPersonsAddPersonBtn,
  StyledPersonsBlock,
  StyledPersonsCheckbox,
  StyledPersonsCheckboxInfo,
  StyledPersonsCheckboxTitle,
  StyledPersonsName,
  StyledPersonsNameWithActions,
  StyledPersonsTitleGroup,
} from './PersonInfoBlocks.styled';

export const PersonInfoBlocks = () => {
  const navigate = useNavigate();

  const { application } = useContext(ApplicationContext);

  const applicationId = application?.application?.id;

  const [getPersons, state] = useLazyGetPersonsQuery();

  const personsData = state.data;

  useEffect(() => {
    if (applicationId) {
      getPersons({ applicationId });
    }
  }, [applicationId]);

  const [deletePerson, deleteState] = useDeletePersonMutation();
  const [setMainUbo] = useSetMainUboMutation();

  const preparedData: { roles: string; persons: TPersons[] }[] = useMemo(
    () =>
      personsData?.reduce((acc: { roles: string; persons: any[] }[], curr) => {
        const isRoleMatch = acc
          ?.map((itemAcc) => itemAcc?.roles)
          .includes(curr.roles);

        if (isRoleMatch) {
          return acc.map((item) => {
            if (item?.roles === curr?.roles) {
              return { ...item, persons: [...item.persons, curr] };
            }
            return item;
          });
        }

        return [...acc, { roles: curr.roles, persons: [curr] }];
      }, []) || [],
    [personsData],
  );

  const handleOnAddPerson = () =>
    navigate(`${ROOT_PATH}/create-person/${applicationId}`);

  const handleOnEditPerson = (personId: number) =>
    navigate(`${ROOT_PATH}/edit-person/${personId}`);

  const handleOnSetMainBO = (person: TPersons) => {
    if (!person.main_ubo) {
      return setMainUbo({ data: [person], method: 'POST' });
    }
    return setMainUbo({ data: [person], method: 'DELETE' });
  };

  const handleDeletePerson = (personId: number) => deletePerson({ personId });
  return (
    <div>
      <StyledPersonsAddPersonBtn
        iconName="AddIcon"
        label="Add New Person"
        iconColor="action"
        iconSize={24}
        onClick={handleOnAddPerson}
        className="AddNewTagButton"
      />
      {preparedData.map(({ roles, persons }, index) => {
        return (
          <div key={`${roles}${index}`}>
            {roles && (
              <StyledPersonsTitleGroup className="PersonsTitleGroup">
                {roles}
              </StyledPersonsTitleGroup>
            )}
            <StyledPersonsBlock className="PersonsBlock">
              {persons?.map((person) => {
                const personId = person.id;

                return (
                  <div key={personId}>
                    <StyledPersons className="Persons" key={personId}>
                      <StyledPersonsNameWithActions className="PersonsNameWithActions">
                        <StyledPersonsName className="PersonsName">{`${person.first_name} ${person.last_name}`}</StyledPersonsName>
                        <StyledPersonsActions className="PersonsActions">
                          <IconButton
                            iconName="EditIcon"
                            iconColor="ghost"
                            iconSize={24}
                            onClick={() => handleOnEditPerson(personId)}
                          />
                          <ActionWithConfirmation
                            onConfirm={() => handleDeletePerson(personId)}
                            title="Confirmation"
                            content="Are you sure to delete this person?"
                            placement="left"
                            disabled={deleteState.isLoading}
                            confirmButtonNameKey="Confirm"
                            cancelButtonNameKey="Cancel"
                          >
                            <IconButton
                              iconName="DeleteIcon"
                              iconColor="ghost"
                              iconSize={24}
                            />
                          </ActionWithConfirmation>
                        </StyledPersonsActions>
                      </StyledPersonsNameWithActions>
                      <StyledPersonsCheckbox className="PersonsCheckbox">
                        <ActionWithConfirmation
                          withCloseAfterConfirmation
                          onConfirm={() =>
                            person.can_be_main_ubo && handleOnSetMainBO(person)
                          }
                          title="Confirmation"
                          content={`Are you sure to ${
                            !person.main_ubo ? 'set' : 'unset'
                          } Main UBO?`}
                          placement="bottom"
                          disabled={!person.can_be_main_ubo}
                          confirmButtonNameKey="Confirm"
                          cancelButtonNameKey="Cancel"
                        >
                          <Checkbox
                            checked={!!person.main_ubo}
                            disabled={!person.can_be_main_ubo}
                          />
                        </ActionWithConfirmation>
                        <StyledPersonsCheckboxTitle className="PersonsCheckboxTitle">
                          Main UBO
                        </StyledPersonsCheckboxTitle>
                      </StyledPersonsCheckbox>
                    </StyledPersons>
                    <StyledPersonsCheckboxInfo className="PersonsCheckboxInfo">
                      {person.tagvalues.map(({ title, value }) => {
                        return (
                          <ValueRow key={title} label={title} value={value} />
                        );
                      })}
                    </StyledPersonsCheckboxInfo>
                  </div>
                );
              })}
            </StyledPersonsBlock>
          </div>
        );
      })}
    </div>
  );
};
