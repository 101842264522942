import { useEffect, useRef } from 'react';

import { compareValues } from '~/utils/forms/compareValues';

type Value = string | number | boolean | null | undefined;

export const useHighlightingField = (value: Value, warning?: boolean) => {
  const defaultValueRef = useRef<Value>(undefined);

  useEffect(() => {
    defaultValueRef.current = value;
  }, []);

  const isChanging = warning && compareValues(defaultValueRef.current, value);

  return {
    isChanging,
  };
};
