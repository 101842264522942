import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useMemo,
  useState,
} from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { TChildren } from 'types/TChildren';
import { TSectionChildren } from '~/api/applications/applications.types';

import { PATHS } from '../../../../router';
import { TParams } from '../../../../router/router.types';
import {
  RootTabStructureKey,
  TabStructureKey,
} from '../../ApplicationEntry.constants';
import { mapTabsUrls } from '../../tabs/Tabs/Tabs.constants';

import { TTab, TCurrentTab } from './TabsContext.types';

type TTabsContext = {
  isTabsLoading: boolean;
  tabs: TTab[];
  currentTab: TCurrentTab | null;
  subTabs?: TTab[];
  currentTabIsDirty: boolean;
  setCurrentTabIsDirty: Dispatch<SetStateAction<boolean>>;
};

type TTabsProviderProps = {
  structure?: TSectionChildren[];
};

export const TabsContext = createContext<TTabsContext>({
  isTabsLoading: false,
  tabs: [],
  currentTab: null,
  currentTabIsDirty: false,
  setCurrentTabIsDirty: () => {},
});

export const TabsProvider: FC<TChildren & TTabsProviderProps> = ({
  children,
  structure,
}) => {
  const location = useLocation();
  const { id } = useParams<TParams>();

  const [currentTabIsDirty, setCurrentTabIsDirty] = useState(false);

  const tabs: TTab[] =
    structure
      ?.filter(({ resourcetype }) => resourcetype === RootTabStructureKey)
      ?.map((item) => {
        const url = item?.name
          ? mapTabsUrls[item.name as keyof typeof mapTabsUrls]
          : '';

        const subTubs =
          item?.children
            ?.filter(
              (subTab) =>
                subTab.resourcetype === TabStructureKey &&
                // temporarily removed the "Access Rights" subtab
                subTab.name !== 'access-rights-subtab',
            )
            ?.map((elem) => {
              const urlSubTab = elem?.name
                ? mapTabsUrls[elem.name as keyof typeof mapTabsUrls]
                : '';
              return {
                title: elem?.title || '',
                name: elem?.name || '',
                url: urlSubTab,
                disabled: !urlSubTab,
                children: elem.children,
              };
            }) || [];

        const subTabHash = subTubs?.length ? `#${subTubs[0].url}` : '';

        return {
          title: item?.title || '',
          name: item?.name || '',
          url: `${PATHS.USERS}/${id}/${url}${subTabHash}`,
          disabled: !url,
          subTabs: subTubs,
          children: item.children,
        };
      }) || [];

  const isLoading = false;

  const currentTab = useMemo<TCurrentTab>(() => {
    const match = location.pathname.split('/')[4];
    const tab = tabs.find(({ url }) => url.includes(match));
    const tabIdx = tabs.findIndex(
      ({ url, disabled }) => url.includes(match) && !disabled,
    );

    return {
      tab,
      number: tabIdx,
    };
  }, [location, tabs]);

  const subTabs = currentTab?.tab?.subTabs || [];

  const value = useMemo<TTabsContext>(
    () => ({
      tabs: tabs || [],
      subTabs,
      isTabsLoading: isLoading,
      currentTab,
      currentTabIsDirty,
      setCurrentTabIsDirty,
    }),
    [tabs, isLoading, currentTabIsDirty, setCurrentTabIsDirty],
  );

  return <TabsContext.Provider value={value}>{children}</TabsContext.Provider>;
};
