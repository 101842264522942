import { createElement } from 'react';

import { DEFAULT_EMPTY_PLACEHOLDER } from '~/constants/common';
import { mapIcons } from '~/constants/statusesInfoMap';

import { StyledStatusCell } from './StatusCell.styled';
import { StatusCellProps } from './StatusCell.types';

export const StatusCell = ({ type, status }: StatusCellProps) => {
  const icon =
    status && mapIcons[type][status]
      ? createElement(mapIcons[type][status])
      : '';

  return (
    <StyledStatusCell className="StatusCell" status={status} type={type}>
      {icon}
      {status || DEFAULT_EMPTY_PLACEHOLDER}
    </StyledStatusCell>
  );
};
