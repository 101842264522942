import cn from 'classnames';
import { FC, useContext, useMemo, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Notification, Table } from 'react-ui-kit-exante';

import {
  useAddTableRowMutation,
  useAddTableValueMutation,
  useDeleteTableRowMutation,
  useLazyGetTableSectionQuery,
} from '~/api';
import { formatDate } from '~/utils/dates/formatDate';

import { ApplicationContext } from '../../../contexts/ApplicationContext';

import { StyledTableContainer } from './SectionTableBody.styled';
import { TSectionTableBody } from './SectionTableBody.types';
import { getColumns } from './columns';
import { AddRowForm } from './components/AddRowForm';

export const SectionTableBody: FC<TSectionTableBody> = ({
  section,
  parent,
}) => {
  const { application } = useContext(ApplicationContext);
  const formContext = useFormContext();

  const applicationId = application?.application?.id;
  const sectionName = section.name;

  const inflowOutflowFlag =
    sectionName === 'inflow_outflow_table_section' &&
    formContext.watch('inflow_outflow_flag');

  const [getTableSection, { data: tableInfo, isLoading, isFetching }] =
    useLazyGetTableSectionQuery();
  const [onDeleteTableRow, state] = useDeleteTableRowMutation();
  const [addTableRow, addTableRowState] = useAddTableRowMutation();
  const [addTableValue, addTableValueState] = useAddTableValueMutation();

  const getTable = () => {
    if (applicationId && sectionName) {
      getTableSection({
        applicationId,
        tableKey: sectionName,
      });
    }
  };

  useEffect(() => {
    getTable();
  }, []);

  const onDeleteRow = (rowId: number) => {
    onDeleteTableRow({ rowId });
  };

  const actionProcessing = Boolean(state.isLoading);

  const columns = getColumns({
    columns: tableInfo?.columns,
    tableName: sectionName,
    rows: tableInfo?.rows,
    deleteAction: onDeleteRow,
    actionProcessing,
  });

  const addActionIsDisable = useMemo(() => {
    if (sectionName === 'inflow_outflow_table_section') {
      return !inflowOutflowFlag;
    }
    return false;
  }, [section, inflowOutflowFlag]);
  const handleAdd = async (formState: Record<string, unknown>) => {
    if (tableInfo?.table_id && applicationId) {
      const res = await addTableRow({
        numberOfRows: 1,
        tableId: tableInfo.table_id,
      });

      if (!('error' in res)) {
        const {
          data: { id },
        } = res;

        await Promise.all(
          Object.keys(formState).map(async (i) => {
            const value =
              formState[i] instanceof Date
                ? formatDate(formState[i] as Date)
                : (formState[i] as string);
            if (value) {
              addTableValue({
                columnId: Number(i),
                value,
                rowId: id,
                applicationId,
              });
            }
          }),
        )
          .then(() => {
            getTable();
            Notification.success({
              title: 'Values successfully added',
            });
          })
          .catch(() => {
            Notification.error({
              title: 'Value adding error ',
            });
          });
      } else {
        Notification.error({
          title: 'Row adding error ',
        });
      }
    }
  };

  const additionalActions = [
    {
      key: 'AddRowForm',
      component: (
        <AddRowForm
          section={parent || section}
          onAdd={handleAdd}
          disabled={addActionIsDisable}
          isLoading={addTableRowState.isLoading || addTableValueState.isLoading}
          fields={tableInfo?.columns}
        />
      ),
    },
  ];

  if (!sectionName) {
    return null;
  }

  return (
    <StyledTableContainer className="TableContainer">
      <Table
        className={cn('SectionTable', sectionName)}
        columns={columns}
        isLoading={isLoading || isFetching}
        data={tableInfo?.rows || []}
        tableId={sectionName}
        isFlexLayout
        disableSortBy
        hasFilters={false}
        additionalActions={additionalActions}
        skeletonsCount={4}
      />
    </StyledTableContainer>
  );
};
