import { getTableId } from '~/utils/table';

export const COLUMNS = [
  { Header: 'Email', accessor: 'user', disableSortBy: true },
  { Header: 'ClientID', accessor: 'client_id', disableSortBy: true },
  { Header: 'Source type', accessor: 'source_type', disableSortBy: true },
  {
    Header: 'Date',
    accessor: 'created',
    disableSortBy: true,
    formatting: 'dateTime',
  },
  { Header: 'Authdb id', accessor: 'authdb_id', disableSortBy: true },
  { Header: 'Application id', accessor: 'crm_id', disableSortBy: true },
  { Header: 'State', accessor: 'state', disableSortBy: true },
  { Header: 'Total NAV', accessor: 'total_nav', disableSortBy: true },
];
export const TABLE_ID = getTableId('referrals');
