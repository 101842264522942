import { createApi } from '@reduxjs/toolkit/query/react';

import { TDefaultPaginationResponse, TDefaultParams } from '~/types/api';
import { baseQueryHandler } from '~/utils/apiRequest';

import { TParnersReferralsTable } from './partners.types';

export const partnersApi = createApi({
  reducerPath: 'partnersApi',
  baseQuery: baseQueryHandler,
  tagTypes: ['Partners'],
  endpoints: (builder) => ({
    getPartnersReferrals: builder.query<
      TDefaultPaginationResponse<TParnersReferralsTable>,
      TDefaultParams
    >({
      query: (params) => ({
        url: `/rest/partners/referrals/`,
        params,
      }),
      providesTags: ['Partners'],
    }),
  }),
});

export const { useLazyGetPartnersReferralsQuery } = partnersApi;
