import { endOfDay, startOfDay } from 'date-fns';

import {
  TDateRangeFilterHandler,
  TPredefinedDateRange,
} from 'Components/Table/types';

import { PredefinedDataRangeStyled } from '../styled';
import { TDateValue } from '../types';

import { toDate } from './helpers';

interface PredefinedDateRangesProps {
  items: TPredefinedDateRange[];
  onChangeDateRange: (
    values: TDateValue[],
    onChange: TDateRangeFilterHandler,
  ) => void;
  setRange: (min: Date, max: Date) => void;
  setLocalComponentRange: (min: Date, max: Date) => void;
}

export const PredefinedDateRanges = ({
  items,
  onChangeDateRange,
  setLocalComponentRange,
  setRange,
}: PredefinedDateRangesProps) => {
  return (
    <>
      {items.map(({ title, onFilter, range }) => {
        const [startRangeDate, endRangeDate] = range.map(toDate);

        const handleClick = () => {
          const [dayStart, dayEnd] = [
            startOfDay(startRangeDate),
            endOfDay(endRangeDate),
          ];

          setLocalComponentRange(dayStart, dayEnd);

          if (onFilter && onChangeDateRange) {
            onChangeDateRange([startRangeDate, endRangeDate], onFilter);
          } else {
            setRange(startRangeDate, endRangeDate);
          }
        };

        return (
          <PredefinedDataRangeStyled
            key={title}
            title={title}
            onClick={handleClick}
            data-test-id={`filters__button--set-date-range-to-${title.toLowerCase()}`}
            type="button"
          >
            {title}
          </PredefinedDataRangeStyled>
        );
      })}
    </>
  );
};
