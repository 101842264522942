import { memo } from 'react';

import {
  ActionButtonProps,
  isPropsForActionWithConfirmationBtn,
  isPropsForActionWithConfirmationWithSelectBtn,
  isPropsForIconBtn,
} from './ActionButton.types';
import {
  ActionButtonWithConfirmation,
  ActionButtonWithConfirmationWithSelect,
} from './components';
import { ActionIconButton } from './components/ActionIconButton';

const ActionButton = (buttonProps: ActionButtonProps) => {
  if (isPropsForIconBtn(buttonProps)) {
    return <ActionIconButton {...buttonProps.props} />;
  }

  if (isPropsForActionWithConfirmationBtn(buttonProps)) {
    return <ActionButtonWithConfirmation {...buttonProps.props} />;
  }

  if (isPropsForActionWithConfirmationWithSelectBtn(buttonProps)) {
    return <ActionButtonWithConfirmationWithSelect {...buttonProps.props} />;
  }

  return null;
};

export const ActionButtonMemoized = memo(ActionButton);
