import { useEffect, useState } from 'react';
import { ICellValue, IColumn } from 'react-ui-kit-exante';

import { useGetComputedUsersTableFiltersQuery } from '~/api';
import { TServerColumn } from '~/api/computedUsers/computedUsers.types';
import {
  TComputedUsers,
  TNormalizedColumn,
} from '~/pages/ComputedUsers/ComputedUsers.types';
import { TTableFilter } from '~/types/table';

import { formatStringToColumn } from '../../utils/formatStringToColumn';
import { getTooltipJsonFormat } from '../../utils/getTooltipJsonFormat';

import {
  columnFormatFields,
  dateFieldsFormat,
  jsonFormatFields,
} from './ComputedUsers.constants';

export function useDynamicData() {
  const [columns, setColumns] = useState<TNormalizedColumn[]>([]);
  const [defaultVisibleColumns, setDefaultVisibleColumns] = useState<
    string[] | null
  >(null);

  const [filters, setFilters] = useState<TTableFilter[]>([]);

  const { data, isLoading, isFetching } =
    useGetComputedUsersTableFiltersQuery();

  const isLoadingMetadata = isLoading || isFetching;

  const getNormalizedColumns = (responseColumns: IColumn<TServerColumn>[]) => {
    return responseColumns?.reduce((acc, item): IColumn<TComputedUsers>[] => {
      const columnId: string = item.id;

      const dateFieldFormat = dateFieldsFormat?.[columnId];
      const isColumnFormat = columnFormatFields.includes(columnId);
      const isJsonFormat = jsonFormatFields.includes(columnId);

      return [
        ...acc,
        {
          accessor: item.id,
          Header: item.title,
          disableFilters: true, // we are getting filters from backend
          ...(dateFieldFormat
            ? {
                formatting: dateFieldFormat,
              }
            : {}),
          ...(isColumnFormat
            ? {
                Cell: ({
                  row: { values },
                }: ICellValue<Record<string, unknown>>) => {
                  const value = values?.[item.id]?.split(',');
                  return formatStringToColumn(value);
                },
              }
            : {}),
          ...(isJsonFormat ? getTooltipJsonFormat() : {}),
        },
      ];
    }, [] as IColumn<TServerColumn>[]);
  };

  useEffect(() => {
    if (!isLoadingMetadata && data) {
      setColumns(getNormalizedColumns(data?.columns));
      setDefaultVisibleColumns(
        data?.columns?.filter((col) => col.checked).map((col) => col.id),
      );
      setFilters(data.filters);
    }
  }, [data, isLoadingMetadata]);

  return {
    columns,
    defaultVisibleColumns,
    isLoadingMetadata,
    filters,
  };
}
