import { AuditLogsTable } from '../ActivityTab/components/AuditLogsTable';
import { NotificationsTable } from '../ActivityTab/components/NotificationsTable';
import { TradeTable } from '../ActivityTab/components/TradeTable';
import { TransactionsTable } from '../ActivityTab/components/TransactionsTable';
import {
  DepositsSubTab,
  FinancialSettingsSubTab,
  WithdrawalsSubTab,
} from '../FinancialTab';
import { InfoTab } from '../InfoTab/InfoTab';
import { AffiliatedTab } from '../OnboardingTab/AffiliatedTab';
import { MonitoringTab } from '../OnboardingTab/MonitoringTab';
import { OnboardingSettingsTab } from '../OnboardingTab/OnboardingSettingsTab';
import { RiskScoringTab } from '../OnboardingTab/RiskScoringTab';
import { PartnersSettingsTab } from '../PartnersTab/PartnersSettingsTab';
import { ReferralsTab } from '../PartnersTab/ReferralsTab';
import { QualifiedLeadTab } from '../QualifiedLeadTab';
import { AccountPermissionsContainer } from '../UserSettingsTab/components/AccountPermissions';
import { NotificationsEditor } from '../UserSettingsTab/components/NotificationsEditor';
import { PasswordManagementContainer } from '../UserSettingsTab/components/PasswordManagement';

export const mapTabsComponents = {
  info: <InfoTab />,

  onboarding_settings_subtab: <OnboardingSettingsTab />,
  'affiliated-subtab': <AffiliatedTab />,
  'monitoring-subtab': <MonitoringTab />,
  'risk_scoring-subtab': <RiskScoringTab />,

  'financial-settings-subtab': <FinancialSettingsSubTab />,
  'deposits-subtab': <DepositsSubTab />,
  'withdrawals-subtab': <WithdrawalsSubTab />,

  'qualified-lead': <QualifiedLeadTab />,

  'partners-settings-subtab': <PartnersSettingsTab />,
  referrals: <ReferralsTab />,

  'account-permissions-subtab': <AccountPermissionsContainer />,
  'password-management-subtab': <PasswordManagementContainer />,
  'notifications-subtab': <NotificationsEditor />,

  'trades-subtab': <TradeTable />,
  'transactions-subtab': <TransactionsTable />,
  'notifications-activity-subtab': <NotificationsTable />,
  'audit-logs-subtab': <AuditLogsTable />,
};
