import { ReactNode } from 'react';

import { CommentsSection } from '~/pages/ApplicationEntry/tabs/InfoTab/components/CommentsSection/CommentsSection';
import { LinkedApplication } from '~/pages/ApplicationEntry/tabs/InfoTab/components/LinkedApplication';
import { NextCommunicationDate } from '~/pages/ApplicationEntry/tabs/InfoTab/components/NextCommunicationDate';
import { PersonInfoBlocks } from '~/pages/ApplicationEntry/tabs/OnboardingTab/AffiliatedTab/components/PersonInfoBlocks';
import { ClientForm } from '~/pages/ApplicationEntry/tabs/OnboardingTab/OnboardingSettingsTab/components/ClientForm';
import { NameForm } from '~/pages/ApplicationEntry/tabs/OnboardingTab/OnboardingSettingsTab/components/NameForm';
import { OnboardingLog } from '~/pages/ApplicationEntry/tabs/OnboardingTab/OnboardingSettingsTab/components/OnboardingLog';
import { UploadedDocuments } from '~/pages/ApplicationEntry/tabs/OnboardingTab/OnboardingSettingsTab/components/UploadedDocuments';
import { ValidationAndCategorization } from '~/pages/ApplicationEntry/tabs/OnboardingTab/OnboardingSettingsTab/components/ValidationAndCategorization';
import { AddNewTag } from '~/pages/ApplicationEntry/tabs/QualifiedLeadTab/components/AddNewTag';

// hardcoded sections in application card
export const mapSectionKeyToStaticComponent: Record<string, ReactNode> = {
  // Info tab sections
  'communication-date-section': <NextCommunicationDate />,
  'app-comments-section': <CommentsSection />,
  'linked-apps-section': <LinkedApplication />,

  // Qualified lead tab sections
  'add-new-tag-section': <AddNewTag />,

  // Onboarding lead tab sections
  'name-section': <NameForm />,
  'onboarding-log-section': <OnboardingLog />,
  'client-info-section': <ClientForm />,
  'uploaded-docs-section': <UploadedDocuments />,
  'onboarding-questions-section': <ValidationAndCategorization />,
  'affiliated-section': <PersonInfoBlocks />,
};

export const HIDDEN_TITLES = [
  'Uploaded Docs',
  'Affiliated section',
  'Exchange subscription categories',
  'Name section local',
];
