import { useCallback, useContext, useEffect } from 'react';

import {
  useApplicationActionsMutation,
  useSaveApplicationMutation,
} from '~/api';
import { TTagForSave } from '~/api/applications/applications.types';
import { ActionWithConfirmationWithSelectProps } from '~/modules/actionButtonsBlock/ActionButton';
import { ApplicationContext } from '~/pages/ApplicationEntry/contexts/ApplicationContext';
import { IOption } from '~/types/form/options';

import { ActionButtonWithConfirmationWithSelectEnum } from '../Header.types';
import {
  applicationSuccessMessage,
  buttonWithConfirmationWithSelectKeysToActionsTypeMap,
  clientSupportOnboardingReasonOptions,
  staticButtonWithConfirmationWithSelectPropsMap,
} from '../constants';

import { useGetApplicationTagsData } from './useGetApplicationTagsData';

export const useGetButtonWithConfirmationWithSelectProps = () => {
  const {
    application,
    isLoadingApplication,
    isDisableActionButtons,
    setIsDisableActionButtons,
  } = useContext(ApplicationContext);
  const applicationId = application?.application?.id;

  const [saveApplication] = useSaveApplicationMutation();
  const [applicationActions, { isLoading: isApplicationActionsLoading }] =
    useApplicationActionsMutation();

  const {
    closedOptions,
    closureReasonId,
    closureReasonTagId,
    defaultClosureDetailsId,
    defaultClosureDetailsTagId,
    defaultClosureDetails,
    defaultClosureReason,
  } = useGetApplicationTagsData();

  useEffect(() => {
    setIsDisableActionButtons(isApplicationActionsLoading);
  }, [isApplicationActionsLoading, setIsDisableActionButtons]);

  const handleSendApplicationActions = useCallback(
    (key: ActionButtonWithConfirmationWithSelectEnum, reason?: string) =>
      () => {
        if (applicationId) {
          const action =
            buttonWithConfirmationWithSelectKeysToActionsTypeMap[key];

          applicationActions({
            applicationId,
            action,
            successMessage: applicationSuccessMessage[action],
            reason,
          });
        }
      },
    [applicationActions, applicationId],
  );

  const saveApplicationHandler = useCallback(
    (tags: TTagForSave[]) => {
      if (applicationId) {
        saveApplication({
          applicationId,
          params: {
            tags,
          },
        });
      }
    },
    [applicationId, saveApplication],
  );

  const getHandleConfirm = useCallback(
    (buttonKey: ActionButtonWithConfirmationWithSelectEnum) =>
      (closureDetails?: string, closureReason?: IOption[]) => {
        let reason: string | undefined;

        if (closureReason && buttonKey === 'sendAppToOnboardingWithSelectBtn') {
          reason = [closureReason[0].value, closureDetails].join(' ').trim();
        }

        if (
          buttonKey === 'blockBtn' &&
          closureReasonId &&
          closureReasonTagId &&
          defaultClosureDetailsId &&
          defaultClosureDetailsTagId
        ) {
          const tags = [
            {
              id: closureReasonId,
              tag_id: closureReasonTagId,
              text_value: closureReason
                ?.map(({ value }: { value: string }) => value)
                .join(),
            },
            {
              id: defaultClosureDetailsId,
              tag_id: defaultClosureDetailsTagId,
              text_value: closureDetails,
            },
          ];

          saveApplicationHandler(tags);
        }

        handleSendApplicationActions(buttonKey, reason)();
      },
    [
      closureReasonId,
      closureReasonTagId,
      defaultClosureDetailsId,
      defaultClosureDetailsTagId,
      handleSendApplicationActions,
      saveApplicationHandler,
    ],
  );

  const optionsMap = {
    blockBtn: {
      optionsForAutocomplete: closedOptions,
    },
    sendAppToOnboardingWithSelectBtn: {
      optionsForAutocomplete: clientSupportOnboardingReasonOptions,
    },
  };

  const getButtonWithConfirmationWithSelectProps = (
    buttonKey: ActionButtonWithConfirmationWithSelectEnum,
  ): ActionWithConfirmationWithSelectProps['props'] => {
    const staticProps =
      staticButtonWithConfirmationWithSelectPropsMap[buttonKey];

    const defaultCommentValue =
      buttonKey === 'blockBtn' && typeof defaultClosureDetails === 'string'
        ? defaultClosureDetails
        : undefined;
    const defaultSelectOptions =
      buttonKey === 'blockBtn' && defaultClosureReason
        ? defaultClosureReason
        : undefined;

    return {
      defaultCommentValue,
      defaultSelectOptions,
      isLoadingActions: isLoadingApplication || isDisableActionButtons,
      handleConfirm: getHandleConfirm(buttonKey),
      options: optionsMap[buttonKey].optionsForAutocomplete,
      ...staticProps,
    };
  };

  return {
    getButtonWithConfirmationWithSelectProps,
  };
};
