import { FormSelectContainer } from '~/components/Form';
import { useAccountStatuses, usePickUserPermissions } from '~/hooks';

import { StyledAccountPermissionsAtp } from './AccountPermissionsAtp.styled';

export const AccountPermissionsAtp = ({ warning }: { warning?: boolean }) => {
  const { accountStatusesOptions } = useAccountStatuses();

  const userPermissions = usePickUserPermissions([
    'User account status',
    'User default account access',
  ]);

  const hasWriteAccess =
    userPermissions['User default account access'].write &&
    userPermissions['User account status'].write;

  if (!userPermissions['User default account access'].read) {
    return null;
  }

  return (
    <StyledAccountPermissionsAtp className="AccountPermissionsAtp">
      <FormSelectContainer
        disabled={!hasWriteAccess}
        fullWidth
        label="Default account access"
        name="defaultAccountAccess"
        options={accountStatusesOptions}
        warning={warning}
      />
    </StyledAccountPermissionsAtp>
  );
};
