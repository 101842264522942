import { TTag } from '../api/applications/applications.types';

export const DEFAULT_EMPTY_PLACEHOLDER = '–';
export const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd';
export const DEFAULT_DATE_TIME_FORMAT = 'yyyy-MM-dd HH:mm';
export const MIN_BIRTH_DATE = new Date('1900-01-01T00:00:00');
export const MAX_BIRTH_DATE = new Date();
export const DEFAULT_CURRENCY = 'EUR';

export enum FieldTypes {
  Select = 'select',
  Autocomplete = 'autocomplete',
  Checkbox = 'checkbox',
  DateField = 'date',
  DateTimeField = 'datetime',
  NumberField = 'number',
  Input = 'input',
  Textarea = 'textarea',
  File = 'file',
}

export const mapTagTypeToFieldType: Record<string, FieldTypes> = {
  flag: FieldTypes.Checkbox,
  input: FieldTypes.Input,
  id: FieldTypes.Input,
  textarea: FieldTypes.Textarea,
  money: FieldTypes.NumberField,
  numeric: FieldTypes.NumberField,
  date: FieldTypes.DateField,
};

const autocompleteTypes: string[] = [
  'generic',
  'manager',
  'choice',
  'text',
  'event',
];
const selectTypes: string[] = [];

export const getFieldType = (tag: TTag): FieldTypes => {
  if (autocompleteTypes.includes(tag.type)) {
    return FieldTypes.Autocomplete;
  }

  // remove when values appear in selectTypes
  // eslint-disable-next-line prettier/prettier
  if (selectTypes.includes(tag.type)) { // NOSONAR
    if (tag.is_multiple) {
      return FieldTypes.Autocomplete;
    }
    return FieldTypes.Select;
  }

  return mapTagTypeToFieldType[tag.type];
};

export const CHECK_ONLY_NUMBER = /^\d+$/;

export const EMPTY_ARRAY: never[] = [];

export const ALL_LEGAL_ENTITY = 'All';
