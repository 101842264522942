import { useEffect, useCallback, useMemo, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  IOnFetchArguments,
  useTableData,
  calculateCountOfPages,
  Table,
  TAdditionalFilter,
} from 'react-ui-kit-exante';

import {
  useGetWithdrawalsTableFiltersQuery,
  useLazyGetWithdrawalsQuery,
} from '~/api';
import { TWithdrawalsData } from '~/api/withdrawals/withdrawals.types';
import { contextSearchFilter, defaultSortByGlobal } from '~/constants/table';
import {
  DISPLAYED_COLUMNS_KEYS,
  fieldsParams,
  getColumns,
  WITHDRAWALS_DATE_FIELDS,
} from '~/constants/withdrawals';
import {
  useLogHandleTime,
  useCallbackTriggerHandle,
  useGetTableFilterOptions,
} from '~/hooks';
import { ApplicationContext } from '~/pages/ApplicationEntry/contexts/ApplicationContext';
import { PATHS } from '~/router';
import { prepareFilterParams } from '~/utils/prepareFilterParams';
import {
  getPaginationParams,
  getDefaultPagination,
  getFilterParams,
  getAdditionalFilters,
} from '~/utils/table';
import { getSortingParams } from '~/utils/table/sorting';

import { TABLE_ID } from './WithdrawalsTable.constants';
import { getRowProps } from './WithdrawalsTable.helpers';
import { StyledWithdrawalsTableWrapper } from './WithdrawalsTable.styled';

export const WithdrawalsTable = () => {
  const { setStartHandleTime, logHandleTime } = useLogHandleTime(
    `application-entry-${TABLE_ID}`,
  );
  const { application, activeLegalEntity } = useContext(ApplicationContext);

  const applicationId = application?.application?.id;

  const navigate = useNavigate();
  const { data: withdrawalsTableFilters } =
    useGetWithdrawalsTableFiltersQuery();
  const [
    fetchWithdrawals,
    {
      isLoading: isGetWithdrawalsLoading,
      isFetching: isFetchingWithdrawals,
      isSuccess: isGetWithdrawalsSuccess,
    },
  ] = useLazyGetWithdrawalsQuery();

  useEffect(() => {
    if (isGetWithdrawalsLoading) {
      setStartHandleTime();
    }
  }, [isGetWithdrawalsLoading, setStartHandleTime]);

  const additionalOptions = useGetTableFilterOptions(withdrawalsTableFilters);

  const getWithdrawals = useCallback(
    async (params: IOnFetchArguments) => {
      const paginationParams = getPaginationParams(params);
      const filterParams = prepareFilterParams(
        getFilterParams(params, WITHDRAWALS_DATE_FIELDS),
      );
      const sortingParams = getSortingParams(params);

      if (applicationId) {
        const response = await fetchWithdrawals({
          ...paginationParams,
          ...filterParams,
          ...fieldsParams,
          ...sortingParams,
          field_application: applicationId,
          field_legal_entity: activeLegalEntity || undefined,
        });

        return response.data;
      }

      return undefined;
    },
    [fetchWithdrawals, applicationId, activeLegalEntity],
  );

  const tableDataArgs = useMemo(
    () => ({
      tableId: TABLE_ID,
      data: { onFetch: getWithdrawals },
      pagination: {
        getDefaultPagination,
      },
      filters: {
        getDefaultFilters: () => {
          return contextSearchFilter;
        },
      },
      saveViewParamsAfterLeave: true,
      hasNegativeFilters: true,
    }),
    [getWithdrawals],
  );

  const {
    data,
    limit,
    setLimit,
    setPage,
    page,
    skip,
    isLoading,
    setFilter,
    removeFilter,
    resetFilters,
    filters,
    setSorting,
    params,
  } = useTableData(tableDataArgs);

  const isTableLoading = isLoading || isFetchingWithdrawals;

  const additionalFilters = useMemo<
    TAdditionalFilter<Record<string, unknown>>[]
  >(
    () =>
      getAdditionalFilters({
        onFilter: setFilter,
        onRemove: removeFilter,
        filters: withdrawalsTableFilters,
        additionalOptions,
        withContextSearch: true,
      }),
    [removeFilter, setFilter, withdrawalsTableFilters, additionalOptions],
  );

  const columns = getColumns();

  const total = data?.iTotalDisplayRecords ?? 0;

  const filterProps = useMemo(
    () => ({
      removeAllFilters: resetFilters,
      additionalFilters,
      filters,
      manualFilters: true,
    }),
    [additionalFilters, filters, resetFilters],
  );

  const pageCount = useMemo(
    () => calculateCountOfPages(total, limit),
    [limit, total],
  );

  const serverPaginationProps = useMemo(
    () => ({
      pageSize: limit,
      skip,
      setPage,
      setPageSize: setLimit,
      pageIndex: page,
      total,
      pageCount,
    }),
    [skip, limit, page, pageCount, setLimit, setPage, total],
  );

  const handleRowClick = useCallback(
    ({ id }: TWithdrawalsData) =>
      navigate(`${PATHS.WITHDRAWALS}/${id}`, {
        state: {
          previousPath: window.location.href,
          requestParams: params,
          fromApplication: true,
        },
      }),
    [navigate, params],
  );

  useCallbackTriggerHandle({
    cb: logHandleTime,
    dataTrigger: data?.data,
    processTrigger: !isGetWithdrawalsLoading && isGetWithdrawalsSuccess,
  });

  return (
    <StyledWithdrawalsTableWrapper className="WithdrawalsTableWrapper">
      <Table
        className="WithdrawalsTable"
        columns={columns}
        isLoading={isTableLoading}
        displayedColumnKeys={DISPLAYED_COLUMNS_KEYS}
        isFlexLayout
        filtersExpanded
        onSort={setSorting}
        defaultSortBy={defaultSortByGlobal}
        manualSortBy
        data={data?.data || []}
        tableId={TABLE_ID}
        hasPagination
        hasFilters
        hasNegativeFilters
        showTableInfo
        filteringProps={filterProps}
        saveViewParamsAfterLeave
        serverPaginationProps={serverPaginationProps}
        getRowProps={getRowProps}
        saveColumnOrder
        handleRowClick={handleRowClick}
      />
    </StyledWithdrawalsTableWrapper>
  );
};
