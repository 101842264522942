import { skipToken } from '@reduxjs/toolkit/query';
import { useContext, useMemo } from 'react';

import { useGetApplicationActionsInfoQuery } from '~/api';
import {
  ActionButton,
  ActionButtonProps,
  ActionButtonType,
} from '~/modules/actionButtonsBlock/ActionButton';
import { ApplicationContext } from '~/pages/ApplicationEntry/contexts/ApplicationContext';

import {
  ActionButtonWithConfirmationEnum,
  ActionButtonWithConfirmationWithSelectEnum,
  ActionIconButtonEnum,
} from '../Header.types';

import { useGetButtonWithConfirmationProps } from './useGetButtonWithConfirmationProps';
import { useGetButtonWithConfirmationWithSelectProps } from './useGetButtonWithConfirmationWithSelectProps';
import { useGetIconButtonProps } from './useGetIconButtonProps';

export const useGetActions = () => {
  const { application } = useContext(ApplicationContext);

  const isClientSupportPreCheckState =
    application?.application?.state === 'client_pre_check';

  const applicationId = application?.application?.id;
  const queryParams = applicationId
    ? {
        applicationId,
      }
    : skipToken;

  const { data } = useGetApplicationActionsInfoQuery(queryParams);

  const dataActions = data?.actions ?? [];

  const infoHiddenActions = dataActions.reduce((acc, { name, hidden }) => {
    acc[name] = !hidden;
    return acc;
  }, {} as Record<string, boolean>);

  const { getIconButtonProps } = useGetIconButtonProps();
  const { getButtonWithConfirmationProps } =
    useGetButtonWithConfirmationProps();
  const { getButtonWithConfirmationWithSelectProps } =
    useGetButtonWithConfirmationWithSelectProps();

  const pendingBtnType = isClientSupportPreCheckState
    ? {
        key: ActionButtonWithConfirmationWithSelectEnum.SendAppToOnboardingWithSelectBtn,
        type: ActionButtonType.WithConfirmationWithSelect,
        props: getButtonWithConfirmationWithSelectProps(
          ActionButtonWithConfirmationWithSelectEnum.SendAppToOnboardingWithSelectBtn,
        ),
      }
    : {
        key: ActionIconButtonEnum.SendFromPendingBtn,
        type: ActionButtonType.IconBtn,
        props: getIconButtonProps(ActionIconButtonEnum.SendFromPendingBtn),
      };

  const actions = [
    infoHiddenActions.approve
      ? {
          key: ActionButtonWithConfirmationEnum.ApproveBtn,
          type: ActionButtonType.WithConfirmationBtn,
          props: getButtonWithConfirmationProps(
            ActionButtonWithConfirmationEnum.ApproveBtn,
          ),
        }
      : null,
    infoHiddenActions.unapprove
      ? {
          key: ActionButtonWithConfirmationEnum.UnapproveBtn,
          type: ActionButtonType.WithConfirmationBtn,
          props: getButtonWithConfirmationProps(
            ActionButtonWithConfirmationEnum.UnapproveBtn,
          ),
        }
      : null,
    infoHiddenActions.send_to_pending
      ? {
          key: ActionButtonWithConfirmationEnum.SendAppToPendingBtn,
          type: ActionButtonType.WithConfirmationBtn,
          props: getButtonWithConfirmationProps(
            ActionButtonWithConfirmationEnum.SendAppToPendingBtn,
          ),
        }
      : null,
    infoHiddenActions.send_to_onboarding
      ? {
          key: ActionIconButtonEnum.SendAppToOnboardingBtn,
          type: ActionButtonType.IconBtn,
          props: getIconButtonProps(
            ActionIconButtonEnum.SendAppToOnboardingBtn,
          ),
        }
      : null,
    infoHiddenActions.client_support_pre_check
      ? {
          key: ActionIconButtonEnum.SendToClientSupportBtn,
          type: ActionButtonType.IconBtn,
          props: getIconButtonProps(
            ActionIconButtonEnum.SendToClientSupportBtn,
          ),
        }
      : null,
    infoHiddenActions.send_from_pending ? pendingBtnType : null,
    infoHiddenActions.send_to_registrant
      ? {
          key: ActionIconButtonEnum.SendAppToRegistrantBtn,
          type: ActionButtonType.IconBtn,
          props: getIconButtonProps(
            ActionIconButtonEnum.SendAppToRegistrantBtn,
          ),
        }
      : null,
    infoHiddenActions.convert_to_application
      ? {
          key: ActionIconButtonEnum.ConvertBtn,
          type: ActionButtonType.IconBtn,
          props: getIconButtonProps(ActionIconButtonEnum.ConvertBtn),
        }
      : null,
    infoHiddenActions.send_to_qualified
      ? {
          key: ActionIconButtonEnum.SendToQualifiedBtn,
          type: ActionButtonType.IconBtn,
          props: getIconButtonProps(ActionIconButtonEnum.SendToQualifiedBtn),
        }
      : null,
    infoHiddenActions.reject
      ? {
          key: ActionButtonWithConfirmationEnum.RejectBtn,
          type: ActionButtonType.WithConfirmationBtn,
          props: getButtonWithConfirmationProps(
            ActionButtonWithConfirmationEnum.RejectBtn,
          ),
        }
      : null,
    infoHiddenActions.send_message
      ? {
          key: ActionButtonWithConfirmationEnum.SendMessageBtn,
          type: ActionButtonType.WithConfirmationBtn,
          props: getButtonWithConfirmationProps(
            ActionButtonWithConfirmationEnum.SendMessageBtn,
          ),
        }
      : null,
    infoHiddenActions.login
      ? {
          key: ActionIconButtonEnum.LoginBtn,
          type: ActionButtonType.IconBtn,
          props: getIconButtonProps(ActionIconButtonEnum.LoginBtn),
        }
      : null,
    infoHiddenActions.delete
      ? {
          key: ActionButtonWithConfirmationEnum.DeleteBtn,
          type: ActionButtonType.WithConfirmationBtn,
          props: getButtonWithConfirmationProps(
            ActionButtonWithConfirmationEnum.DeleteBtn,
          ),
        }
      : null,
    infoHiddenActions.unblock
      ? {
          key: ActionButtonWithConfirmationEnum.UnblockBtn,
          type: ActionButtonType.WithConfirmationBtn,
          props: getButtonWithConfirmationProps(
            ActionButtonWithConfirmationEnum.UnblockBtn,
          ),
        }
      : null,
    infoHiddenActions.close_relationship
      ? {
          key: ActionButtonWithConfirmationWithSelectEnum.BlockBtn,
          type: ActionButtonType.WithConfirmationWithSelect,
          props: getButtonWithConfirmationWithSelectProps(
            ActionButtonWithConfirmationWithSelectEnum.BlockBtn,
          ),
        }
      : null,
  ].filter((item) => item !== null);

  // any is to fix the ts error, after raising the version of the typescript package there will be no build error
  const actionButtons = useMemo(
    () =>
      actions.map(({ key, type, props }: any) => {
        const buttonProps = { props, type } as ActionButtonProps;

        return <ActionButton key={key} {...buttonProps} />;
      }),
    [actions],
  );

  return actionButtons;
};
