import { createContext, FC, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Loader } from 'react-ui-kit-exante';

import { TChildren } from 'types/TChildren';
import {
  useLazyGetApplicationQuery,
  useLazyGetApplicationStructureQuery,
} from '~/api';
import { EntryScreenWrapper } from '~/components/EntryScreen';
import { ALL_LEGAL_ENTITY } from '~/constants/common';
import { TParams } from '~/router/router.types';
import { getLE } from '~/utils/getLE';

import { TApplicationContext } from './ApplicationContext.types';

const initialState: TApplicationContext = {
  application: null,
  activeLegalEntity: ALL_LEGAL_ENTITY,
  structure: null,
  setActiveLegalEntity: () => {},
  isStructureLoading: false,
  isLoadingApplication: false,
};

export const ApplicationContext =
  createContext<TApplicationContext>(initialState);

export const ApplicationProvider: FC<TChildren> = ({ children }) => {
  const { id } = useParams<TParams>();

  const [fetchApplication, applicationState] = useLazyGetApplicationQuery();
  const [fetchApplicationStructure, applicationStructureState] =
    useLazyGetApplicationStructureQuery();

  const [activeLegalEntity, setActiveLegalEntity] = useState<
    string | undefined
  >();

  const isLoadingApplication =
    applicationState?.isLoading || applicationState.isFetching;

  const structure =
    applicationStructureState?.data?.sections?.find(
      (section) => section.name === 'applications',
    ) || null;

  const getApplicationData = async () => {
    const { data } = await fetchApplication({
      id: String(id),
      legalEntity: activeLegalEntity || ALL_LEGAL_ENTITY,
    });

    if (data?.application?.id) {
      fetchApplicationStructure({
        id: data.application.id,
        legalEntity: activeLegalEntity || getLE(data?.available_le),
      });
    }
  };

  useEffect(() => {
    getApplicationData();
  }, [activeLegalEntity]);

  const value = useMemo<TApplicationContext>(
    () => ({
      structure,
      activeLegalEntity,
      isStructureLoading: applicationStructureState.isLoading,
      setActiveLegalEntity,
      application: applicationState.data || null,
      isLoadingApplication,
    }),
    [
      activeLegalEntity,
      applicationStructureState,
      applicationState.data,
      isLoadingApplication,
    ],
  );

  if (applicationState?.isLoading || !applicationState.data) {
    return (
      <EntryScreenWrapper>
        <Loader isCentered size={32} />
      </EntryScreenWrapper>
    );
  }

  return (
    <ApplicationContext.Provider value={value}>
      {children}
    </ApplicationContext.Provider>
  );
};
