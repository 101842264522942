import { FC, useContext } from 'react';

import { Accordion } from '~/components/Accordion';

import {
  StaticSectionKey,
  TableSectionKey,
} from '../../../../ApplicationEntry.constants';
import { ApplicationFormContext } from '../../../../contexts/FormContext';
import { SectionBody } from '../../SectionBody';
import { SectionTableBody } from '../../SectionTableBody';
import {
  HIDDEN_TITLES,
  mapSectionKeyToStaticComponent,
  SECTIONS_WITH_BIG_GAP,
} from '../Section.constants';
import { StyledSection, StyledTitle } from '../Section.styled';
import { TSectionProps } from '../Section.types';

import { StyledSubSectionWithBigGap } from './SubSection.styled';

export const SubSection: FC<TSectionProps> = ({
  section,
  tags,
  isInnerSection,
  withoutText,
  parent,
}) => {
  const sectionIsStatic = section.resourcetype === StaticSectionKey;
  const sectionIsTable = section.resourcetype === TableSectionKey;
  const sectionName = section.name;

  const { newTags } = useContext(ApplicationFormContext);

  const tagKey = section?.name;

  const newTagsForSection =
    tagKey && newTags && tagKey in newTags && sectionName
      ? newTags[tagKey]
      : [];

  const allTags = [...newTagsForSection, ...(tags ?? [])];

  const title = section.title;
  const isCollapsed = section.is_collapsed;
  const isVisible = section.is_visible;

  if (!isVisible) {
    return null;
  }

  const content = (
    <>
      {sectionIsStatic &&
        sectionName &&
        mapSectionKeyToStaticComponent(title)[sectionName]}
      {allTags &&
        (!SECTIONS_WITH_BIG_GAP.includes(sectionName || '') ? (
          <SectionBody
            tags={allTags}
            tagKey={tagKey}
            withoutText={withoutText}
          />
        ) : (
          <StyledSubSectionWithBigGap
            tags={allTags}
            tagKey={tagKey}
            withoutText={withoutText}
          />
        ))}
      {sectionIsTable && <SectionTableBody section={section} parent={parent} />}
    </>
  );

  const subSectionTitle =
    title && !HIDDEN_TITLES.includes(title) ? (
      <StyledTitle className="StyledTitle" isCollapsibleTitle={isCollapsed}>
        {title}
      </StyledTitle>
    ) : null;

  return (
    <StyledSection isInnerSection={isInnerSection} className="Section">
      {isCollapsed ? (
        <Accordion title={subSectionTitle} margin="0 0 24px">
          {content}
        </Accordion>
      ) : (
        <>
          {subSectionTitle}
          {content}
        </>
      )}
    </StyledSection>
  );
};
