export enum ActionIconButtonEnum {
  SendFromPendingBtn = 'sendFromPendingBtn',
  SendAppToOnboardingBtn = 'sendAppToOnboardingBtn',
  SendAppToRegistrantBtn = 'sendAppToRegistrantBtn',
  LoginBtn = 'loginBtn',
  ConvertBtn = 'convertBtn',
  SendToQualifiedBtn = 'sendToQualifiedBtn',
  SendToClientSupportBtn = 'sendToClientSupportBtn',
}

export enum ActionButtonWithConfirmationEnum {
  ApproveBtn = 'approveBtn',
  UnapproveBtn = 'unapproveBtn',
  SendAppToPendingBtn = 'sendAppToPendingBtn',
  RejectBtn = 'rejectBtn',
  SendMessageBtn = 'sendMessageBtn',
  DeleteBtn = 'deleteBtn',
  UnblockBtn = 'unblockBtn',
}

export enum ActionButtonWithConfirmationWithSelectEnum {
  BlockBtn = 'blockBtn',
  SendAppToOnboardingWithSelectBtn = 'sendAppToOnboardingWithSelectBtn',
}
