import { DISPLAYED_COLUMNS_KEYS as WITHDRAWALS_DISPLAYED_COLUMNS_KEYS } from '~/constants/withdrawals';

export const DISPLAYED_COLUMNS_KEYS = [
  ...WITHDRAWALS_DISPLAYED_COLUMNS_KEYS,
  'client_id',
  'ip',
  'ip_country',
  'confirm_type',
  'assigned_to',
];
