import {
  EDepositStatuses,
  TCreateDepositBody,
} from '~/api/deposits/deposits.types';
import { FieldTypes } from '~/constants/common';
import { ACCEPT_FILE_LIST } from '~/constants/files';

import { TFieldConfig } from '../../DepositForm.types';

export const getClientDetailsFields = (
  deposit: TCreateDepositBody,
  form?: Record<string, unknown>,
): TFieldConfig[] => {
  return [
    {
      name: 'account_id',
      title: 'Account ID',
      optionsConfig: {
        options:
          deposit?.account_choices?.map((i) => ({ value: i, label: i })) || [],
      },
      type: form?.is_draft ? FieldTypes.Input : FieldTypes.Select,
      required: true,
    },
    {
      name: 'legal_entity',
      title: 'Legal entity',
      readOnly: true,
    },
    {
      name: 'custody',
      title: 'Custody',
    },
    {
      name: 'custody_account',
      title: 'Custody account',
    },
    {
      name: 'bank_name',
      title: 'Client custody',
    },
    {
      name: 'client_custody_account',
      title: 'Client custody account',
    },
    {
      name: 'bank_deposit_id',
      title: 'Bank Deposit ID',
      editable: deposit?.source !== 'bank-service',
    },
    {
      name: 'client_name',
      title: 'Client name',
      readOnly: true,
    },
  ].filter((item) => !deposit?.hidden_fields_keys?.includes(item.name));
};

export const getFileUpload = [
  {
    name: 'uploads',
    title: 'Client name',
    type: FieldTypes.File,
    accept: ACCEPT_FILE_LIST,
  },
];

export const depositCryptoFields = [
  'internal_id',
  'crypto_network',
  'transaction_hash',
  'transaction_url',
  'crypto_address_from',
  'crypto_address_to',
];

export const DEPOSIT_STATUS_TITLES = {
  '-1': EDepositStatuses.New,
  1: EDepositStatuses.BookedInBO,
  2: EDepositStatuses.Active,
  6: EDepositStatuses.FundsRejected,
  10: EDepositStatuses.OnHold,
};
