import * as Yup from 'yup';

export const getValidationPerson = () =>
  Yup.object({
    'percent ownership': Yup.mixed().test({
      test(value, { path, createError }) {
        if (value > 100 || value < 0) {
          return createError({
            message: "Can't be more 100 or less 0 in total for the Application",
            path,
          });
        }

        return true;
      },
    }),
  });
