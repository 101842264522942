import { useLocation, useNavigate } from 'react-router-dom';

import { EntryScreenHeader } from '~/components/EntryScreen/EntryScreenHeader';
import { PATHS } from '~/router';

import { TLocationState } from '../../../../types/location';
import { paramsSerializer } from '../../../../utils/apiRequest/helpers';

import { ApplicationTitle } from './components';
import { useGetActions } from './hooks/useGetActions';

export const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { requestParams } = (location.state as TLocationState) || {};

  const onCloseEntry = () => {
    const { sorting, limit, skip, page, ...rest } = requestParams || {};
    navigate(`${PATHS.USERS}?${paramsSerializer(rest)}`);
  };

  const actions = useGetActions();
  return (
    <EntryScreenHeader
      title={<ApplicationTitle />}
      onClose={onCloseEntry}
      actions={actions}
    />
  );
};
