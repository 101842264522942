import { styled } from 'react-ui-kit-exante';

export const StyledValueRowContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 100px;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;

export const StyledValueRowValue = styled('div')`
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
  word-break: break-all;

  // fix right align
  .MuiCheckbox-root {
    padding-right: 0;
  }

  .MuiAutocomplete-root .MuiChip-label {
    max-width: 170px;
  }
`;

export const StyledValueRowLabel = styled('div')`
  max-width: 50%;
`;

export const StyledRightOffset = styled('div')`
  width: 22px;
`;
