import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty, uniqBy } from 'lodash';
import {
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { TChildren } from 'types/TChildren';
import { useSaveApplicationMutation } from '~/api';
import { TTag } from '~/api/applications/applications.types';
import { TDefaultFormValues } from '~/types/form';
import { checkKeyDown } from '~/utils/checkKeyDown';
import { getDirtyValues } from '~/utils/forms/getDirtyValues';

import { ApplicationContext } from '../ApplicationContext';
import { TabsContext } from '../TabsContext';

import {
  TApplicationFormContext,
  TApplicationFormProps,
  TDefaultValue,
} from './ApplicationFormContext.types';
import {
  getDefaultFormValues,
  getFormValidationScheme,
  mapTagData,
  preparedApplicationFields,
  preparedClientsFormData,
} from './helpers';

const initialState: TApplicationFormContext = {
  onSave: () => {},
  formNotEdited: true,
  onAddField: () => {},
  onRemoveField: () => {},
  isErrors: false,
};

export const ApplicationFormContext =
  createContext<TApplicationFormContext>(initialState);

export const ApplicationFormProvider: FC<TApplicationFormProps & TChildren> = ({
  children,
  editableTags,
  allTags,
  additionalFormData,
}) => {
  const { application } = useContext(ApplicationContext);
  const { setCurrentTabIsDirty } = useContext(TabsContext);
  const [saveApplication, state] = useSaveApplicationMutation();
  const applicationId = application?.application.id;

  const [newTags, setNewTags] = useState<Record<string, TTag[]>>();
  const [tagsForDeleteIds, setTagsForDeleteIds] = useState<number[]>([]);

  const defaultValues = additionalFormData
    ? {
        ...getDefaultFormValues(editableTags),
        ...additionalFormData,
      }
    : getDefaultFormValues(editableTags);
  const validationScheme = getFormValidationScheme(defaultValues);

  const methods = useForm<TDefaultFormValues>({
    defaultValues,
    resolver: yupResolver(validationScheme),
  });

  const {
    handleSubmit,
    getValues,
    reset,
    formState: { dirtyFields, errors },
  } = methods;

  useEffect(() => {
    if (!isEmpty(additionalFormData)) {
      reset(defaultValues);
    }
  }, [additionalFormData]);

  const onAddField = (sectionName: string, tag?: TTag) => {
    if (tag) {
      setNewTags((prevState) => ({
        ...prevState,
        [sectionName]: [tag, ...(prevState?.[sectionName] || [])],
      }));
    }
  };

  const onRemoveField = (tagId: number) => {
    const isNewTag = Object.values(newTags || {})
      .flat()
      .find((t) => t.id === tagId);

    // removing new added tags
    if (isNewTag && newTags) {
      const newTagsCleared = Object.keys(newTags).reduce<
        Record<string, TTag[]>
      >((acc, curr) => {
        return {
          ...acc,
          [curr]: newTags[curr].filter((t) => t.id !== tagId),
        };
      }, {});
      setNewTags(newTagsCleared);
    }

    // removing existed tags
    if (!isNewTag) {
      const tag = editableTags?.find((t) => t.id === tagId);
      if (tag) {
        setTagsForDeleteIds((prevState) => [...prevState, tag.id]);
      }
    }
  };

  const onSave = () => {
    // we can't use formData in render, because form non rerender with new values (do not use watch!)
    const formData = getDirtyValues(dirtyFields, getValues());
    const clientsFormData =
      additionalFormData &&
      preparedClientsFormData(formData, additionalFormData);
    const applicationFields = preparedApplicationFields(formData);

    const tagsKeys = Object.keys(formData);
    const tagsForSave = uniqBy(
      allTags
        ?.flatMap(({ tags }) => tags)
        .filter(
          ({ name, id }) =>
            !tagsForDeleteIds.includes(id) && tagsKeys.includes(name),
        ),
      'id',
    );

    const tagData =
      tagsForSave?.map((tag) =>
        mapTagData({
          tag,
          value:
            formData[tag.name] === ''
              ? undefined
              : (formData[tag.name] as TDefaultValue),
        }),
      ) || [];

    const deletedTags = tagsForDeleteIds.reduce<{ id: number }[]>(
      (acc, curr) => {
        const tValueId = editableTags?.find((t) => t.id === curr)
          ?.tagvalue_set?.[0]?.id;

        if (tValueId) {
          return [...acc, { id: tValueId }];
        }
        return acc;
      },
      [],
    );

    if (applicationId) {
      saveApplication({
        applicationId,
        params: {
          tags: tagData,
          ...(deletedTags.length ? { tags_to_delete: deletedTags } : {}),
          ...(!isEmpty(clientsFormData) ? { clients: clientsFormData } : {}),
          ...(!isEmpty(applicationFields)
            ? { application_fields: applicationFields }
            : {}),
        },
      });
    }
  };
  const formNotEdited = !tagsForDeleteIds.length && isEmpty(dirtyFields);

  useEffect(() => {
    setCurrentTabIsDirty(!formNotEdited);
  }, [formNotEdited]);

  const value = useMemo(
    (): TApplicationFormContext => ({
      onSave,
      formNotEdited,
      allTags,
      onAddField,
      onRemoveField,
      newTags,
      tagsForDeleteIds,
      editableTags,
      isLoading: state.isLoading,
      dirtyFields,
      isErrors: !isEmpty(errors),
    }),
    [
      onSave,
      dirtyFields,
      onAddField,
      newTags,
      state.isLoading,
      tagsForDeleteIds.length,
      errors,
    ],
  );

  return (
    <ApplicationFormContext.Provider value={value}>
      <FormProvider {...methods}>
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
        <form
          onSubmit={handleSubmit(onSave)}
          onKeyDown={(e) => checkKeyDown(e)}
        >
          {children}
        </form>
      </FormProvider>
    </ApplicationFormContext.Provider>
  );
};
