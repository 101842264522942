import { ReactNode } from 'react';
import { ISelectOption } from 'react-ui-kit-exante';

import {
  TCreateDepositBody,
  TDepositInfo,
} from '~/api/deposits/deposits.types';
import { FieldTypes } from '~/constants/common';

export type TDepositFormProps = {
  deposit: TCreateDepositBody | TDepositInfo;
  onCloseEntry?: VoidFunction;
};

export type TFieldOptions = {
  options?: ISelectOption[];
  isLoading?: boolean;
};

export type TFieldConfig = {
  name: string;
  title: string;
  type?: FieldTypes;
  formatter?: (...args: any[]) => number | string | null | undefined;
  subKey?: string;
  optionsConfig?: TFieldOptions;
  editable?: boolean;
  withCopyButton?: boolean;
  node?: ReactNode;
  valueForCopy?: string | null;
  valueType?: string;
  required?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  readOnly?: boolean;
};

export type TGetFieldsParams = {
  countryA?: TFieldOptions;
  ccy?: TFieldOptions;
  deposit?: TCreateDepositBody | TDepositInfo;
  canChange?: boolean;
  hiddenFields?: string[];
};

export const isDepositInfo = (
  deposit: TCreateDepositBody | TDepositInfo,
): deposit is TDepositInfo => 'id' in deposit;
