import { ActionButtonWithConfirmationStaticProps } from '~/modules/actionButtonsBlock/ActionButton/components';
import { EApplicationActionsType } from '~/pages/ApplicationEntry/ApplicationEntry.constants';

import { ActionButtonWithConfirmationEnum } from '../Header.types';

export const staticButtonWithConfirmationPropsMap: Record<
  ActionButtonWithConfirmationEnum,
  ActionButtonWithConfirmationStaticProps
> = {
  rejectBtn: {
    placement: 'bottom',
    title: 'Reject application',
    cancelButtonNameKey: 'Cancel',
    confirmButtonNameKey: 'Reject',
    contentStaticProps: {
      multiline: true,
      label: 'Comment',
      className: 'CommentInput',
    },
    childrenStaticProps: {
      iconName: 'ClearDateIcon',
      label: 'Reject',
      iconColor: 'radical',
    },
  },
  approveBtn: {
    placement: 'bottom',
    cancelButtonNameKey: 'Cancel',
    confirmButtonNameKey: 'Approve',
    title: 'Please, confirm',
    childrenStaticProps: {
      iconName: 'CircleCheckIcon',
      iconColor: 'action',
      title: 'Approve',
      label: 'Approve',
    },
  },
  unapproveBtn: {
    placement: 'bottom',
    cancelButtonNameKey: 'Cancel',
    confirmButtonNameKey: 'Unapprove',
    title: 'Please, confirm',
    childrenStaticProps: {
      iconName: 'CircleCloseIcon',
      iconColor: 'radical',
      label: 'Unapprove',
    },
  },
  sendAppToPendingBtn: {
    placement: 'bottom',
    cancelButtonNameKey: 'Cancel',
    confirmButtonNameKey: 'Send to pending',
    title: 'Pending reason',
    contentStaticProps: {
      multiline: true,
      label: 'Comment',
      className: 'CommentInput',
    },
    childrenStaticProps: {
      iconName: 'ClockIcon',
      label: 'Send to pending',
      iconColor: 'warning',
    },
  },
  deleteBtn: {
    placement: 'bottom',
    cancelButtonNameKey: 'Cancel',
    confirmButtonNameKey: 'Delete',
    title: 'Do you want to delete the user?',
    childrenStaticProps: {
      iconName: 'DeleteIcon',
      iconColor: 'radical',
      label: 'Delete',
    },
  },
  unblockBtn: {
    placement: 'bottom',
    cancelButtonNameKey: 'Cancel',
    confirmButtonNameKey: 'Unblock',
    contentStaticProps:
      'Do you want to unblock and restore the permissions to the site and ATP and restore the demo access?',
    childrenStaticProps: {
      iconName: 'ReadyForActivationIcon',
      iconColor: 'action',
      label: 'Unblock',
    },
  },
  sendMessageBtn: {
    placement: 'bottom',
    cancelButtonNameKey: 'Cancel',
    confirmButtonNameKey: 'Send',
    title: 'Send message',
    contentStaticProps: {
      multiline: true,
      className: 'CommentInput',
      label: 'Comment',
    },
    childrenStaticProps: {
      iconName: 'SendMessageIcon',
      label: 'Send message',
      iconColor: 'ghost',
    },
  },
};

export const buttonWithConfirmationKeysToActionsTypeMap: Record<
  Exclude<ActionButtonWithConfirmationEnum, 'rejectBtn' | 'sendMessageBtn'>,
  EApplicationActionsType
> = {
  approveBtn: EApplicationActionsType.Approve,
  unapproveBtn: EApplicationActionsType.Unapprove,
  sendAppToPendingBtn: EApplicationActionsType.ToPending,
  deleteBtn: EApplicationActionsType.Delete,
  unblockBtn: EApplicationActionsType.Unblock,
};
