import { FC, useContext } from 'react';

import { useGetLinkedApplicationsQuery } from '~/api';
import { convertToTz } from '~/utils/dates/formatDate';

import { TabStructure } from '../../components/EnrtyScreenStructure/TabStructure/TabStructure';
import { ApplicationContext } from '../../contexts/ApplicationContext';

export const InfoTab: FC = () => {
  const { application } = useContext(ApplicationContext);

  const { data: linkedApplications, isLoading } = useGetLinkedApplicationsQuery(
    {
      applicationId: application?.application.id as number,
    },
  );

  const linkedApplicationsValue = linkedApplications?.linked_applications?.map(
    ({ full_name, email, id }) => ({
      label: `${full_name} ${email}`,
      value: String(id),
    }),
  );

  const nextCommDate = application?.next_communication_date;
  const nextCommunicationDateValues = {
    linked_applications: linkedApplicationsValue,
    next_communication_date: nextCommDate
      ? convertToTz(new Date(nextCommDate), application?.timezone_offset)
      : null,
  };

  // todo move to skeletons/loaders
  if (isLoading) {
    return null;
  }

  return (
    <TabStructure
      additionalFormData={nextCommunicationDateValues}
      withMainSave
      entityType="info-tab"
    />
  );
};
