import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  FilterInputProps,
  TAdditionalFilter,
  TControlledFilters,
  TExtendedColumnWithAdditionalFields,
  TRemoveFilterHandler,
} from '../../types';
import {
  FilterEqualityStyled,
  FilterLabelStyled,
  FilterRemoveStyled,
  FilterRowStyled,
} from '../styled';
import { FilterType, FilterValue } from '../types';

import { FilterInput } from './FilterInput';
import { TFilterValueState } from './types';

export interface IFilterRowProps<Data extends object> {
  dataTestId?: string;
  filter: TExtendedColumnWithAdditionalFields<Data> | TAdditionalFilter<Data>;
  filterInputProps?: FilterInputProps;
  filters?: TControlledFilters;
  hasNegativeFilters?: boolean;
  negative: Set<string>;
  onClose: (id: string) => void;
  requiredFilters?: string[];
  setFilter(id: string, value?: FilterValue, type?: FilterType): void;
  setNegative: (key: string, value: boolean) => void;
  useBrandedDatePicker?: boolean;
  showClearButton?: boolean;
}

export const FilterRow = <Data extends object>({
  dataTestId,
  filter,
  filterInputProps,
  filters = {},
  hasNegativeFilters = false,
  negative,
  onClose,
  requiredFilters = [],
  setFilter,
  setNegative,
  useBrandedDatePicker,
  showClearButton,
}: IFilterRowProps<Data>) => {
  const [stateValue, setValue] = useState<TFilterValueState>(
    filter.filterValue || filters[filter.id as string],
  );

  const [isFilterEqualityEqual, setIsFilterEqualityEqual] = useState<boolean>(
    hasNegativeFilters ? !negative.has(String(filter.id)) : true,
  );

  const isNoRequiredFilter = !requiredFilters.includes(filter.id as string);

  const handleCloseFilter = useCallback(() => {
    setValue(undefined);

    if (filter.id) {
      setFilter(filter.id, undefined);
      onClose(filter.id);
    }

    if (filter.onRemove && filter.id) {
      (filter.onRemove as TRemoveFilterHandler)(filter.id, null);
    }

    if (!isFilterEqualityEqual && hasNegativeFilters) {
      setIsFilterEqualityEqual(true);
      setNegative(String(filter.id), false);
    }
  }, [filter, onClose, setFilter]);

  useEffect(() => {
    if (filters[filter.id as string]) {
      setValue(filters[filter.id as string] as TFilterValueState);
    }
  }, [filters, filter]);

  const filterNegativeIsDisabled = useMemo(
    () => filter?.negative === false,
    [filter],
  );

  const handleFilterEqualityClick = () => {
    if (hasNegativeFilters && !filterNegativeIsDisabled) {
      setIsFilterEqualityEqual(!isFilterEqualityEqual);
      setNegative(String(filter.id), isFilterEqualityEqual);
    }
  };

  return (
    <FilterRowStyled className="FilterRow" data-test-id={dataTestId}>
      <FilterLabelStyled className="FilterLabel">
        <>
          {filter.Header}
          <FilterEqualityStyled
            data-test-id="table__filter_equality_button"
            hasNegativeFilters={hasNegativeFilters && !filterNegativeIsDisabled}
            onClick={handleFilterEqualityClick}
            title="Set filter equality"
          >
            {isFilterEqualityEqual ? '=' : '≠'}
          </FilterEqualityStyled>
        </>
      </FilterLabelStyled>

      <FilterInput
        showClearButton={showClearButton}
        filter={filter}
        stateValue={stateValue}
        setValue={setValue}
        setFilter={setFilter}
        filterInputProps={filterInputProps}
        useBrandedDatePicker={useBrandedDatePicker}
      />
      {isNoRequiredFilter && (
        <FilterRemoveStyled
          onClick={handleCloseFilter}
          data-test-id="filters__button--remove-filter"
          aria-label="Remove filter"
          iconSize={16}
          iconColor="secondary"
          iconName="CloseIcon"
          type="button"
        />
      )}
    </FilterRowStyled>
  );
};
