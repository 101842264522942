import { ActionButtonWithConfirmationWithSelectStaticProps } from '~/modules/actionButtonsBlock/ActionButton/components';

import { ActionButtonWithConfirmationWithSelectEnum } from '../WithdrawalHeader.types';

import { WithdrawalTypesOfActions } from './WithdrawalHeader.constants';

export const staticButtonWithConfirmationWithSelectPropsMap: Record<
  ActionButtonWithConfirmationWithSelectEnum,
  ActionButtonWithConfirmationWithSelectStaticProps
> = {
  holdBtn: {
    title: 'Pick a reason',
    confirmText: 'Hold',
    selectStaticProps: {
      placeholder: 'Pick a reason',
      label: 'Pick a reason',
      sx: { width: 300 },
    },
    childrenStaticProps: {
      iconName: 'SuspendIcon',
      iconColor: 'warning',
      label: 'On Hold',
    },
  },
  rejectBtn: {
    title: 'Pick a reason',
    confirmText: 'Reject',
    selectStaticProps: {
      placeholder: 'Pick a reason',
      label: 'Pick a reason',
      sx: { width: 300 },
    },
    childrenStaticProps: {
      iconName: 'CircleCloseIcon',
      label: 'Reject',
      iconColor: 'radical',
    },
  },
};

export const typeHandlerMap = {
  [ActionButtonWithConfirmationWithSelectEnum.RejectBtn]:
    WithdrawalTypesOfActions.Reject,
  [ActionButtonWithConfirmationWithSelectEnum.HoldBtn]:
    WithdrawalTypesOfActions.Hold,
};
