import { useCallback, useContext, useEffect } from 'react';

import {
  useApplicationActionsMutation,
  useRejectApplicationMutation,
  useSaveApplicationMutation,
  useSendMessageToClientMutation,
} from '~/api';
import { TTagForSave } from '~/api/applications/applications.types';
import { ActionWithConfirmationProps } from '~/modules/actionButtonsBlock/ActionButton';
import { ApplicationContext } from '~/pages/ApplicationEntry/contexts/ApplicationContext';

import { ActionButtonWithConfirmationEnum } from '../Header.types';
import {
  applicationSuccessMessage,
  buttonWithConfirmationKeysToActionsTypeMap,
  staticButtonWithConfirmationPropsMap,
} from '../constants';

import { useGetApplicationTagsData } from './useGetApplicationTagsData';

export const useGetButtonWithConfirmationProps = () => {
  const {
    application,
    isLoadingApplication,
    isDisableActionButtons,
    setIsDisableActionButtons,
  } = useContext(ApplicationContext);
  const applicationId = application?.application?.id;
  const currentLocale = application?.application?.current_locale;

  const [sendMessage] = useSendMessageToClientMutation();
  const [saveApplication] = useSaveApplicationMutation();
  const [rejectApplication, { isLoading: isRejectApplicationLoading }] =
    useRejectApplicationMutation();
  const [applicationActions, { isLoading: isApplicationActionsLoading }] =
    useApplicationActionsMutation();

  const {
    defaultPendingReason,
    defaultPendingReasonTagId,
    defaultPendingReasonId,
  } = useGetApplicationTagsData();

  useEffect(() => {
    setIsDisableActionButtons(
      isApplicationActionsLoading || isRejectApplicationLoading,
    );
  }, [
    isApplicationActionsLoading,
    isRejectApplicationLoading,
    setIsDisableActionButtons,
  ]);

  const handleSendApplicationActions = useCallback(
    (
        key: Exclude<
          ActionButtonWithConfirmationEnum,
          'rejectBtn' | 'sendMessageBtn'
        >,
      ) =>
      () => {
        if (applicationId) {
          const action = buttonWithConfirmationKeysToActionsTypeMap[key];

          applicationActions({
            applicationId,
            action,
            successMessage: applicationSuccessMessage[action],
          });
        }
      },
    [applicationActions, applicationId],
  );

  const handleConfirmRejectApplication = useCallback(
    (review: string) => {
      if (applicationId) {
        rejectApplication({
          applicationId,
          review_text: review,
        });
      }
    },
    [applicationId, rejectApplication],
  );

  const handleSendMessage = useCallback(
    (message: string) => {
      if (applicationId) {
        sendMessage({
          applicationId,
          params: {
            locale: currentLocale ?? 'en',
            message_text: message,
          },
        });
      }
    },
    [applicationId, currentLocale, sendMessage],
  );

  const saveApplicationHandler = useCallback(
    (tags: TTagForSave[]) => {
      if (applicationId) {
        saveApplication({
          applicationId,
          params: {
            tags,
          },
        });
      }
    },
    [applicationId, saveApplication],
  );

  const getOnConfirmHandler = useCallback(
    (buttonKey: ActionButtonWithConfirmationEnum) => (textValue: string) => {
      if (buttonKey === 'sendMessageBtn') {
        handleSendMessage(textValue);

        return;
      }

      if (buttonKey === 'rejectBtn') {
        handleConfirmRejectApplication(textValue);

        return;
      }

      if (
        buttonKey === 'sendAppToPendingBtn' &&
        defaultPendingReasonTagId &&
        defaultPendingReasonId &&
        textValue
      ) {
        const tags = [
          {
            id: defaultPendingReasonId,
            tag_id: defaultPendingReasonTagId,
            text_value: textValue,
          },
        ];
        saveApplicationHandler(tags);
      }

      handleSendApplicationActions(buttonKey)();
    },
    [
      defaultPendingReasonId,
      defaultPendingReasonTagId,
      handleConfirmRejectApplication,
      handleSendApplicationActions,
      handleSendMessage,
      saveApplicationHandler,
    ],
  );

  const getButtonWithConfirmationProps = (
    buttonKey: ActionButtonWithConfirmationEnum,
  ): ActionWithConfirmationProps['props'] => {
    const staticProps = staticButtonWithConfirmationPropsMap[buttonKey];

    const defaultTextValue =
      buttonKey === 'rejectBtn' && typeof defaultPendingReason === 'string'
        ? defaultPendingReason
        : undefined;

    return {
      isLoadingActions: isLoadingApplication || isDisableActionButtons,
      onConfirm: getOnConfirmHandler(buttonKey),
      defaultTextValue,
      ...staticProps,
    };
  };

  return {
    getButtonWithConfirmationProps,
  };
};
