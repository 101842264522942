import { createApi } from '@reduxjs/toolkit/query/react';
import { Method } from 'axios';
import { Notification } from 'react-ui-kit-exante';

import { ALL_LEGAL_ENTITY } from '~/constants/common';
import { baseQueryHandler } from '~/utils/apiRequest';

import {
  TGetTagsParams,
  TTagsResponse,
} from '../applications/applications.types';

import { TCreatePersonParams, TPersons } from './person.types';

export const personsApi = createApi({
  reducerPath: 'personsApi',
  baseQuery: baseQueryHandler,
  tagTypes: ['Persons', 'Person', 'PersonTags'],
  endpoints: (builder) => ({
    getPersons: builder.query<TPersons[], { applicationId: number | string }>({
      query: ({ applicationId }) => ({
        url: `/rest/docs/person/?application=${applicationId}`,
      }),
      providesTags: ['Persons'],
    }),

    getPerson: builder.query<TPersons, { personId?: string }>({
      query: ({ personId }) => ({
        url: `/rest/docs/person/${personId}/`,
      }),
      providesTags: ['Person'],
    }),

    getPersonTags: builder.query<TTagsResponse[], TGetTagsParams>({
      query: ({ objectId, sectionName, legalEntity }) => ({
        url: `/rest/tags/tag-group/person_related_tagvalues/`,
        params: {
          object_id: objectId,
          name__in: sectionName,
          le: legalEntity || ALL_LEGAL_ENTITY,
        },
      }),
      providesTags: ['PersonTags'],
    }),

    createPerson: builder.mutation<void, { data: TCreatePersonParams }>({
      query: ({ data }) => ({
        url: '/rest/docs/person/',
        method: 'POST',
        data,
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
          Notification.success({
            title: 'Person successfully created',
          });
        } catch (e) {
          console.error(e);
        }
      },
    }),

    editPerson: builder.mutation<
      void,
      { data: TCreatePersonParams; personId?: string }
    >({
      query: ({ data, personId }) => ({
        url: `/rest/docs/person/${personId}/`,
        method: 'PUT',
        data,
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
          Notification.success({
            title: 'Person successfully edited',
          });
        } catch (e) {
          console.error(e);
        }
      },
    }),

    deletePerson: builder.mutation<void, { personId: number }>({
      query: ({ personId }) => ({
        url: `/rest/docs/person/${personId}/`,
        method: 'DELETE',
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
          Notification.success({
            title: 'Person successfully deleted',
          });
        } catch (e) {
          console.error(e);
        }
      },
      invalidatesTags: ['Persons'],
    }),

    setMainUbo: builder.mutation<void, { data: TPersons[]; method: Method }>({
      query: ({ data, method }) => ({
        url: `/rest/docs/person/${data[0].id}/set_main_ubo/`,
        method,
        data,
      }),
      async onQueryStarted({ method }, { queryFulfilled }) {
        try {
          await queryFulfilled;
          Notification.success({
            title: `Person successfully ${
              method === 'POST' ? 'set' : 'unset'
            } main ubo`,
          });
        } catch (e) {
          console.error(e);
        }
      },
      invalidatesTags: ['Persons'],
    }),

    requestStatusPersonAction: builder.mutation<
      void,
      {
        personId: string;
        type: string;
      }
    >({
      query: ({ personId, type }) => ({
        url: `/rest/docs/person/${personId}/${type}/`,
        method: 'POST',
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
          Notification.success({
            title: 'Request successfully sent',
          });
        } catch (e) {
          console.error(e);
        }
      },
    }),
  }),
});

export const {
  useLazyGetPersonsQuery,
  useCreatePersonMutation,
  useDeletePersonMutation,
  useSetMainUboMutation,
  useGetPersonQuery,
  useGetPersonTagsQuery,
  useEditPersonMutation,
  useRequestStatusPersonActionMutation,
} = personsApi;
