import { EApplicationActionsType } from '../../../ApplicationEntry.constants';

export const applicationSuccessMessage = {
  [EApplicationActionsType.Delete]: 'Application deleted',
  [EApplicationActionsType.FromPending]: 'Application sent from pending',
  [EApplicationActionsType.FromPendingToRegistrant]:
    'Application sent to registrant',
  [EApplicationActionsType.ToPending]: 'Application sent to pending',
  [EApplicationActionsType.ToClientSupport]:
    'Application sent to Sales support pre-check.',
  [EApplicationActionsType.ToOnboarding]: 'Application sent to onboarding',
  [EApplicationActionsType.Unapprove]: 'Application unapproved',
  [EApplicationActionsType.Approve]: 'Application approved',
  [EApplicationActionsType.Block]: 'Application blocked',
  [EApplicationActionsType.Unblock]: 'Application unblocked',
  [EApplicationActionsType.FromPendingToQualified]:
    'Application sent to qualified',
};
