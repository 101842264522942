import { useParams } from 'react-router-dom';

import { TParams } from '~/router/router.types';

import { DepositCreateEntry, DepositInfoEntry } from './components';

export const DepositEntry = () => {
  const { id: depositId, ids } = useParams<TParams>();

  if (depositId) {
    return <DepositInfoEntry depositId={depositId} />;
  }

  if (ids) {
    return <DepositCreateEntry ids={ids} />;
  }

  return null;
};
