import { FC, useContext } from 'react';

import { ApplicationContext } from '~/pages/ApplicationEntry/contexts/ApplicationContext';

import { TabStructure } from '../../../components/EnrtyScreenStructure/TabStructure/TabStructure';

import { getDefaultClientsValues } from './OnboardingSettingsTab.helpers';

export const OnboardingSettingsTab: FC = () => {
  const { application } = useContext(ApplicationContext);

  const applicationData = application?.application;

  const defaultNamesValues = {
    first_name: applicationData?.first_name,
    last_name: applicationData?.last_name,
    ...(applicationData?.is_corporate
      ? { company_name: applicationData?.company_name }
      : {}),
  };

  const defaultClientsValues = getDefaultClientsValues(
    applicationData?.clients || [],
  );

  return (
    <TabStructure
      entityType="onboarding-settings-tab"
      withMainSave
      additionalFormData={{ ...defaultClientsValues, ...defaultNamesValues }}
    />
  );
};
