import { styled } from 'react-ui-kit-exante';

import { SectionBody } from '~/pages/ApplicationEntry/components/EnrtyScreenStructure/SectionBody';

export const StyledCreatePersonForm = styled('div')`
  margin-bottom: 24px;
`;

export const StyledCreatePersonActions = styled('div')`
  display: flex;
  gap: 24px;
`;

export const StyledSectionBody = styled(SectionBody)`
  gap: 24px;
  width: 30%;
`;

export const StyledCreatePersonTitle = styled('div')`
  width: 30%;
  display: flex;
  justify-content: space-between;
`;
