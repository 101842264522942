import { Autocomplete, Select, Input, styled } from 'react-ui-kit-exante';

export const StyledApplicationClosedForm = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 18px;
`;

export const StyledAutocomplete = styled(Autocomplete)`
  width: 240px;
`;

export const StyledSelect = styled(Select)`
  width: 240px;
`;

export const StyledCommentInput = styled(Input)`
  .MuiInputBase-multiline {
    textarea {
      padding: 0;
    }
  }
`;
