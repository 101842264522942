import { range } from 'lodash';
import { blockNonNativeProps, styled } from 'react-ui-kit-exante';

import { EntryColumn, EntrySection } from '~/components/EntryScreen';

export const StyledFormField = styled('div')`
  .MuiFormControlLabel-root {
    display: flex;
    justify-content: right;
  }
`;

export const StyleDepositDetailsFields = styled(EntrySection)`
  border-top: none;
  padding: 0 0 24px 0;

  .EntrySectionContent {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 24px;

    div:nth-child(10) {
      grid-column: 1 / -1;
    }
  }
`;

export const StyleClientDetailsFields = styled(EntrySection, {
  shouldForwardProp: blockNonNativeProps(['length']),
})<{ length: number }>`
  padding: 24px 0;

  .EntrySectionContent {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-areas:
      'item1 item2 . .'
      'item3 item4 item5 item6'
      'item7 item8 . .';
    gap: 24px;

    ${({ length }) =>
      range(1, length).map(
        (count) =>
          `div:nth-child(${count + 1}) { grid-area: item${count + 1}; }`,
      )}
  }
`;

export const StyleDocumentsField = styled(EntrySection)`
  padding: 24px 0;

  .EntrySectionContent {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
  }
`;

export const StyleCommonDetailsFields = styled(EntrySection)`
  padding: 24px 0;

  .EntrySectionContent {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 24px;

    div:nth-child(10) {
      grid-column: 1 / -1;
    }
  }
`;

export const StyleFormWrapper = styled(EntryColumn)`
  padding: 0 24px;
`;
