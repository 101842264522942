import { useMemo } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { StyledInputDatePicker } from './FormDatePickerContainer.styled';
import {
  DateForChangeType,
  TFormDatePickerContainerProps,
} from './FormDatePickerContainer.types';

export const FormDatePickerContainer = ({
  disabled = false,
  fullWidth = false,
  hideErrors = false,
  label = '',
  name,
  size = 'medium',
  showSkeleton,
  ...rest
}: TFormDatePickerContainerProps) => {
  const formContext = useFormContext();

  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    control: formContext.control,
    name,
  });

  const onChangeHandler = (date: DateForChangeType) => {
    onChange(date ?? '');
  };

  const controlProps = useMemo(() => {
    return (
      !hideErrors && {
        error: !!error,
        message: error?.message,
      }
    );
  }, [error, hideErrors]);

  return (
    <StyledInputDatePicker
      disabled={disabled}
      inputProps={{
        size,
        label,
        fullWidth,
        disabled,
        showSkeleton,
        ...controlProps,
        ...rest,
      }}
      showSkeleton={showSkeleton}
      name={name}
      onChange={onChangeHandler}
      selected={value || null}
      {...rest}
      {...controlProps}
    />
  );
};
