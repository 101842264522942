import { useParams } from 'react-router-dom';
import { NoData } from 'react-ui-kit-exante';

import { TParams } from '~/router/router.types';

import { TabLoader } from '../../../../components/TabLoader';

import { PermissionsFormContainer } from './components/PermissionsFormContainer';
import { useAccountPermissionsData } from './useAccountPermissionsData';

export const AccountPermissionsContainer = () => {
  const { id } = useParams<TParams>();

  const userName = String(id);

  const {
    queries: { accountPermissionsQuery },
    isDataLoading,
  } = useAccountPermissionsData(userName);

  if (isDataLoading) {
    return <TabLoader />;
  }

  if (!accountPermissionsQuery.data) {
    return <NoData />;
  }

  return (
    <PermissionsFormContainer
      userName={userName}
      accountPermissions={accountPermissionsQuery.data}
    />
  );
};
