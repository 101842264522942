import { ChangeEvent, FC, useCallback, useEffect, useMemo } from 'react';
import {
  IOnFetchArguments,
  useTableData,
  Table,
  Checkbox,
  calculateCountOfPages,
} from 'react-ui-kit-exante';

import {
  useGetPersonalSettingsQuery,
  useUpdatePersonalSettingsMutation,
} from '~/api';
import { useLazyGetCommentsQuery } from '~/api/comments/commentsApi';
import { useGetGlobalContextQuery } from '~/api/globalContext';
import { useCallbackTriggerHandle, useLogHandleTime } from '~/hooks';
import { getDefaultPagination, getPaginationParams } from '~/utils/table';

import { AddCommentForm } from './AddCommentForm';
import {
  mapContentType,
  PAGE_SIZE,
  PAGE_SIZES,
  TABLE_ID,
} from './Comments.constants';
import { StyledRowContainer } from './Comments.styled';
import { TCommentsProps } from './Comments.types';
import { getColumns } from './columns';

export const Comments: FC<TCommentsProps> = ({
  entityId,
  entityType,
  needRefetch,
}) => {
  const { setStartHandleTime, logHandleTime } = useLogHandleTime(
    `withdrawal-entry-comments-${TABLE_ID}-${entityType}`,
  );

  const [updatePersonalSettings, personalSettingsState] =
    useUpdatePersonalSettingsMutation();

  const { data: personalSettings } = useGetPersonalSettingsQuery();
  const { data: globalContext } = useGetGlobalContextQuery();
  const [
    fetchComments,
    { isLoading: isGetCommentsLoading, isSuccess: isGetCommentsSuccess },
  ] = useLazyGetCommentsQuery();

  useEffect(() => {
    if (isGetCommentsLoading) {
      setStartHandleTime();
    }
  }, [isGetCommentsLoading, setStartHandleTime]);

  const isHideSystem = Boolean(
    Number(personalSettings?.personal_settings.hyde_system_comments),
  );

  const contentType =
    globalContext?.content_type?.[mapContentType[entityType]] ?? 0;

  const getComments = useCallback(
    async (params: IOnFetchArguments) => {
      const paginationParams = getPaginationParams(params, true);
      const response = await fetchComments({
        entityId,
        perPage: paginationParams.iDisplayLength,
        page: paginationParams.iDisplayPage
          ? paginationParams.iDisplayPage + 1
          : 1,
        contentType,
        isSystem: !isHideSystem,
      });
      return response.data;
    },
    [isHideSystem],
  );

  const tableDataArgs = useMemo(
    () => ({
      tableId: TABLE_ID,
      data: { onFetch: getComments },
      pagination: {
        getDefaultPagination,
      },
    }),
    [getComments],
  );

  const { data, limit, setLimit, setPage, page, skip, isLoading, fetchData } =
    useTableData(tableDataArgs);

  const total = data?.[0].total_entries ?? 0;

  const serverPaginationProps = useMemo(
    () => ({
      pageSize: limit,
      skip,
      setPage,
      setPageSize: setLimit,
      pageIndex: page,
      total,
      pageCount: calculateCountOfPages(total, limit),
    }),
    [skip, limit, page, setLimit, setPage, total],
  );

  const onIsSystemCheckboxChange = (
    _: ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    setPage(0);

    updatePersonalSettings({
      id: 0,
      personal_settings: {
        hyde_system_comments: String(Number(checked)),
      },
    });
  };

  const columns = useMemo(() => {
    return getColumns();
  }, []);

  useEffect(() => {
    if (needRefetch) {
      fetchData();
    }
  }, [needRefetch]);

  useCallbackTriggerHandle({
    cb: logHandleTime,
    dataTrigger: data?.[1],
    processTrigger: !isGetCommentsLoading && isGetCommentsSuccess,
  });

  return (
    <div>
      <AddCommentForm
        entityType={entityType}
        entityId={entityId}
        contentType={contentType}
        onRefetchTable={fetchData}
      />

      <StyledRowContainer className="RowContainer">
        <Checkbox
          label="Hide system comments"
          checked={isHideSystem}
          onChange={onIsSystemCheckboxChange}
          disabled={personalSettingsState.isLoading}
        />
      </StyledRowContainer>

      <Table
        tableId={TABLE_ID}
        data={data?.[1] || []}
        columns={columns}
        manualSortBy
        isLoading={isLoading && !needRefetch}
        serverPaginationProps={serverPaginationProps}
        skeletonsCount={PAGE_SIZE}
        pageSizes={PAGE_SIZES}
        pageSize={PAGE_SIZE}
        isFlexLayout
        disableSortBy
        isHiddenColumnSelect
        showTableInfo={false}
        noHeader
        hasPagination
      />
    </div>
  );
};
