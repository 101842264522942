import { yupResolver } from '@hookform/resolvers/yup';
import {
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Loader, Notification } from 'react-ui-kit-exante';

import {
  useLazyGetUserQuery,
  useAddUserTokenMutation,
  useEditUserTokenMutation,
  useDeleteUserTokenMutation,
  useLazyGetAuthFlowQuery,
  useSetAuthFlowMutation,
} from '~/api';
import { TTokens } from '~/api/nodeBackApi/generalUsers/generalUsers.types';
import {
  DEFAULT_PASSWORD_VALUES,
  DEFAULT_SMS_AND_EMAIL_VALUES,
  DEFAULT_TOTP_VALUES,
  PasswordManagementType,
} from '~/pages/ApplicationEntry/tabs/UserSettingsTab/components/PasswordManagement/PasswordManagement.constants';
import {
  getValidationPasswordScheme,
  getValidationSmsOrEmailScheme,
  getValidationTotpScheme,
} from '~/pages/ApplicationEntry/tabs/UserSettingsTab/components/PasswordManagement/components/Forms/vaildationSchemes';
import { TChildren } from '~/types/TChildren';

import { ApplicationContext } from '../../../../../contexts/ApplicationContext';

import { TPasswordManagementContext } from './PasswordManagementContext.types';

const initialState: TPasswordManagementContext = {
  tokens: null,
  editData: null,
  setEditData: () => {},
  formType: '',
  onAdd: () => {},
  onEdit: () => {},
  onDelete: () => {},
  isOpenAddForm: false,
  openAddFormAnchorEl: null,
  handleAddFormOpen: () => {},
  handleAddFormClose: () => {},
  isDisabledBtn: true,
  authFlow: {},
  onEditAuthFlow: () => {},
};

export const PasswordManagementContext =
  createContext<TPasswordManagementContext>(initialState);

export const PasswordManagementProvider: FC<TChildren> = ({ children }) => {
  const [formType, setFormType] = useState(PasswordManagementType.Password);
  const [editData, setEditData] = useState<TTokens | null>(null);

  const [isOpenAddForm, setIsOpenAddForm] = useState(false);

  const [openAddFormAnchorEl, setOpenAddFormAnchorEl] =
    useState<null | HTMLElement>(null);

  const { application } = useContext(ApplicationContext);

  const [fetchUser, userState] = useLazyGetUserQuery();
  const [fetchAuthFlow, authFlowState] = useLazyGetAuthFlowQuery();
  const [addUserToken, addUserState] = useAddUserTokenMutation();
  const [editUserToken, editUserState] = useEditUserTokenMutation();
  const [deleteUserToken, deleteUserState] = useDeleteUserTokenMutation();
  const [setAuthFlowToken] = useSetAuthFlowMutation();

  const {
    data,
    isLoading: isUserLoading,
    isFetching: isUserFetching,
  } = userState;
  const { data: authFlow, isLoading: isFlowStateLoading } = authFlowState;

  const tokens = data?.tokens || null;
  const isDisabledBtn =
    isUserLoading ||
    isUserFetching ||
    addUserState.isLoading ||
    editUserState.isLoading ||
    deleteUserState.isLoading;
  const userId: number = application?.user?.authdb_id;

  useEffect(() => {
    if (userId) {
      fetchUser({ userId });
      fetchAuthFlow({ userId });
    }
  }, [userId]);

  const setDefaultValues = () => {
    if (editData) {
      return { ...DEFAULT_PASSWORD_VALUES, name: editData.name };
    }
    if (formType === PasswordManagementType.Totp) {
      return DEFAULT_TOTP_VALUES;
    }
    if (
      formType === PasswordManagementType.SMS ||
      formType === PasswordManagementType.Email
    ) {
      return DEFAULT_SMS_AND_EMAIL_VALUES;
    }

    return DEFAULT_PASSWORD_VALUES;
  };

  const setResolver = () => {
    if (formType === PasswordManagementType.Totp) {
      return getValidationTotpScheme({ tokens });
    }
    if (
      formType === PasswordManagementType.SMS ||
      formType === PasswordManagementType.Email
    ) {
      return getValidationSmsOrEmailScheme({ tokens });
    }

    return getValidationPasswordScheme({ tokens, isEdit: !!editData });
  };

  const resolver = setResolver();

  setDefaultValues();

  const methods = useForm({
    defaultValues: DEFAULT_PASSWORD_VALUES,
    resolver: yupResolver(resolver),
  });

  const { getValues, watch, reset, handleSubmit } = methods;

  watch();

  const handleAddFormOpen = (event?: React.MouseEvent<HTMLElement>) => {
    setIsOpenAddForm(true);

    if (event) {
      setOpenAddFormAnchorEl(event.currentTarget);
    }
  };

  const handleAddFormClose = () => {
    setIsOpenAddForm(false);
    setOpenAddFormAnchorEl(null);
  };

  const onAdd = async () => {
    let response = {};
    const body = getValues();

    if (formType === PasswordManagementType.Password) {
      delete body.revalue;

      response = await addUserToken({ userId, data: body });
    }

    if (formType !== PasswordManagementType.Password) {
      response = await addUserToken({ userId, data: body });
    }

    if ('data' in response) {
      reset(DEFAULT_PASSWORD_VALUES);
      setFormType(PasswordManagementType.Password);
      handleAddFormClose();

      Notification.success({
        title: 'User token successfully added',
      });
    }
  };

  const onEdit = async () => {
    const body = getValues();

    delete body.revalue;

    const response = await editUserToken({
      userId,
      tokenId: editData?.id,
      data: {
        ...body,
        lastChangeTime: editData?.lastChangeTime,
      },
    });

    if ('data' in response) {
      Notification.success({
        title: 'User token successfully saved',
      });
    }
  };

  const onDelete = async (tokenId: number) => {
    const response = await deleteUserToken({
      userId,
      tokenId,
    });

    if ('data' in response) {
      Notification.success({
        title: 'User token successfully deleted',
      });
    }
  };

  const onEditAuthFlow = async (
    tokenId: number,
    isPasswordTokens?: boolean,
  ) => {
    let body = {};

    const secondSteps = authFlow?.secondSteps || [];
    const isActivated = secondSteps.includes(tokenId);

    if (isPasswordTokens) {
      body = { ...authFlow, firstStep: tokenId };
    } else if (isActivated) {
      body = {
        ...authFlow,
        secondSteps: secondSteps.filter((item: number) => item !== tokenId),
      };
    } else if (authFlow) {
      body = {
        ...authFlow,
        secondSteps: [...secondSteps, ...[tokenId]],
      };
    }

    const response = await setAuthFlowToken({
      userId,
      data: body,
    });

    if ('data' in response) {
      Notification.success({
        title: `User token successfully ${
          isActivated ? 'deactivated' : 'activated'
        }`,
      });
    }
  };

  useEffect(() => {
    setFormType(getValues().type);
    const defaultValues = setDefaultValues();

    reset({ ...defaultValues, type: getValues().type });
  }, [getValues().type, editData]);

  const value = useMemo(
    (): TPasswordManagementContext => ({
      tokens,
      formType,
      editData,
      setEditData,
      onAdd: handleSubmit(onAdd),
      onEdit: handleSubmit(onEdit),
      onDelete,
      isOpenAddForm,
      openAddFormAnchorEl,
      handleAddFormOpen,
      handleAddFormClose,
      isDisabledBtn,
      authFlow,
      onEditAuthFlow,
    }),
    [
      tokens,
      formType,
      editData,
      setEditData,
      isOpenAddForm,
      openAddFormAnchorEl,
      handleAddFormOpen,
      handleAddFormClose,
      isDisabledBtn,
    ],
  );

  if (isUserLoading || isFlowStateLoading) {
    return <Loader isInner isCentered size="l" />;
  }

  return (
    <PasswordManagementContext.Provider value={value}>
      <FormProvider {...methods}>
        <form>{children}</form>
      </FormProvider>
    </PasswordManagementContext.Provider>
  );
};
