import {
  isArray,
  isBoolean,
  isDate,
  isEmpty,
  pickBy,
  startsWith,
} from 'lodash';
import { object, string } from 'yup';

import { TTag, TTagForSave } from '~/api/applications/applications.types';
import { formatDate } from '~/utils/dates/formatDate';

import { HARDCODED_NAME_FIELDS } from './ApplicationFormContext.constants';
import { TDefaultValue, TValues } from './ApplicationFormContext.types';
import { errorMsg } from './constants';

export const getDefaultFormValues = (tags?: TTag[]) => {
  if (!tags) {
    return {};
  }

  return tags.reduce((acc, curr): TValues => {
    let defaultValue: TDefaultValue;

    const tValueSet = curr?.tagvalue_set;

    if (curr.type === 'flag') {
      defaultValue = tValueSet?.[0]?.bool_value || curr?.default_bool_value;
    } else if (curr.type === 'date') {
      defaultValue = tValueSet?.[0]?.date_value
        ? new Date(tValueSet[0].date_value)
        : '';
    } else {
      const value = tValueSet?.[0]?.t_value;
      if (!value) {
        defaultValue = null;
      }

      if (typeof value === 'string') {
        if (curr.is_multiple && value === '') {
          // multiple autocomplete can not be empty string
          defaultValue = [];
        } else {
          defaultValue = value;
        }
      } else if (isArray(value)) {
        // multiple autocomplete need array value
        if (curr.is_multiple) {
          defaultValue =
            value.length === 1 ? [value[0]?.id] : value.map((i) => i.id);
        } else {
          defaultValue =
            value.length === 1 ? value[0]?.id : value.map((i) => i.id);
        }
      } else {
        defaultValue = value?.id;
      }
    }

    const key = curr.name;

    return {
      [key]: defaultValue,
      ...acc,
    };
  }, {});
};

// here we can set any validation scheme for all fields
// example from old CRM
// https://gitlab.exan.tech/Exante-websites/exante-crm-frontend/-/blob/release/1.41/src/modules/tags/components/tags-group/tag/tags/reasons-for-leaving-comment.js?ref_type=heads
export const getFormValidationScheme = (defaultValues: TValues) => {
  const fields: Record<string, any> = {};
  const keys = Object.keys(defaultValues);
  if (keys.includes('reasons for leaving')) {
    fields['reasons for leaving comment'] = string().test(
      'reasons for leaving comment',
      errorMsg['reasons for leaving comment'],
      (value, context) => {
        return context.parent['reasons for leaving']?.length ? !!value : true;
      },
    );
  }

  if (keys.includes('approved but not funded reason')) {
    fields['approved but not funded details'] = string().test(
      'approved but not funded details',
      errorMsg['approved but not funded details'],
      (value, context) => {
        return context.parent['approved but not funded reason']?.length
          ? !!value
          : true;
      },
    );
  }

  if (keys.includes('city')) {
    fields.city = string().required(errorMsg.city);
  }

  if (keys.includes('cvi assigned')) {
    fields['reason assigned cvi changed'] = string().test(
      'reason assigned cvi changed',
      errorMsg['reason assigned cvi changed'],
      (value, context) => {
        return context.parent['cvi assigned']?.length ? !!value : true;
      },
    );
  }

  return object({ ...fields });
};

type TMapTagData = {
  tag: TTag;
  value: TDefaultValue;
  tagNameProp?: string;
};

export const mapTagData = ({
  tag,
  value,
  tagNameProp = 'tag_id',
}: TMapTagData): TTagForSave => {
  const id = tag?.tagvalue_set?.[0]?.id || null;
  const tagId = tag.id;
  const data = {
    id,
    [tagNameProp]: tagId,
  };

  if (tag.type === 'flag') {
    return {
      ...data,
      bool_value: isBoolean(value) ? value : undefined,
    };
  }

  if (tag.type === 'date') {
    return {
      ...data,
      date_value: value ? formatDate(String(value)) : null,
    };
  }

  if (tag.type === 'manager') {
    return {
      ...data,
      manager_id: value ? Number(value) : null,
    };
  }

  if (tag.name === 'percent ownership') {
    return {
      ...data,
      object_id: null,
      text_value: value ? String(value) : null,
    };
  }

  return {
    id,
    [tagNameProp]: tag.id,
    text_value: value ? String(value) : null,
  };
};

export const preparedApplicationFields = (
  formData: Record<string, unknown>,
) => {
  let params = {};
  let names = {};

  HARDCODED_NAME_FIELDS.forEach((item) => {
    const value = formData[item];

    if (value) {
      names = { ...names, [item]: value };
    }
  });

  if (!isEmpty(names)) {
    params = { ...params, names };
  }

  return params;
};

export const preparedClientsFormData = (
  allFormData: Record<string, unknown>,
  clientFormData: Record<string, string | number | boolean | Date | null>,
) => {
  const dirtyClientsFormData = () =>
    allFormData &&
    pickBy(allFormData, (value, key) => startsWith(key, 'clientId'));

  const clientsFormData = Object.entries(dirtyClientsFormData())?.map(
    ([prop, value]) => {
      const splitPropName = prop.split(':');
      const [clientId, name] = splitPropName;

      return {
        id: clientId.split('=')[1],
        name,
        value: isDate(value) ? formatDate(value) : value,
      };
    },
  );

  const preparedClientsFormDataForSending = clientsFormData?.reduce(
    (acc: Record<string, unknown>[], curr): Record<string, unknown>[] => {
      const { id, name, value } = curr;
      const isSameClient = acc.map((item) => item?.id).includes(id);

      if (isSameClient) {
        return acc.map((client) => {
          if (client?.id === id) {
            return { ...client, [name]: value };
          }
          return client;
        });
      }

      return [...acc, { id, [name]: value }];
    },
    [],
  );

  const clientsWithRequiredParameter = preparedClientsFormDataForSending.map(
    (client) => {
      if (!client.non_compliance_fee_amount) {
        return {
          ...client,
          non_compliance_fee_amount:
            clientFormData[`clientId=${client.id}:non_compliance_fee_amount`],
        };
      }
      return client;
    },
  );

  return clientsWithRequiredParameter;
};
