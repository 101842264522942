import { uniqBy } from 'lodash';
import { SyntheticEvent, useEffect, useMemo, useState } from 'react';

import { RESERVED_KEYS } from './constants';
import {
  getExistingFilters,
  getPredefinedFilters,
  makeFiltersData,
} from './helpers';
import {
  FilterValue,
  TableFilterProps,
  ISelectedFiltersState,
  FilterType,
} from './types';

export const useComponentFilters = <Data extends object>({
  additionalFilters,
  allColumns,
  data,
  defaultFiltersExpanded,
  filters,
  filtersList,
  hasNegativeFilters = false,
  onChangeExpanded,
  removeServerFilters,
  requiredFilters,
  setAllFilters,
  setFilter,
  setNegative,
  fetchData,
}: TableFilterProps<Data>) => {
  const { base, additional } = makeFiltersData(
    allColumns,
    data,
    additionalFilters,
  );

  const predefinedFilters = useMemo(
    () => getPredefinedFilters({ filters, base, additional }),
    [additional, base, filters],
  );

  const [selectedFilters, setSelectedFilters] = useState<
    ISelectedFiltersState[]
  >(filters ? predefinedFilters : []);

  const filtersOptions = useMemo(
    () => uniqBy(base.options.concat(additional.options), 'id'),
    [additional, base],
  );

  const handleFiltersSubmit = () => {
    if (fetchData) {
      fetchData();
    }
  };

  const handleReactTableFilterChange = (
    id: string,
    value: FilterValue,
    type?: FilterType,
  ) => {
    if (additional.entities[id]) {
      return;
    }

    setFilter(id, value, type);
  };

  const handleClearFilter = (filterId: string) => {
    const newFilters = selectedFilters.filter((el) => el.value !== filterId);

    setSelectedFilters(newFilters);
  };

  const handleAvailableFiltersChange = (
    _: SyntheticEvent,
    newValue: ISelectedFiltersState[],
  ) => {
    setSelectedFilters((prev) => {
      // Because of ColumnSelect has empty value all time.
      // We should check duplicates
      const keys = prev.map((i) => i.value);
      if (keys.includes(newValue[0].value)) {
        return prev;
      }

      return prev.concat(newValue);
    });
  };

  const handleClearAllFilters = () => {
    if (requiredFilters && requiredFilters.length > 0) {
      const allFilters = filtersList();
      allFilters.forEach((filterName) => {
        if (!requiredFilters.includes(filterName)) {
          handleClearFilter(filterName);
        }
      });

      return;
    }

    setAllFilters([]);
    setSelectedFilters([]);

    if (removeServerFilters) {
      removeServerFilters(filtersList());
    }

    if (hasNegativeFilters) {
      selectedFilters.forEach((selectedFilter) => {
        setNegative(String(selectedFilter.value), false);
      });
    }
  };

  useEffect(() => {
    if (selectedFilters.length && defaultFiltersExpanded === undefined) {
      onChangeExpanded(true);
    }
  }, [selectedFilters.length, onChangeExpanded, defaultFiltersExpanded]);

  useEffect(() => {
    const filterNames: ISelectedFiltersState[] = Object.keys(filters)
      .filter((key) => !RESERVED_KEYS.has(key))
      .map((name) => {
        const filterInAdditionalList = !!additionalFilters?.find((item) => {
          return item.accessor === name;
        });

        const type = filterInAdditionalList ? 'additional' : 'base';

        return {
          id: name,
          value: name,
          filterValue: String(filters?.[name]),
          type,
        };
      });

    setSelectedFilters(filterNames);
  }, [filters]);

  useEffect(() => {
    const selectedFiltersCount = selectedFilters?.length || 0;

    const allFiltersCount =
      filters &&
      Object.keys(filters).filter((key) => !RESERVED_KEYS.has(key)).length;

    const countsIsDifferent = allFiltersCount > selectedFiltersCount;

    /**
     * https://jira.exante.eu/browse/RUI-657
     * All empty filters are removed when one filter is removed or filled
     */
    if (countsIsDifferent) {
      setSelectedFilters(predefinedFilters);
    } else {
      setSelectedFilters(selectedFilters);
    }
  }, [selectedFilters, predefinedFilters]);

  useEffect(() => {
    if (predefinedFilters.length) {
      setAllFilters(getExistingFilters(predefinedFilters));
    }
    // Set filters into inner react-table state only when component initialize (for not controlled table)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    additional,
    base,
    filters,
    filtersOptions,
    handleAvailableFiltersChange,
    handleClearAllFilters,
    handleClearFilter,
    handleFiltersSubmit,
    handleReactTableFilterChange,
    hasSelectedFilters: Boolean(selectedFilters.length),
    selectedFilters,
  };
};
