import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { EntryScreenHeader } from '~/components/EntryScreen/EntryScreenHeader';
import { PATHS } from '~/router';
import { TLocationState } from '~/types/location';
import { paramsSerializer } from '~/utils/apiRequest/helpers';

import { WITHDRAWAL_TYPE_SECURITY } from '../../WithdrawalEntry.constants';

import { TWithdrawalHeader } from './WithdrawalHeader.types';
import {
  HAS_RESTRICTION_LABEL,
  labelColors,
} from './constants/WithdrawalHeader.constants';
import { useGetActions } from './hooks/useGetActions';

export const WithdrawalHeader = ({ withdrawal }: TWithdrawalHeader) => {
  const navigate = useNavigate();
  const location = useLocation();

  const isSubTabPrev = location.state?.fromApplication;
  const { requestParams } = (location.state as TLocationState) || {};

  const {
    status,
    username,
    extra_data: extraData,
    is_locked: isLocked,
  } = withdrawal;

  const actionButtons = useGetActions(withdrawal);

  const onCloseEntry = () => {
    if (isSubTabPrev) {
      return navigate(-1);
    }

    const { sorting, limit, skip, page, ...rest } = requestParams || {};

    navigate(`${PATHS.WITHDRAWALS}?${paramsSerializer(rest)}`);
  };

  const labels = useMemo(() => {
    const result = [];
    const hasRestriction = extraData.has_restriction;
    const segment = extraData.segment;

    if (isLocked) {
      const lockedText =
        withdrawal.type === WITHDRAWAL_TYPE_SECURITY
          ? 'Positions are locked'
          : 'Funds are locked';

      result.push({
        textValue: lockedText,
        color: labelColors.locked,
        icon: true,
      });
    }

    if (hasRestriction?.value) {
      result.push({
        textValue: HAS_RESTRICTION_LABEL,
        color: labelColors?.[hasRestriction.color] ?? labelColors.warning,
      });
    }

    if (segment?.value) {
      result.push({
        textValue: segment.value,
        color: labelColors?.[segment.color] ?? labelColors.default,
      });
    }

    return result;
  }, [extraData.has_restriction, extraData.segment, isLocked, withdrawal.type]);

  return (
    <EntryScreenHeader
      status={status}
      title={username}
      actions={actionButtons}
      entityKey="withdrawal"
      onClose={onCloseEntry}
      labels={labels}
    />
  );
};
