import { memo, useContext } from 'react';

import { ApplicationContext } from '~/pages/ApplicationEntry/contexts/ApplicationContext';

import {
  StyledApplicationFilter,
  StyledApplicationFilters,
  StyledApplicationWrapper,
} from './ApplicationTitle.styled';

const ApplicationTitle = () => {
  const { application, activeLegalEntity, setActiveLegalEntity, userFullName } =
    useContext(ApplicationContext);

  return (
    <StyledApplicationWrapper className="ApplicationWrapper">
      {userFullName}
      <StyledApplicationFilters className="ApplicationFilters">
        {application?.application?.legal_entities.map((item) => {
          return (
            <StyledApplicationFilter
              className="ApplicationFilter"
              key={item}
              isActive={activeLegalEntity === item}
              onClick={() =>
                setActiveLegalEntity(activeLegalEntity === item ? '' : item)
              }
            >
              {item}
            </StyledApplicationFilter>
          );
        })}
      </StyledApplicationFilters>
    </StyledApplicationWrapper>
  );
};

export const ApplicationTitleMemoized = memo(ApplicationTitle);
