import { useCallback, useContext, useEffect } from 'react';

import {
  useCreateCommentMutation,
  useDeleteDepositMutation,
  useGetGlobalContextQuery,
  useTransitionDepositMutation,
} from '~/api';
import { TDepositInfo } from '~/api/deposits/deposits.types';
import { ActionWithConfirmationProps } from '~/modules/actionButtonsBlock/ActionButton';
import {
  mapCommentType,
  mapContentType,
} from '~/modules/comments/components/Comments/Comments.constants';

import { DepositEditFormContext } from '../../../../DepositForm/contexts';
import {
  buttonKeyToDepositStatusMap,
  staticButtonWithConfirmationPropsMap,
} from '../DepositHeader.constants';
import { ActionButtonWithConfirmationEnum } from '../DepositHeader.types';

export const useGetButtonProps = (
  deposit: TDepositInfo,
  onCloseEntry: VoidFunction,
) => {
  const { setIsEdit } = useContext(DepositEditFormContext);
  const { data: globalContext } = useGetGlobalContextQuery();

  const [onCommentCreate, { isLoading: isCreateCommentLoading }] =
    useCreateCommentMutation();

  const [
    onDeleteDeposit,
    { isLoading: isDeleteDepositLoading, isSuccess: isDeleteDepositSuccess },
  ] = useDeleteDepositMutation();
  const [
    onTransitionDeposit,
    {
      isLoading: isTransitionDepositLoading,
      isSuccess: isTransitionDepositSuccess,
      isError: isTransitionDepositError,
    },
  ] = useTransitionDepositMutation();

  const isLoading =
    isDeleteDepositLoading ||
    isTransitionDepositLoading ||
    isCreateCommentLoading;

  useEffect(() => {
    if (isDeleteDepositSuccess) {
      onCloseEntry();
    }
  }, [isDeleteDepositSuccess, onCloseEntry]);

  const onTransitionDepositHandler = useCallback(
    (key: Exclude<ActionButtonWithConfirmationEnum, 'deleteBtn'>) => {
      setIsEdit(false);
      onTransitionDeposit({
        depositId: deposit.id,
        status: buttonKeyToDepositStatusMap[key],
      });
    },
    [deposit.id, onTransitionDeposit, setIsEdit],
  );

  const onDelete = useCallback(() => {
    setIsEdit(false);
    onDeleteDeposit({ depositId: deposit.id });
  }, [deposit.id, onDeleteDeposit, setIsEdit]);

  const onAddComment = useCallback(
    (holdReason: string) => {
      const contentType =
        globalContext?.content_type?.[mapContentType.deposits] || 0;

      onCommentCreate({
        content: holdReason,
        answered: false,
        content_type: contentType,
        hidden: false,
        is_system: false,
        kind: mapCommentType.deposits,
        object_id: deposit.id,
        removed: false,
      });
    },
    [deposit.id, globalContext?.content_type, onCommentCreate],
  );

  const onHold = useCallback(
    (holdReason: string) => {
      if (holdReason) {
        onTransitionDepositHandler(ActionButtonWithConfirmationEnum.OnHoldBtn);

        if (isTransitionDepositSuccess && !isTransitionDepositError) {
          onAddComment(holdReason);
        }
      }
    },
    [
      isTransitionDepositError,
      isTransitionDepositSuccess,
      onAddComment,
      onTransitionDepositHandler,
    ],
  );

  const getOnConfirmHandler = useCallback(
    (key: ActionButtonWithConfirmationEnum) => {
      if (key === ActionButtonWithConfirmationEnum.OnHoldBtn) {
        return onHold;
      }

      if (key === ActionButtonWithConfirmationEnum.DeleteBtn) {
        return onDelete;
      }

      return () => onTransitionDepositHandler(key);
    },
    [onDelete, onHold, onTransitionDepositHandler],
  );

  const getButtonProps = (
    key: ActionButtonWithConfirmationEnum,
    disable: boolean,
  ): ActionWithConfirmationProps['props'] => {
    const staticProps = staticButtonWithConfirmationPropsMap[key];

    return {
      onConfirm: getOnConfirmHandler(key),
      withError: key === ActionButtonWithConfirmationEnum.OnHoldBtn,
      isLoadingActions: isLoading || disable,
      ...staticProps,
    };
  };

  return {
    getButtonProps,
  };
};
