import { APP_INSTANCE } from '~/utils/getCrmInstanceName';

// todo replace all old crm paths
export const OLD_CRM_ROOT_PATH = `/${APP_INSTANCE}`;
export const ROOT_BASEURL = `${APP_INSTANCE}-ui`;

export const ROOT_PATH = `/${ROOT_BASEURL}`;

export const PATHS = {
  DEPOSITS: `${ROOT_PATH}/deposits`,
  USERS: `${ROOT_PATH}/users`,
  DEPOSIT_ENTRY: `${ROOT_PATH}/deposits/:id`,
  CREATE_DEPOSIT_RECORD: `${ROOT_PATH}/deposits/create/:ids`,
  MONITORING: `${ROOT_PATH}/monitoring`,
  MONITORING_ENTRY: `${ROOT_PATH}/monitoring/:id`,
  CREATE_REQUEST: `${ROOT_PATH}/monitoring/create/:applicationId`,
  WITHDRAWALS: `${ROOT_PATH}/withdrawals`,
  WITHDRAWALS_ENTRY: `${ROOT_PATH}/withdrawals/:id`,
  DATA_CHANGE_REQUESTS: `${ROOT_PATH}/data-change-requests`,
  DATA_CHANGE_REQUESTS_ENTRY: `${ROOT_PATH}/data-change-requests/:id`,
  CONTACTS: `${ROOT_PATH}/contacts/`,
  CONTACTS_IMPORT_CONTACT: `${ROOT_PATH}/contacts/import-contact/`,
  IMPORT_TAG: `${ROOT_PATH}/import-tag/`,
  ADMIN: `${ROOT_PATH}/admin`,
  SUPPORT: `${ROOT_PATH}/support`,
  ONBOARDING: `${ROOT_PATH}/onboarding`,
  SALES_QA: `${ROOT_PATH}/sales_qa`,
  BACKOFFICE: `${ROOT_PATH}/backoffice`,
  MARKETING: `${ROOT_PATH}/marketing`,
  ABOUT_PERMISSIONS: `${ROOT_PATH}/about-permissions`,
  REPORTS: `${ROOT_PATH}/reports`,
  NOTIFICATIONS: `${ROOT_PATH}/notifications`,
  APPLICATIONS_ENTRY: `${ROOT_PATH}/users/:ids/:page?`,
  CREATE_PERSON: `${ROOT_PATH}/create-person/:applicationId`,
  EDIT_PERSON: `${ROOT_PATH}/edit-person/:personId`,
};
