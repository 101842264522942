import { ICellValue, IColumn } from 'react-ui-kit-exante';

import { TFile, TRequest } from '~/api/requests/requests.types';
import { StatusCell } from '~/components/Cells';
import { LinksCell } from '~/components/Cells/LinksCell';
import { TLinksCellProps } from '~/components/Cells/LinksCell/LinksCell.types';
import { DEFAULT_EMPTY_PLACEHOLDER } from '~/constants/common';
import {
  formatCellToCheckbox,
  formatCellToName,
} from '~/utils/table/cellFormatters';

import { TRequestsGetColumns } from './Requests.types';
import { RequestsActionCell } from './components/RequestsActionCell';

export const getColumns: ({
  recordPermissions,
}: TRequestsGetColumns) => IColumn<TRequest>[] = ({
  recordPermissions,
  fetchData,
}) => {
  const baseColumns: IColumn<TRequest>[] = [
    {
      id: 'id',
      Header: 'ID',
      width: 100,
    },
    {
      id: 'application',
      Header: 'Application',
      Cell: ({ value }: ICellValue<TRequest>) => value?.username,
      minWidth: 410,
    },
    {
      id: 'client_id',
      Header: 'Client ID',
      Cell: ({ value }: ICellValue<TRequest>) =>
        value?.length ? value?.join(', ') : DEFAULT_EMPTY_PLACEHOLDER,
    },
    {
      id: 'manager',
      Header: 'Manager',
      Cell: formatCellToName(),
    },
    {
      id: 'created',
      Header: 'Created',
      formatting: 'dateUTC',
    },
    {
      id: 'changed_by',
      Header: 'Changed by',
      Cell: formatCellToName(),
    },
    {
      id: 'type',
      Header: 'Request type',
      minWidth: 180,
    },
    {
      id: 'received_from',
      Header: 'Received from',
      Cell: formatCellToName(),
    },
    {
      id: 'assigned_to',
      Header: 'Assigned to',
      Cell: formatCellToName(),
    },
    {
      id: 'access_level',
      Header: 'Access level',
      Cell: formatCellToName(),
    },
    {
      id: 'state',
      Header: 'State',
      disableSortBy: true,
      Cell: ({ value }: ICellValue<TRequest>) => (
        <StatusCell status={value} type="request" />
      ),
    },
    {
      id: 'close_only_mode',
      Header: 'Close only mode',
      Cell: formatCellToCheckbox('value'),
      minWidth: 170,
    },
    {
      id: 'non_compliance_fee',
      Header: 'Non-compliance fee',
      Cell: formatCellToCheckbox('value'),
      minWidth: 190,
    },
    {
      id: 'subject',
      Header: 'Subject',
    },
    {
      id: 'summary',
      Header: 'Summary',
      minWidth: 250,
    },
    {
      id: 'files',
      Header: 'Files',
      disableSortBy: true,
      Cell: ({ value }: ICellValue<TRequest>) => {
        if (value?.length) {
          const links: TLinksCellProps['links'] = (value as TFile[]).map(
            (file) => ({
              id: file.id,
              name: file.file_name,
              url: file.url,
            }),
          );

          return <LinksCell links={links} />;
        }
        return DEFAULT_EMPTY_PLACEHOLDER;
      },
    },
    {
      id: 'deadline',
      Header: 'Deadline',
      formatting: 'dateUTC',
    },
    {
      id: 'registration_legal_entity',
      Header: 'Registration LE',
      minWidth: 160,
    },
    {
      id: 'legal_entities',
      Header: 'Legal Entity',
    },
    {
      id: 'application_state',
      Header: 'Onboarding state',
      minWidth: 180,
    },
    {
      id: '_tag_monitoring / source',
      Header: 'Source',
    },
    {
      id: '_tag_monitoring / source other',
      Header: 'Source other',
    },
    {
      id: '_tag_monitoring / date of source',
      Header: 'Date of source',
    },
    {
      id: '_tag_monitoring / date of opening',
      Header: 'Date of opening',
      minWidth: 160,
    },
    {
      id: '_tag_monitoring / priority',
      Header: 'Priority',
    },
    {
      id: '_tag_monitoring / related case',
      Header: 'Related case',
    },
    {
      id: '_tag_monitoring / local mlro',
      Header: 'Local MLRO',
    },
  ];

  if (recordPermissions?.delete) {
    baseColumns.push({
      id: 'action',
      Header: 'Actions',
      disableSortBy: true,
      width: 120,
      align: 'right',
      Cell: ({ row: { original } }: ICellValue<TRequest>) => (
        <RequestsActionCell recordId={original.id} fetchData={fetchData} />
      ),
    });
  }

  return baseColumns.map((item) => ({
    ...item,
    accessor: item.id,
    disableFilters: true,
  }));
};
