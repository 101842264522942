import { blockNonNativeProps, IconButton, styled } from 'react-ui-kit-exante';

import type { THeaderFundsProps } from './EntryScreenHeader.types';

export const StyledEntryScreenHeaderWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  padding: 28px 24px;
`;

export const StyledEntryScreenHeaderActions = styled('div')`
  display: flex;
  gap: 16px;
`;

export const StyledEntryScreenHeaderLabels = styled('div')`
  display: flex;
  gap: 8px;
`;

export const StyledEntryScreenHeaderTitleWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledEntryScreenHeaderTitleWIthInfo = styled('div')`
  min-width: 45%;
`;

export const StyledEntryScreenHeaderFunds = styled('div', {
  shouldForwardProp: blockNonNativeProps(['labelColor']),
})<THeaderFundsProps>`
  border-radius: 32px;
  font-weight: 400;
  font-size: 16px;
  padding: 4px 16px;
  width: fit-content;
  white-space: nowrap;
  margin-top: 4px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme?.color.bg.primary};
  font-family: ${({ theme }) => theme?.font?.header};
  background-color: ${({ theme, labelColor }) =>
    theme?.color?.typo?.[labelColor]};
`;

export const StyledEntryScreenHeaderTitle = styled('div')`
  font-size: 32px;
  font-weight: 400;
  margin: 0 8px 0 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: ${({ theme }) => theme?.font?.header};
`;

export const StyledEntryScreenHeaderBlockIcon = styled(IconButton)`
  margin-left: 5px;
`;
