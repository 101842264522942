import { FC, useContext } from 'react';
import { IconButton } from 'react-ui-kit-exante';

import { ApplicationFormContext } from '../../../contexts/FormContext';

import { StyledTabHeader } from './TabHeader.styled';

export const TabHeader: FC = () => {
  const { formNotEdited, isLoading } = useContext(ApplicationFormContext);
  return (
    <StyledTabHeader className="TabHeader">
      <IconButton
        label="Save"
        type="submit"
        iconName="SaveIcon"
        iconColor="action"
        iconSize={24}
        disabled={formNotEdited || isLoading}
        className="SaveQualifiedLeadButton"
        data-test-id="save-qualified-lead--button"
      />
    </StyledTabHeader>
  );
};
