import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Panel, IconButton, Loader } from 'react-ui-kit-exante';

import {
  useCreatePersonMutation,
  useEditPersonMutation,
  useGetApplicationTagsQuery,
  useGetPersonQuery,
  useGetPersonTagsQuery,
} from 'api';
import { TCreatePersonParams } from '~/api/applications/applications.types';
import { EntryScreenWrapper } from '~/components/EntryScreen';
import { StyledTitle } from '~/pages/ApplicationEntry/components/EnrtyScreenStructure/Section/Section.styled';
import { TDefaultValue } from '~/pages/ApplicationEntry/contexts/FormContext/ApplicationFormContext.types';
import {
  getDefaultFormValues,
  mapTagData,
} from '~/pages/ApplicationEntry/contexts/FormContext/helpers';
import { RequestStatusAction } from '~/pages/ApplicationEntry/tabs/OnboardingTab/OnboardingSettingsTab/components/RequestStatusAction';
import { TParams } from '~/router/router.types';
import { TDefaultFormValues } from '~/types/form';
import { getDirtyValues } from '~/utils/forms/getDirtyValues';

import {
  DEFAULT_STATIC_PERSON_FORM,
  SECTIONS_FOR_PERSON,
} from './CreatePerson.constants';
import { getValidationPerson } from './CreatePerson.helpers';
import {
  StyledCreatePersonActions,
  StyledCreatePersonForm,
  StyledCreatePersonTitle,
  StyledSectionBody,
} from './CreatePerson.styled';

export const CreatePerson = () => {
  const { applicationId, personId } = useParams<TParams>();

  const isEdit = !!personId;

  const navigate = useNavigate();
  const [createPerson, createPersonState] = useCreatePersonMutation();
  const [editPerson, editPersonState] = useEditPersonMutation();
  const {
    data: tagsData,
    isLoading: isLoadingTagsData,
    isFetching: isFetchingTagsData,
  } = useGetApplicationTagsQuery(
    { objectId: applicationId, sectionName: SECTIONS_FOR_PERSON },
    { skip: isEdit, refetchOnMountOrArgChange: true },
  );
  const {
    data: personTags,
    isLoading: isLoadingPersonTags,
    isFetching: isFetchingPersonTags,
  } = useGetPersonTagsQuery(
    { objectId: personId, sectionName: SECTIONS_FOR_PERSON },
    { skip: !isEdit, refetchOnMountOrArgChange: true },
  );
  const {
    data: personData,
    isLoading: isLoadingPersonData,
    isFetching: isFetchingPersonData,
  } = useGetPersonQuery(
    { personId },
    { skip: !isEdit, refetchOnMountOrArgChange: true },
  );

  const isLoading =
    isLoadingTagsData ||
    isLoadingPersonTags ||
    isLoadingPersonData ||
    isFetchingTagsData ||
    isFetchingPersonTags ||
    isFetchingPersonData;

  const currentData = tagsData || personTags;

  const preparedTagsData = currentData && [
    currentData[2],
    currentData[3],
    currentData[0],
    currentData[1],
  ];

  const allDefaultTags = currentData?.map(({ tags }) => tags).flat() || [];

  const defaultValues = getDefaultFormValues([
    ...DEFAULT_STATIC_PERSON_FORM,
    ...allDefaultTags,
  ]);

  const resolver = getValidationPerson();

  const methods = useForm<TDefaultFormValues>({
    defaultValues,
    resolver: yupResolver(resolver),
  });

  const {
    handleSubmit,
    getValues,
    formState: { dirtyFields, isDirty },
    reset,
  } = methods;

  const isDisabled =
    !isDirty || createPersonState.isLoading || editPersonState.isLoading;

  useEffect(
    () =>
      reset({
        ...defaultValues,
        affiliated_type: personData?.affiliated_type,
        first_name: personData?.first_name,
        last_name: personData?.last_name,
      }),
    [personTags, personData],
  );

  const handleOnClose = () => navigate(-1);

  const onSave = async () => {
    const formData = getDirtyValues(dirtyFields, getValues());
    const tagsKeys = Object.keys(formData);
    const tagsForSave = currentData
      ?.flatMap(({ tags }) => tags)
      .filter(({ name }) => tagsKeys.includes(name));

    const tagData =
      tagsForSave?.map((tag) =>
        mapTagData({
          tag,
          value:
            formData[tag.name] === ''
              ? undefined
              : (formData[tag.name] as TDefaultValue),
          tagNameProp: 'tag',
        }),
      ) || [];

    const data: TCreatePersonParams = {
      tagvalues: tagData,
      application: applicationId || personData?.application || '',
    };

    const affiliatedType = formData.affiliated_type;
    const firstName = formData.first_name;
    const lastName = formData.last_name;

    if (typeof affiliatedType === 'string') {
      data.affiliated_type = affiliatedType;
    }
    if (typeof firstName === 'string') {
      data.first_name = firstName;
    }
    if (typeof lastName === 'string') {
      data.last_name = lastName;
    }

    const response = isEdit
      ? await editPerson({ data, personId })
      : await createPerson({ data });

    if ('data' in response) {
      handleOnClose();
    }
  };

  if (isLoading) {
    return (
      <EntryScreenWrapper>
        <Loader size={32} isCentered />
      </EntryScreenWrapper>
    );
  }

  const getTitle = (name: string, title: string) => {
    if (name === 'sumsub') {
      return (
        <StyledCreatePersonTitle className="CreatePersonTitle">
          {title}
          <RequestStatusAction
            disabled={!isEdit}
            personId={personId}
            action="check_aml"
          />
        </StyledCreatePersonTitle>
      );
    }
    if (name === 'accuity') {
      return (
        <StyledCreatePersonTitle className="CreatePersonTitle">
          {title}
          <RequestStatusAction
            disabled={!isEdit}
            personId={personId}
            action="check_accuity"
          />
        </StyledCreatePersonTitle>
      );
    }
    if (name === 'sumsub_liveness_check') {
      return 'Liveness Check';
    }

    return title;
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSave)}>
        <Panel
          title={isEdit ? 'Edit Person' : 'Create Person'}
          action={
            <StyledCreatePersonActions className="CreatePersonActions">
              <IconButton
                title="Save withdrawal data"
                label="Save"
                type="submit"
                iconName="SaveIcon"
                iconColor="action"
                iconSize={24}
                disabled={isDisabled}
              />
              <IconButton
                type="button"
                iconSize={32}
                iconName="CloseIcon"
                iconColor="promo"
                onClick={handleOnClose}
              />
            </StyledCreatePersonActions>
          }
        >
          {preparedTagsData?.map(({ tags = [], title, name }) => {
            return (
              <StyledCreatePersonForm key={name} className="CreatePersonForm">
                {(title || name === 'sumsub_liveness_check') && (
                  <StyledTitle className="StyledTitle">
                    {getTitle(name, title)}
                  </StyledTitle>
                )}
                <StyledSectionBody
                  className="SectionBody"
                  withoutText
                  tags={
                    name === 'person'
                      ? [...DEFAULT_STATIC_PERSON_FORM, ...tags]
                      : tags
                  }
                />
              </StyledCreatePersonForm>
            );
          })}
        </Panel>
      </form>
    </FormProvider>
  );
};
