import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import {
  useLazyGetWithdrawalExportToXMLQuery,
  useWithdrawalActionsMutation,
} from '~/api';
import { IconBtnProps } from '~/modules/actionButtonsBlock/ActionButton';
import { TParams } from '~/router/router.types';

import { ActionIconButtonEnum } from '../WithdrawalHeader.types';
import {
  staticIconButtonPropsMap,
  WithdrawalTypesOfActions,
} from '../constants';

export const useGetIconButtonProps = (
  isLoadingActions: boolean,
  loadingActionsHandler: (isLoading: boolean) => void,
) => {
  const { id: withdrawalId } = useParams<TParams>();

  const [
    getWithdrawalExportToXML,
    { isLoading: isGetWithdrawalExportLoading },
  ] = useLazyGetWithdrawalExportToXMLQuery();

  const [onWithdrawalAction, { isLoading: isWithdrawalActionsLoading }] =
    useWithdrawalActionsMutation();

  useEffect(() => {
    loadingActionsHandler(
      isGetWithdrawalExportLoading || isWithdrawalActionsLoading,
    );
  }, [
    isGetWithdrawalExportLoading,
    isWithdrawalActionsLoading,
    loadingActionsHandler,
  ]);

  const handleMakeActive = () => {
    if (withdrawalId) {
      onWithdrawalAction({
        id: withdrawalId,
        type: WithdrawalTypesOfActions.MakeActive,
      });
    }
  };

  const handleExportToXML = () => {
    if (withdrawalId) {
      getWithdrawalExportToXML({
        id: withdrawalId,
      });
    }
  };

  const onClickHandlerMap = {
    [ActionIconButtonEnum.MakeActiveBtn]: handleMakeActive,
    [ActionIconButtonEnum.ExportToXMLBtn]: handleExportToXML,
  };

  const getIconButtonProps = (
    buttonKey: ActionIconButtonEnum,
  ): IconBtnProps['props'] => {
    const staticProps = staticIconButtonPropsMap[buttonKey];

    return {
      onClick: onClickHandlerMap[buttonKey],
      disabled: isLoadingActions,
      ...staticProps,
    };
  };

  return {
    getIconButtonProps,
  };
};
