import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  calculateCountOfPages,
  IColumn,
  IconButton,
  IOnFetchArguments,
  Table,
  TAdditionalFilter,
  useTableData,
} from 'react-ui-kit-exante';

import {
  useGetDepositTableFiltersQuery,
  useGetPermissionsQuery,
  useLazyGetDepositsQuery,
} from '~/api';
import { TDepositInfo } from '~/api/deposits/deposits.types';
import { DEPOSIT_DATE_FIELDS } from '~/constants/deposits';
import { contextSearchFilter } from '~/constants/table';
import {
  useCallbackTriggerHandle,
  useGetTableFilterOptions,
  useLogHandleTime,
} from '~/hooks';
import { ApplicationContext } from '~/pages/ApplicationEntry/contexts/ApplicationContext';
import { PATHS, ROOT_PATH } from '~/router';
import { TParams } from '~/router/router.types';
import { prepareFilterParams } from '~/utils/prepareFilterParams';
import {
  getFilterParams,
  getPaginationParams,
  getDefaultPagination,
  getAdditionalFilters,
} from '~/utils/table';
import { getSortingParams } from '~/utils/table/sorting';

import {
  DISPLAYED_COLUMNS_KEYS,
  getColumns,
  TABLE_ID,
} from './RecordsTable.constants';
import { getRowProps } from './RecordsTable.helpers';
import { StyledRecordsTableWrapper } from './RecordsTable.styled';

export const RecordsTable = () => {
  const { setStartHandleTime, logHandleTime } = useLogHandleTime(
    `application-entry-${TABLE_ID}`,
  );

  const { ids } = useParams<TParams>();

  const navigate = useNavigate();
  const { application, activeLegalEntity } = useContext(ApplicationContext);

  const applicationId = application?.application?.id;

  const { data: recordsTableFilters } = useGetDepositTableFiltersQuery();
  const { data: permissions } = useGetPermissionsQuery();
  const [
    fetchRecords,
    { isLoading: isGetDepositsTabLoading, isSuccess: isGetDepositsTabSuccess },
  ] = useLazyGetDepositsQuery();

  const additionalOptions = useGetTableFilterOptions(recordsTableFilters);

  useEffect(() => {
    if (isGetDepositsTabLoading) {
      setStartHandleTime();
    }
  }, [isGetDepositsTabLoading, setStartHandleTime]);

  const canAdd = Boolean(permissions?.deposit_info.add_depositinfo);

  const getRecords = useCallback(
    async (params: IOnFetchArguments) => {
      const paginationParams = getPaginationParams(params);
      const filterParams = prepareFilterParams(
        getFilterParams(params, DEPOSIT_DATE_FIELDS),
      );
      const sortingParams = getSortingParams(params);
      if (applicationId) {
        const response = await fetchRecords({
          ...paginationParams,
          ...filterParams,
          ...sortingParams,
          field_application_id: String(applicationId),
          field_legal_entity: activeLegalEntity || undefined,
        });
        return response.data;
      }

      return undefined;
    },
    [fetchRecords, activeLegalEntity, applicationId],
  );

  const tableDataArgs = useMemo(
    () => ({
      tableId: TABLE_ID,
      data: { onFetch: getRecords },
      pagination: {
        getDefaultPagination,
      },
      filters: {
        getDefaultFilters: () => {
          return contextSearchFilter;
        },
      },
      saveViewParamsAfterLeave: true,
      hasNegativeFilters: true,
    }),
    [getRecords],
  );

  const {
    data,
    limit,
    setLimit,
    setPage,
    page,
    skip,
    isLoading,
    setFilter,
    removeFilter,
    resetFilters,
    filters,
    setSorting,
    params,
  } = useTableData(tableDataArgs);

  const columns = getColumns();

  const additionalFilters = useMemo<
    TAdditionalFilter<Record<string, unknown>>[]
  >(
    () =>
      getAdditionalFilters({
        onFilter: setFilter,
        onRemove: removeFilter,
        filters: recordsTableFilters,
        additionalOptions,
        withContextSearch: true,
      }),
    [removeFilter, setFilter, recordsTableFilters, additionalOptions],
  );

  const filterProps = useMemo(
    () => ({
      removeAllFilters: resetFilters,
      additionalFilters,
      filters,
      manualFilters: true,
    }),
    [additionalFilters, filters, resetFilters],
  );

  const total = data?.iTotalDisplayRecords ?? 0;

  const pageCount = useMemo(
    () => calculateCountOfPages(total, limit),
    [limit, total],
  );

  const serverPaginationProps = useMemo(
    () => ({
      pageSize: limit,
      skip,
      setPage,
      setPageSize: setLimit,
      pageIndex: page,
      total,
      pageCount,
    }),
    [skip, limit, page, pageCount, setLimit, setPage, total],
  );

  const handleOnAddRecord = () => {
    navigate(`${ROOT_PATH}/deposits/create/${ids}`);
  };

  const handleRowClick = useCallback(
    (tableData: IColumn<TDepositInfo>) => {
      const { row } = tableData;

      if (permissions?.record?.change) {
        navigate(`${PATHS.DEPOSITS}/${row.original.id}`, {
          state: {
            previousPath: window.location.href,
            fromApplication: true,
            requestParams: params,
          },
        });
      }
    },
    [navigate, params],
  );

  const additionalActions = canAdd
    ? [
        {
          component: (
            <IconButton
              key="add"
              iconName="AddIcon"
              label="Add New Deposit"
              iconColor="action"
              onClick={handleOnAddRecord}
            />
          ),
        },
      ]
    : undefined;

  useCallbackTriggerHandle({
    cb: logHandleTime,
    dataTrigger: data?.results,
    processTrigger: !isGetDepositsTabLoading && isGetDepositsTabSuccess,
  });

  return (
    <StyledRecordsTableWrapper className="RecordsTableWrapper">
      <Table
        className="RecordsTable"
        columns={columns}
        isLoading={isLoading}
        displayedColumnKeys={DISPLAYED_COLUMNS_KEYS}
        filtersExpanded
        onSort={setSorting}
        hasFilters
        hasNegativeFilters
        filteringProps={filterProps}
        isFlexLayout
        hasPagination
        manualSortBy
        handleCellClick={handleRowClick}
        serverPaginationProps={serverPaginationProps}
        data={data?.results || []}
        tableId={TABLE_ID}
        getRowProps={getRowProps}
        showTableInfo
        saveColumnOrder
        additionalActions={additionalActions}
      />
    </StyledRecordsTableWrapper>
  );
};
