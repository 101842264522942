import { useContext } from 'react';

import { ValueRow } from '~/components/ValueRow';
import { ApplicationContext } from '~/pages/ApplicationEntry/contexts/ApplicationContext';
import { getFormField } from '~/utils/getFormField';

import { getNameFormFields } from './NameForm.helpers';
import {
  StyledOnboardingLogList,
  StyledClientFormField,
} from './NewForm.styled';

export const NameForm = () => {
  const { application } = useContext(ApplicationContext);

  return (
    <StyledOnboardingLogList className="OnboardingLogList">
      {getNameFormFields(application?.application.is_corporate).map(
        ({ name, title }) => {
          return (
            <ValueRow
              key={name}
              label={title}
              valueNode={
                <StyledClientFormField className="ClientFormField">
                  {getFormField({
                    name,
                  })}
                </StyledClientFormField>
              }
            />
          );
        },
      )}
    </StyledOnboardingLogList>
  );
};
