import { getTableId } from '~/utils/table';

export const COLUMNS = [
  {
    Header: 'Risk Type',
    accessor: 'title',
    disableSortBy: true,
    disableFilters: true,
    width: 200,
  },
  {
    Header: 'Type',
    accessor: 'value',
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'Value',
    accessor: 'clean_score',
    disableSortBy: true,
    disableFilters: true,
    width: 90,
  },

  {
    Header: 'Impact %',
    accessor: 'impact_percent',
    disableSortBy: true,
    disableFilters: true,
    width: 90,
  },
  {
    Header: 'Risk',
    accessor: 'score',
    disableSortBy: true,
    disableFilters: true,
    width: 90,
  },
  {
    Header: 'Forced Level',
    accessor: 'level',
    disableSortBy: true,
    disableFilters: true,
    width: 90,
  },
];

export const TABLE_ID = getTableId('risk-scoring');
