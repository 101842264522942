import {
  EDepositStatuses,
  TCreateDepositBody,
} from '~/api/deposits/deposits.types';

export const initialDeposit: Omit<
  TCreateDepositBody,
  'application' | 'account_id'
> = {
  datetime: undefined,
  name: undefined,
  swift: undefined,
  ac_no: undefined,
  iban: undefined,
  country_a: undefined,
  bank_name: undefined,
  amount: undefined,
  ccy: undefined,
  detail: undefined,
  is_draft: false,
  deposit_type: {
    id: 'wire',
    text: 'Wire',
  },
  confirms_required: undefined,
  status: {
    id: -1,
    text: EDepositStatuses.New,
  },
  client_custody_account: undefined,
  custody: undefined,
  custody_account: undefined,
  bank_deposit_id: undefined,
  uploads: undefined,
};
