import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { useLazyGetGetHiddenFieldsQuery } from '~/api';
import { TDepositType } from '~/api/deposits/deposits.types';
import { TDefaultFormValues } from '~/types/form';
import { getDirtyValues } from '~/utils/forms/getDirtyValues';

import { DEPOSIT_TYPES } from '../DepositForm.constants';
import { TFieldConfig } from '../DepositForm.types';

import { getFormValidationScheme } from './helpers';

type FormMethodProps = {
  defaultValues: TDefaultFormValues;
  initDefault: TFieldConfig[];
};

export const useGetFormMethod = ({
  defaultValues,
  initDefault,
}: FormMethodProps) => {
  const [getGetHiddenFields, { data: hiddenFields }] =
    useLazyGetGetHiddenFieldsQuery();

  const validationScheme = getFormValidationScheme(initDefault);

  const methods = useForm<TDefaultFormValues>({
    defaultValues,
    resolver: yupResolver(validationScheme),
  });

  const { handleSubmit, getValues, formState, watch, reset } = methods;

  const formData = getDirtyValues(
    formState.dirtyFields,
    getValues(),
    hiddenFields,
  );

  const depositType = watch('deposit_type');

  useEffect(() => {
    if (depositType) {
      getGetHiddenFields({
        type: DEPOSIT_TYPES[depositType as keyof TDepositType],
      });
    }
  }, [depositType]);

  return {
    reset,
    handleSubmit,
    methods,
    formData,
    hiddenFields,
  };
};
