import { FC } from 'react';

import { isDepositInfo, TDepositFormProps } from './DepositForm.types';
import { DepositCreateForm, DepositEditForm } from './components';

export const DepositForm: FC<TDepositFormProps> = ({
  deposit,
  onCloseEntry,
}) => {
  if (isDepositInfo(deposit)) {
    return <DepositEditForm deposit={deposit} />;
  }

  return <DepositCreateForm deposit={deposit} onCloseEntry={onCloseEntry} />;
};
