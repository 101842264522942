import { TDepositInfo } from '~/api/deposits/deposits.types';

export type TDepositHeaderProps = {
  deposit: TDepositInfo;
};

export enum ActionButtonWithConfirmationEnum {
  BookFundsBtn = 'bookFundsBtn',
  OnHoldBtn = 'onHoldBtn',
  RejectBtn = 'rejectBtn',
  MakeActiveBtn = 'makeActiveBtn',
  DeleteBtn = 'deleteBtn',
}
