import { TClient } from '~/api/applications/applications.types';

export const getDefaultClientsValues = (clients: TClient[]) => {
  return clients
    ?.map((client, index) => {
      return Object.entries(client).map((item) => {
        const propName = `clientId=${clients[index].id}:${item[0]}`;

        if (item[0].includes('date')) {
          const preparedDate = item[1] ? new Date(String(item[1])) : null;

          return {
            [propName]: preparedDate,
          };
        }

        return {
          [propName]: item[1],
        };
      });
    })
    .flat()
    .reduce((prev, curr) => ({ ...prev, ...curr }), {});
};
