import { ICellValue, IColumn } from 'react-ui-kit-exante';

import { TWithdrawalsData } from '~/api/withdrawals/withdrawals.types';
import { StatusCell } from '~/components/Cells';

import { replaceZeroAfterNumber } from '../../utils/replaceZeroAfterNumber';
import { DEFAULT_EMPTY_PLACEHOLDER } from '../common';

export const DISPLAYED_COLUMNS_KEYS = [
  'username',
  'account',
  'datetime',
  'status',
  'responsible_title',
  'changed',
  'amount_currency',
  'currency',
  'eur_amount_repr',
  'legal_entity',
  'type',
];

const getIdAndHeader = (
  id: string,
  header: string,
): IColumn<TWithdrawalsData> => ({
  id,
  Header: header,
});

export const getColumns = (): IColumn<TWithdrawalsData>[] => {
  return [
    getIdAndHeader('id', 'Id'),
    getIdAndHeader('username', 'User'),
    getIdAndHeader('client_id', 'Client'),
    getIdAndHeader('account', 'Account Id'),
    getIdAndHeader('ip', 'Ip'),
    getIdAndHeader('ip_country', 'Ip Country'),
    {
      id: 'datetime',
      Header: 'Created',
      formatting: 'dateUTC',
    },
    {
      id: 'status',
      Header: 'Status',
      Cell: ({ row: { values } }: ICellValue<TWithdrawalsData>) => (
        <StatusCell status={values.status} type="withdrawal" />
      ),
    },
    {
      id: 'responsible_title',
      Header: 'Responsible',
      disableSortBy: true,
    },
    {
      id: 'changed',
      Header: 'Changed',
      formatting: 'dateUTC',
    },
    getIdAndHeader('amount_currency', 'Amount Currency'),
    getIdAndHeader('currency', 'Currency'),
    {
      id: 'eur_amount_repr',
      Header: 'Eur Equivalent',
      Cell: ({ row: { values } }: ICellValue<TWithdrawalsData>) => {
        const eurEquivalent = values?.eur_amount_repr?.replace('EUR', '');

        return <div>{eurEquivalent}</div>;
      },
    },
    getIdAndHeader('confirm_type', 'Confirm Type'),
    getIdAndHeader('legal_entity', 'Legal Entity'),
    getIdAndHeader('assigned_to', 'Assigned To'),
    getIdAndHeader('type', 'Type'),
    {
      id: 'commission_amount',
      Header: 'Commission',
      Cell: ({ row: { original } }: ICellValue<TWithdrawalsData>) => {
        if (original?.commission_amount && original?.commission_currency) {
          return `${replaceZeroAfterNumber(original?.commission_amount)} ${
            original?.commission_currency
          }`;
        }
        return DEFAULT_EMPTY_PLACEHOLDER;
      },
      disableSortBy: true,
    },
    {
      id: 'account_nav',
      Header: 'Account Nav',
      disableSortBy: true,
    },
    getIdAndHeader('is_withdrawn', 'Is Withdrawn'),
    getIdAndHeader('is_crypto_withdrawn', 'Is Crypto Withdrawn'),
    getIdAndHeader('all_funds', 'All Funds'),
    getIdAndHeader('is_card_withdrawn', 'Card Withdrawn'),
    getIdAndHeader('source', 'Source'),
    getIdAndHeader('bank_country', 'Bank Country'),
    getIdAndHeader('value_date', 'Value Date'),
    getIdAndHeader('bank_to_transfer', 'Bank To Transfer'),
    getIdAndHeader('account_to_transfer', 'Account To Transfer'),
    getIdAndHeader('transfer_status', 'Transfer Status'),
    getIdAndHeader('iban', 'Iban'),
    getIdAndHeader('account_number', 'Account Number'),
    getIdAndHeader('swift', 'Swift'),
    getIdAndHeader('bank_name', 'Bank Name'),
    getIdAndHeader('exante_account', 'Exante Account'),
    {
      id: 'withdrawal_securities__currency',
      accessor: (row: TWithdrawalsData) =>
        row?.withdrawal_securities?.[0]?.currency,
      Header: 'Securities Currency',
    },
    {
      id: 'withdrawal_securities__security_type',
      accessor: (row: TWithdrawalsData) =>
        row?.withdrawal_securities?.[0]?.security_type,
      Header: 'Securities Security Type',
    },
    {
      id: 'withdrawal_securities__security_name',
      accessor: (row: TWithdrawalsData) =>
        row?.withdrawal_securities?.[0]?.security_name,
      Header: 'Securities Security Name',
    },
    {
      id: 'withdrawal_securities__isin_code',
      accessor: (row: TWithdrawalsData) =>
        row?.withdrawal_securities?.[0]?.isin_code,
      Header: 'Securities Isin Code',
    },
    {
      id: 'withdrawal_securities__exchange',
      accessor: (row: TWithdrawalsData) =>
        row?.withdrawal_securities?.[0]?.exchange,
      Header: 'Securities Exchange',
    },
    {
      id: 'withdrawal_securities__quantity',
      accessor: (row: TWithdrawalsData) =>
        row?.withdrawal_securities?.[0]?.quantity,
      Header: 'Securities Quantity',
    },
    {
      id: 'withdrawal_securities__price',
      accessor: (row: TWithdrawalsData) =>
        row?.withdrawal_securities?.[0]?.price,
      Header: 'Securities Price',
    },
    {
      id: 'withdrawal_securities__commission',
      accessor: (row: TWithdrawalsData) =>
        row?.withdrawal_securities?.[0]?.commission,
      Header: 'Securities Commission',
    },
    {
      id: 'withdrawal_securities__settlement_date',
      accessor: (row: TWithdrawalsData) =>
        row?.withdrawal_securities?.[0]?.settlement_date,
      Header: 'Securities Settlement Date',
    },
    {
      id: 'withdrawal_securities__trade_date',
      accessor: (row: TWithdrawalsData) =>
        row?.withdrawal_securities?.[0]?.trade_date,
      Header: 'Securities Trade Date',
    },
    {
      id: 'withdrawal_securities__custodian',
      accessor: (row: TWithdrawalsData) =>
        row?.withdrawal_securities?.[0]?.custodian,
      Header: 'Securities Custodian',
    },
    getIdAndHeader('sepa_swift', 'Sepa Swift'),
    getIdAndHeader('comply_radar_id', 'Comply Radar Id'),
    getIdAndHeader('deposit_group', 'Deposit Group'),
    getIdAndHeader('beneficiary_type', 'Beneficiary Type'),
    getIdAndHeader('country_of_residence', 'Country of Residence'),
    getIdAndHeader('country_of_citizenship', 'Country of Citizenship'),
    getIdAndHeader('crypto_address', 'Crypto address'),
    getIdAndHeader('crypto_transaction_id', 'Crypto transaction id'),
    getIdAndHeader('crypto_network', 'Crypto network'),
  ].map((item) => ({
    ...item,
    accessor: item.accessor || item.id,
    disableFilters: true,
  }));
};

export const fieldsParams = {
  columns:
    'username,client_id,account,ip,ip_country,datetime,status,responsible_title,changed,amount_currency,currency,eur_amount_repr,commission_amount,commission_currency,account_nav,confirm_type,legal_entity,assigned_to,type,is_withdrawn,is_crypto_withdrawn,all_funds,is_card_withdrawn,source,value_date,bank_country,bank_to_transfer,account_to_transfer,transfer_status,iban,account_number,swift,bank_name,exante_account,withdrawal_securities__currency,withdrawal_securities__security_type,withdrawal_securities__security_name,withdrawal_securities__isin_code,withdrawal_securities__exchange,withdrawal_securities__quantity,withdrawal_securities__price,withdrawal_securities__commission,withdrawal_securities__settlement_date,withdrawal_securities__trade_date,withdrawal_securities__custodian,sepa_swift,comply_radar_id,deposit_group,beneficiary_type,country_of_citizenship,country_of_residence,crypto_address,crypto_transaction_id,crypto_network',
};

export const WITHDRAWALS_DATE_FIELDS = [
  'field_datetime',
  'field_value_date',
  'field_changed',
  'field_withdrawal_securities__trade_date',
  'field_withdrawal_securities__settlement_date',
];
