import { ActionIconButtonStaticProps } from '~/modules/actionButtonsBlock/ActionButton/components';

import { ActionIconButtonEnum } from '../WithdrawalHeader.types';

export const staticIconButtonPropsMap: Record<
  ActionIconButtonEnum,
  ActionIconButtonStaticProps
> = {
  makeActiveBtn: {
    iconName: 'ReadyForActivationIcon',
    label: 'Make Active',
    iconColor: 'action',
  },
  exportToXMLBtn: {
    iconName: 'ExportIcon',
    label: 'Export To XML',
    iconColor: 'action',
  },
};
