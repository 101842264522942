import { isEmpty } from 'lodash';
import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormProvider } from 'react-hook-form';

import { TChildren } from 'types/TChildren';
import { useUpdateDepositInfoMutation } from '~/api';
import { TDepositInfo } from '~/api/deposits/deposits.types';
import { checkKeyDown } from '~/utils/checkKeyDown';

import { getClientDetailsFields } from '../components/DepositEditForm/DepositEditForm.constants';
import { getFields } from '../components/DepositEditForm/DepositEditForm.helpers';

import { getDefaultFormValues } from './helpers';
import { useGetFormMethod } from './useGetFormMethod';

type TDepositFormContext = {
  onSave: VoidFunction;
  isEdit: boolean;
  isFormDisabled: boolean;
  setIsEdit: Dispatch<SetStateAction<boolean>>;
  setIsFormDisabled: Dispatch<SetStateAction<boolean>>;
  formNotEdited: boolean;
  hiddenFields: string[];
  isFormLoading: boolean;
};

type TDepositFormProps = {
  deposit: TDepositInfo;
};

const initialState: TDepositFormContext = {
  onSave: () => {},
  isEdit: false,
  isFormDisabled: false,
  setIsEdit: () => {},
  setIsFormDisabled: () => {},
  formNotEdited: true,
  hiddenFields: [],
  isFormLoading: false,
};

export const DepositEditFormContext =
  createContext<TDepositFormContext>(initialState);

export const DepositEditFormProvider: FC<TDepositFormProps & TChildren> = ({
  children,
  deposit,
}) => {
  const [onUpdateDepositInfo, updateDepositState] =
    useUpdateDepositInfoMutation();

  const [isEdit, setIsEdit] = useState(initialState.isEdit);
  const [isFormDisabled, setIsFormDisabled] = useState(
    initialState.isFormDisabled,
  );

  const initDefault = [
    ...getFields({ deposit }),
    ...getClientDetailsFields(deposit),
  ];
  const defaultValues = getDefaultFormValues(deposit, initDefault);

  const { reset, handleSubmit, methods, formData, hiddenFields } =
    useGetFormMethod({ defaultValues, initDefault });

  const onSave = async () => {
    const res = await onUpdateDepositInfo({
      depositId: deposit.id,
      updateInfo: formData,
    });

    if (!('error' in res)) {
      setIsEdit(false);

      reset(getDefaultFormValues(res.data, initDefault));
    }
  };

  useEffect(() => {
    reset(defaultValues);
  }, [isEdit]);

  const value = useMemo(
    (): TDepositFormContext => ({
      onSave,
      isEdit,
      isFormDisabled,
      setIsFormDisabled,
      setIsEdit,
      formNotEdited: isEmpty(formData),
      hiddenFields: hiddenFields || [],
      isFormLoading: updateDepositState.isLoading,
    }),
    [
      onSave,
      formData,
      isEdit,
      hiddenFields,
      isFormDisabled,
      updateDepositState.isLoading,
    ],
  );

  return (
    <DepositEditFormContext.Provider value={value}>
      <FormProvider {...methods}>
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
        <form
          onSubmit={handleSubmit(onSave)}
          onKeyDown={(e) => checkKeyDown(e)}
        >
          {children}
        </form>
      </FormProvider>
    </DepositEditFormContext.Provider>
  );
};
