import { isArray, isDate } from 'lodash';
import type { ISortBy } from 'react-ui-kit-exante';

import {
  CREATION_DATE_FIELDS,
  LAST_LOGIN_DATE_FIELDS,
  BIRTH_DATE_FIELDS,
} from '~/pages/ComputedUsers/ComputedUsers.constants';
import type {
  ITransformerArguments,
  TParams,
} from '~/pages/ComputedUsers/ComputedUsers.types';

export function paramsTransformer({
  params,
  mapper,
}: ITransformerArguments): TParams {
  const paramsWithoutDate = Object.keys(params).filter(
    (item) => item !== 'reportDate',
  );
  return paramsWithoutDate.reduce((acc, curr) => {
    const mapperItem = mapper[curr];

    if (mapperItem) {
      const parsedValues = mapperItem.parser(params[curr]);

      return { ...acc, ...parsedValues };
    }
    return { ...acc, [curr]: params[curr] };
  }, {});
}

export function prepareSortingForApi(sortingArray: ISortBy[]) {
  return sortingArray.reduce((acc, curr) => {
    if (curr.desc === undefined) {
      return acc;
    }

    const direction = curr?.desc ? 'desc' : 'asc';

    return { order: direction, orderBy: curr.id };
  }, {});
}

const invalidDateTimeFormatLength = 4;

export const formatDateRange = <T>(dateRange: T): T | [string, string] => {
  const isInvalidFormat =
    isArray(dateRange) && dateRange.length === invalidDateTimeFormatLength;

  if (isInvalidFormat) {
    const [beginDate, beginTime, endDate, endTime] = dateRange;

    return [`${beginDate}, ${beginTime}`, `${endDate}, ${endTime}`];
  }

  return dateRange;
};

export function isValidDate(d: unknown) {
  return (
    d instanceof Date && !Number.isNaN(d) && d.toString() !== 'Invalid Date'
  );
}

export function convertDateToUTC(dateString: string | Date) {
  const date = new Date(dateString);

  if (!isValidDate(date)) {
    return new Date();
  }

  const utcField = {
    year: date.getFullYear(),
    month: date.getMonth(),
    date: date.getDate(),
    hours: date.getHours(),
    minutes: date.getMinutes(),
  };

  return new Date(
    Date.UTC(
      utcField.year,
      utcField.month,
      utcField.date,
      utcField.hours,
      utcField.minutes,
    ),
  );
}

export function convertDateToISO(value: unknown): string | null {
  if (value && (typeof value === 'string' || isDate(value))) {
    return convertDateToUTC(value).toISOString();
  }

  return null;
}

type TConvertedToISO<T extends string> = Record<
  T,
  ReturnType<typeof convertDateToISO>
>;

export function prepareDateRangeStringForAPI<T extends string>(
  fields: T[],
  values: (string | null)[],
) {
  return fields.reduce((acc, curr, index) => {
    const currentValue = values[index];

    if (!currentValue) {
      return acc;
    }

    return {
      ...acc,
      [curr]: convertDateToISO(currentValue),
    };
  }, {} as TConvertedToISO<T>);
}

export const createEmptyObject = () => ({});

export function createStartDate(date: string | Date, utc = false) {
  const start = new Date(date);

  if (utc) {
    start.setUTCHours(0, 0, 0, 0);
  } else {
    start.setHours(0, 0, 0, 0);
  }

  return start;
}

export function createEndDate(date: string | Date, utc = false) {
  const end = new Date(date);

  if (utc) {
    end.setUTCHours(23, 59, 59, 999);
  } else {
    end.setHours(23, 59, 59, 999);
  }

  return end;
}

export function createStringDateWithoutTimezone(date: string | Date) {
  return convertDateToUTC(date).toISOString().slice(0, 16);
}

export function setStartEndForDateRangeString(dates?: [string?, string?]) {
  if (!dates) {
    return [null, null];
  }

  const [minDate, maxDate] = dates;

  return [
    minDate ? createStringDateWithoutTimezone(createStartDate(minDate)) : null,
    maxDate ? createStringDateWithoutTimezone(createEndDate(maxDate)) : null,
  ];
}

export const USERS_PARAMS_TRANSFORMER = {
  sorting: {
    parser: (sorting: ISortBy[]) => prepareSortingForApi(sorting),
  },
  creationDate: {
    parser: (values?: [string, string]) => {
      const formattedDateRange = formatDateRange(values);

      return prepareDateRangeStringForAPI(
        CREATION_DATE_FIELDS,
        setStartEndForDateRangeString(formattedDateRange),
      );
    },
  },
  lastLoginTime: {
    parser: (values?: [string, string]) => {
      return prepareDateRangeStringForAPI(
        LAST_LOGIN_DATE_FIELDS,
        setStartEndForDateRangeString(values),
      );
    },
  },
  birthDate: {
    parser: (values?: [string, string]) => {
      return prepareDateRangeStringForAPI(
        BIRTH_DATE_FIELDS,
        setStartEndForDateRangeString(values),
      );
    },
  },
  page: {
    parser: createEmptyObject,
  },
};

export const sortAlphabetically = (arr: string[] = []) => {
  return [...arr].sort((a, b) => a.localeCompare(b));
};

export function transformVariantsToSelectOptions(
  variants: string,
  options: Partial<any> = {},
) {
  const { shouldSortAlphabetically = false } = options;
  const choices = variants.split(',');

  const items: string[] = shouldSortAlphabetically
    ? sortAlphabetically(choices.map((choice) => String(choice)))
    : choices.map((t) => String(t));

  return items.map((item) => {
    return {
      label: item,
      value: item,
    };
  });
}

export const getApplicationIds = (
  id?: number | null,
  crmId?: number | null,
) => {
  if (id && crmId) {
    return `${id}_${crmId}`;
  }
  if (id) {
    return `${id}_`;
  }
  return crmId;
};
