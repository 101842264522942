import { RecordsTable } from '~/pages/ApplicationEntry/tabs/FinancialTab/DepositsSubTab/RecordsTable';
import {
  FinancialSettingsTransactions,
  AccountsSegregation,
} from '~/pages/ApplicationEntry/tabs/FinancialTab/FinancialSettingsSubTab/components';
import { WithdrawalsTable } from '~/pages/ApplicationEntry/tabs/FinancialTab/WithdrawalsSubTab/components/WithdrawalsTable';
import { CommentsSection } from '~/pages/ApplicationEntry/tabs/InfoTab/components/CommentsSection/CommentsSection';
import { LinkedApplication } from '~/pages/ApplicationEntry/tabs/InfoTab/components/LinkedApplication';
import { NextCommunicationDate } from '~/pages/ApplicationEntry/tabs/InfoTab/components/NextCommunicationDate';
import { PersonInfoBlocks } from '~/pages/ApplicationEntry/tabs/OnboardingTab/AffiliatedTab/components/PersonInfoBlocks';
import { RequestTable } from '~/pages/ApplicationEntry/tabs/OnboardingTab/MonitoringTab/RequestTable';
import { ClientForm } from '~/pages/ApplicationEntry/tabs/OnboardingTab/OnboardingSettingsTab/components/ClientForm';
import { NameForm } from '~/pages/ApplicationEntry/tabs/OnboardingTab/OnboardingSettingsTab/components/NameForm';
import { OnboardingLog } from '~/pages/ApplicationEntry/tabs/OnboardingTab/OnboardingSettingsTab/components/OnboardingLog';
import { RequestStatusAction } from '~/pages/ApplicationEntry/tabs/OnboardingTab/OnboardingSettingsTab/components/RequestStatusAction';
import { UploadedDocuments } from '~/pages/ApplicationEntry/tabs/OnboardingTab/OnboardingSettingsTab/components/UploadedDocuments';
import { ValidationAndCategorization } from '~/pages/ApplicationEntry/tabs/OnboardingTab/OnboardingSettingsTab/components/ValidationAndCategorization';
import { RiskScoringWrapper } from '~/pages/ApplicationEntry/tabs/OnboardingTab/RiskScoringTab/components/RiskScoringWrapper';
import { AddNewTag } from '~/pages/ApplicationEntry/tabs/QualifiedLeadTab/components/AddNewTag';

// hardcoded sections in application card
export const mapSectionKeyToStaticComponent = (
  title?: string,
): Record<string, JSX.Element> => ({
  // Info tab sections
  'communication-date-section': <NextCommunicationDate />,
  'app-comments-section': <CommentsSection />,
  'linked-apps-section': <LinkedApplication />,

  // Qualified lead tab sections
  'add-new-tag-section': <AddNewTag />,

  // Onboarding lead tab sections
  'name-section': <NameForm />,
  'onboarding-log-section': <OnboardingLog />,
  'client-info-section': <ClientForm />,
  'uploaded-docs-section': <UploadedDocuments dynamicTitle={title} />,
  'onboarding-questions-section': <ValidationAndCategorization />,
  'affiliated-section': <PersonInfoBlocks />,
  'risk-scoring-table-section': <RiskScoringWrapper />,
  'monitoring-section': <RequestTable />,

  // Financial tab sections
  'transaction-finance-section': <FinancialSettingsTransactions />,
  'accounts-segregation-section': <AccountsSegregation />,
  'deposits-section': <RecordsTable />,
  'withdrawals-section': <WithdrawalsTable />,
});

export const mapSectionKeyToAdditionalComponent: Record<string, JSX.Element> = {
  'accuity-check-section': <RequestStatusAction action="check_accuity" />,
  'sumsub-section': <RequestStatusAction action="check_aml" />,
};

export const HIDDEN_TITLES = [
  'Uploaded documents',
  'Affiliated section',
  'Exchange subscription categories',
  'Name section local',
  'Transactions',
  'Risk scoring table section',
  'Monitoring section',
  'Deposits section',
];

export const SECTIONS_WITH_BIG_GAP = ['risk_scoring_initial'];
