import { yupResolver } from '@hookform/resolvers/yup';
import { useState, FC, MouseEvent } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { IconButton, Loader } from 'react-ui-kit-exante';

import {
  TSection,
  TTableSectionColumn,
} from '~/api/applications/applications.types';
import { ActionWithConfirmationWithSelect } from '~/components/ConfirmationComponents/ActionWithConfirmationWithSelect';
import { mapDynamicTypeToFieldType } from '~/constants/common';
import { TDefaultFormValues } from '~/types/form';
import { getFormField } from '~/utils/getFormField';

import { getValidationScheme } from './AddRowForm.constants';
import { StyledAddRowFormContainer } from './AddRowForm.styled';

export type AddRowFormProps = {
  section: TSection;
  onAdd: (formState: Record<string, unknown>) => void;
  disabled?: boolean;
  fields?: TTableSectionColumn[];
  isLoading?: boolean;
};

export const AddRowForm: FC<AddRowFormProps> = ({
  section,
  onAdd,
  disabled,
  fields,
  isLoading,
}) => {
  const [isOpenAddForm, setIsOpenAddForm] = useState(false);
  const [addFormAnchorEl, setAddFormAnchorEl] = useState<null | HTMLElement>(
    null,
  );

  const normalizedFields = fields?.filter((i) => i.id !== 'actions');
  const defaultValues = normalizedFields?.reduce((acc, curr) => {
    return {
      ...acc,
      [curr.id]: '',
    };
  }, {});

  const methods = useForm<TDefaultFormValues>({
    defaultValues,
    resolver: yupResolver(getValidationScheme(fields)),
  });

  const { watch, handleSubmit, getValues, reset } = methods;

  watch();

  const openAddForm = (event: MouseEvent<HTMLElement>) => {
    setIsOpenAddForm(true);
    setAddFormAnchorEl(event.currentTarget);
  };

  const onCloseAddForm = () => {
    setIsOpenAddForm(false);
    reset();
  };

  const onConfirm = () => {
    onAdd(getValues());
    setIsOpenAddForm(false);
    setAddFormAnchorEl(null);
  };

  const formBody = () => {
    return (
      <StyledAddRowFormContainer className="AddRowFormContainer">
        {normalizedFields?.map((field) => {
          return getFormField({
            name: String(field.id),
            type: mapDynamicTypeToFieldType[field.type],
            options: field?.choices?.split(',').map((i) => ({
              value: i,
              label: i,
            })),
            label: field.title,
            size: 'medium',
          });
        })}
      </StyledAddRowFormContainer>
    );
  };

  return (
    <FormProvider {...methods}>
      <ActionWithConfirmationWithSelect
        key="addRowForm"
        open={isOpenAddForm}
        anchorEl={addFormAnchorEl}
        onClose={onCloseAddForm}
        title={`Add row to table "${section.title}"`}
        content={formBody()}
        confirmText="Add"
        handleConfirm={handleSubmit(onConfirm)}
        handleClose={onCloseAddForm}
        // workaround for calendar popup
        paperStyle={{
          overflowY: 'visible',
          overflowX: 'visible',
        }}
        disabledConfirm={isLoading}
      >
        {!isLoading && (
          <IconButton
            key="add"
            iconName="AddIcon"
            label="Add"
            disabled={disabled}
            iconColor="action"
            onClick={openAddForm}
          />
        )}
        {isLoading && <Loader />}
      </ActionWithConfirmationWithSelect>
    </FormProvider>
  );
};
