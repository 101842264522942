import { ICellValue, IconButton } from 'react-ui-kit-exante';

import { TTableSectionRow } from '~/api/applications/applications.types';
import { ActionWithConfirmation } from '~/components/ConfirmationComponents/ActionWithConfirmation';
import { TFooter } from '~/types/table';

import { TGetColumnsParams } from './SectionTableBody.types';

export const getColumns = ({
  columns = [],
  tableName,
  rows = [],
  deleteAction,
  actionProcessing,
}: TGetColumnsParams) => {
  const defaultColumns =
    columns.map(({ id, title, type }) => {
      return {
        id: String(id),
        accessor: String(id),
        Header: title,
        formatting: type === 'date' ? 'dateUTC' : undefined,
        ...(id === 'actions'
          ? {
              maxWidth: 80,
              align: 'right',
              Cell: ({ row: { original } }: ICellValue<TTableSectionRow>) => {
                const onDelete = () => {
                  if (!actionProcessing) {
                    deleteAction?.(original.row_id);
                  }
                };
                return (
                  <ActionWithConfirmation
                    withCloseAfterConfirmation
                    placement="bottom"
                    cancelButtonNameKey="Cancel"
                    confirmButtonNameKey="Confirm"
                    onConfirm={onDelete}
                    title="Do you want to delete row?"
                    disabled={actionProcessing}
                  >
                    <IconButton
                      iconName="DeleteIcon"
                      iconSize={18}
                      disabled={actionProcessing}
                    />
                  </ActionWithConfirmation>
                );
              },
            }
          : {}),
      };
    }) || [];

  if (tableName === 'inflow_outflow_table_section') {
    const isShowTotalFooter = rows.length > 1;
    let depositWithdrawalId: string;
    let amountId: string;

    columns.forEach((column) => {
      if (column.title === 'Expected deposits/ withdrawals') {
        depositWithdrawalId = column.id.toString();
      }
      if (column.title === 'Amount (€)') {
        amountId = column.id.toString();
      }
    });

    return defaultColumns.map((column) => ({
      ...column,
      ...(column.id === amountId && isShowTotalFooter
        ? {
            Footer: ({ data }: TFooter<TTableSectionRow>) => {
              const total = data.reduce((acc, curr) => {
                const amount = curr?.[amountId];
                const type = curr?.[depositWithdrawalId];
                if (type === 'Deposits' && amount) {
                  return acc + parseFloat(amount);
                }
                if (type === 'Withdrawals' && amount) {
                  return acc - parseFloat(amount);
                }
                return acc;
              }, 0);

              return <div>{total}</div>;
            },
          }
        : {}),
      ...(column.id === depositWithdrawalId && isShowTotalFooter
        ? {
            Footer: () => {
              return <div>Total</div>;
            },
          }
        : {}),
    }));
  }

  return defaultColumns;
};
