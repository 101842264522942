import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import {
  useWithdrawalActionsMutation,
  useWithdrawalConvertMutation,
} from '~/api';
import { ActionWithConfirmationProps } from '~/modules/actionButtonsBlock/ActionButton';
import { TParams } from '~/router/router.types';

import { getConfirmMessages } from '../../WithdrawalForm/WithdrawalForm.helpers';
import { ActionButtonWithConfirmationEnum } from '../WithdrawalHeader.types';
import {
  staticButtonWithConfirmationPropsMap,
  WithdrawalTypesOfActions,
} from '../constants';

export const useGetButtonWithConfirmationProps = (
  wrType: string,
  wrCurrency: string,
  isLoadingActions: boolean,
  loadingActionsHandler: (isLoading: boolean) => void,
) => {
  const { id: withdrawalId } = useParams<TParams>();

  const [onWithdrawalConvert, { isLoading: isWithdrawalConvertLoading }] =
    useWithdrawalConvertMutation();
  const [onWithdrawalAction, { isLoading: isWithdrawalActionsLoading }] =
    useWithdrawalActionsMutation();

  useEffect(() => {
    loadingActionsHandler(
      isWithdrawalConvertLoading || isWithdrawalActionsLoading,
    );
  }, [
    isWithdrawalConvertLoading,
    isWithdrawalActionsLoading,
    loadingActionsHandler,
  ]);

  const confirmMessages = getConfirmMessages(wrType, wrCurrency);

  const typeSendFunds = useMemo(() => {
    if (wrType === 'Crypto') {
      return WithdrawalTypesOfActions.WithdrawCrypto;
    }
    if (wrType === 'Card') {
      return WithdrawalTypesOfActions.WithdrawCard;
    }

    return WithdrawalTypesOfActions.WithdrawWire;
  }, [wrType]);

  const handleConvert = () => {
    if (withdrawalId) {
      onWithdrawalConvert({ id: withdrawalId });
    }
  };

  const handleWithdrawalAction = (type: WithdrawalTypesOfActions) => {
    if (withdrawalId) {
      onWithdrawalAction({
        id: withdrawalId,
        type,
      });
    }
  };

  const onClickHandlerMap = {
    [ActionButtonWithConfirmationEnum.ConvertBtn]: handleConvert,
    [ActionButtonWithConfirmationEnum.RollbackBtn]: () =>
      handleWithdrawalAction(WithdrawalTypesOfActions.Rollback),
    [ActionButtonWithConfirmationEnum.SendFundsToClientBtn]: () =>
      handleWithdrawalAction(typeSendFunds),
    [ActionButtonWithConfirmationEnum.WithdrawBtn]: () =>
      handleWithdrawalAction(WithdrawalTypesOfActions.Withdraw),
  };

  const getButtonWithConfirmationProps = (
    buttonKey: ActionButtonWithConfirmationEnum,
  ): ActionWithConfirmationProps['props'] => {
    const staticProps = staticButtonWithConfirmationPropsMap[buttonKey];
    const contentStaticProps = confirmMessages[buttonKey];

    return {
      isLoadingActions,
      onConfirm: onClickHandlerMap[buttonKey],
      contentStaticProps,
      ...staticProps,
    };
  };

  return {
    getButtonWithConfirmationProps,
  };
};
