import { ICellValue } from 'react-ui-kit-exante';

import {
  TApplicationRequest,
  TFile,
  TRequest,
} from '~/api/requests/requests.types';
import { LinksCell, StatusCell } from '~/components/Cells';
import { TLinksCellProps } from '~/components/Cells/LinksCell/LinksCell.types';
import { DEFAULT_EMPTY_PLACEHOLDER } from '~/constants/common';
import { RequestsActionCell } from '~/pages/Requests/components/RequestsActionCell';
import { getTableId } from '~/utils/table';
import { formatCellToSomeProp } from '~/utils/table/cellFormatters';

export const REQUESTS_TABLE_ID = getTableId('app-requests');

export const COLUMNS = [
  { Header: 'Id', accessor: 'id', disableSortBy: true, width: '80' },
  {
    Header: 'Date',
    accessor: 'created',
    formatting: 'date',
    disableSortBy: true,
    width: '120',
  },
  {
    Header: 'Type of request',
    accessor: 'type',
    disableSortBy: true,
    Cell: ({ value }: ICellValue<TApplicationRequest>) => (
      <div>{value.text}</div>
    ),
  },
  {
    Header: 'Received from',
    accessor: 'received_from',
    disableSortBy: true,
    Cell: formatCellToSomeProp('text'),
  },
  {
    Header: 'Assigned to',
    accessor: 'assigned_to',
    disableSortBy: true,
    Cell: formatCellToSomeProp('text'),
  },
  { Header: 'Legal Entity', accessor: 'legal_entities', disableSortBy: true },
  {
    Header: 'Client ID',
    accessor: 'clients_id',
    disableSortBy: true,
  },
  {
    Header: 'Deadline',
    accessor: 'deadline',
    formatting: 'date',
    disableSortBy: true,
    width: '120',
  },
  { Header: 'Summary', accessor: 'summary', disableSortBy: true },
  {
    Header: 'State',
    accessor: 'state',
    disableSortBy: true,
    Cell: ({ value }: ICellValue<TApplicationRequest>) => (
      <StatusCell status={value} type="request" />
    ),
  },
  {
    Header: 'Close only mode',
    accessor: 'close_only_mode',
    disableSortBy: true,
    width: '80',
  },
  {
    Header: 'Non-compliance fee',
    accessor: 'non_compliance_fee',
    disableSortBy: true,
    width: '80',
  },
  {
    Header: 'Files',
    accessor: 'files',
    disableSortBy: true,
    Cell: ({ value }: ICellValue<TRequest>) => {
      if (value?.length) {
        const links: TLinksCellProps['links'] = (value as TFile[]).map(
          (file) => ({
            id: file.id,
            name: file.file_name,
            url: file.url,
          }),
        );

        return <LinksCell links={links} />;
      }
      return DEFAULT_EMPTY_PLACEHOLDER;
    },
  },
  {
    id: 'action',
    Header: 'Actions',
    disableSortBy: true,
    width: 120,
    align: 'right',
    Cell: ({ row: { original } }: ICellValue<TRequest>) => (
      <RequestsActionCell recordId={original.id} />
    ),
  },
];
