import { ActionButtonWithConfirmationStaticProps } from '~/modules/actionButtonsBlock/ActionButton/components';

import { ActionButtonWithConfirmationEnum } from '../WithdrawalHeader.types';

export const staticButtonWithConfirmationPropsMap: Record<
  ActionButtonWithConfirmationEnum,
  ActionButtonWithConfirmationStaticProps
> = {
  withdrawBtn: {
    placement: 'bottom',
    cancelButtonNameKey: 'Cancel',
    confirmButtonNameKey: 'Confirm Withdrawal',
    childrenStaticProps: {
      iconName: 'WithdrawFromAccountIcon',
      label: 'Withdraw From Account',
      iconColor: 'action',
    },
  },
  sendFundsToClientBtn: {
    placement: 'bottom',
    cancelButtonNameKey: 'Cancel',
    confirmButtonNameKey: 'Send funds to client',
    childrenStaticProps: {
      iconName: 'CircleSendIcon',
      iconColor: 'action',
      label: 'Send funds to client',
    },
  },
  convertBtn: {
    placement: 'bottom',
    cancelButtonNameKey: 'Cancel',
    confirmButtonNameKey: 'Convert',
    childrenStaticProps: {
      iconName: 'ArrowsClockwiseIcon',
      iconColor: 'action',
      label: 'Convert',
    },
  },
  rollbackBtn: {
    placement: 'bottom',
    cancelButtonNameKey: 'Cancel',
    confirmButtonNameKey: 'Rollback Withdrawal Confirm',
    childrenStaticProps: {
      iconName: 'RollbackIcon',
      label: 'Rollback Withdrawal Confirm',
      iconColor: 'radical',
    },
  },
};
