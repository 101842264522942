import type {
  ActionButtonWithConfirmationWithSelectProps,
  ActionButtonWithConfirmationProps,
  ActionIconButtonProps,
} from './components';

export enum ActionButtonType {
  IconBtn = 'iconBtn',
  WithConfirmationBtn = 'withConfirmationBtn',
  WithConfirmationWithSelect = 'withConfirmationWithSelect',
}

export type IconBtnProps = {
  type: ActionButtonType;
  props: ActionIconButtonProps;
};
export type ActionWithConfirmationProps = {
  type: ActionButtonType;
  props: ActionButtonWithConfirmationProps;
};
export type ActionWithConfirmationWithSelectProps = {
  type: ActionButtonType;
  props: ActionButtonWithConfirmationWithSelectProps;
};

export type ActionButtonProps =
  | IconBtnProps
  | ActionWithConfirmationProps
  | ActionWithConfirmationWithSelectProps;

export const isPropsForIconBtn = (
  props: ActionButtonProps,
): props is IconBtnProps => props.type === ActionButtonType.IconBtn;

export const isPropsForActionWithConfirmationBtn = (
  props: ActionButtonProps,
): props is ActionWithConfirmationProps =>
  props.type === ActionButtonType.WithConfirmationBtn;

export const isPropsForActionWithConfirmationWithSelectBtn = (
  props: ActionButtonProps,
): props is ActionWithConfirmationWithSelectProps =>
  props.type === ActionButtonType.WithConfirmationWithSelect;
