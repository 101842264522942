import { FC, useCallback, useContext, useEffect, useMemo } from 'react';
import {
  IPaginationParams,
  IconButton,
  Table,
  calculateCountOfPages,
  useTableData,
} from 'react-ui-kit-exante';

import {
  useLazyGetPartnersReferralsQuery,
  useLazyExportReferralsToEmailQuery,
} from '~/api';
import { TParnersReferralsTable } from '~/api/partners/partners.types';
import { RefreshButton } from '~/components/RefreshButton';
import { useCallbackTriggerHandle, useLogHandleTime } from '~/hooks';
import { ApplicationContext } from '~/pages/ApplicationEntry/contexts/ApplicationContext';
import { getApplicationIds } from '~/pages/ComputedUsers/ComputedUsers.utils';
import { PATHS } from '~/router';
import { getDefaultPagination } from '~/utils/table';

import { COLUMNS, TABLE_ID } from './ReferralsTab.constants';

export const ReferralsTab: FC = () => {
  const { setStartHandleTime, logHandleTime } = useLogHandleTime(
    `application-entry-${TABLE_ID}`,
  );
  const { application } = useContext(ApplicationContext);
  const [fetchPartnersReferrals, { isLoading: isLoadingReferrals, isSuccess }] =
    useLazyGetPartnersReferralsQuery();
  const [exportReferralsToEmail, { isLoading: isLoadingExport }] =
    useLazyExportReferralsToEmailQuery();

  const username = application?.application.username;
  const applicationId = application?.application.id;

  useEffect(() => {
    if (isLoadingReferrals) {
      setStartHandleTime();
    }
  }, [isLoadingReferrals, setStartHandleTime]);

  const getPartnersReferral = useCallback(
    async ({ params }: { params: IPaginationParams }) => {
      const response = await fetchPartnersReferrals({
        email: username,
        iDisplayLength: params?.limit,
        iDisplayStart: params?.skip,
      });

      return response.data;
    },
    [fetchPartnersReferrals, setStartHandleTime],
  );

  const tableDataArgs = useMemo(
    () => ({
      tableId: TABLE_ID,
      data: {
        onFetch: getPartnersReferral,
      },
      pagination: {
        getDefaultPagination,
      },
    }),
    [getPartnersReferral],
  );

  const {
    data,
    limit,
    setLimit,
    setPage,
    page,
    skip,
    isLoading,
    resetFilters,
    filters,
    fetchData: refetch,
  } = useTableData(tableDataArgs);

  const total = data?.iTotalRecords ?? 0;
  const referrals = data?.results;

  const pageCount = useMemo(
    () => calculateCountOfPages(total, limit),
    [limit, total],
  );

  const serverPaginationProps = useMemo(
    () => ({
      pageSize: limit,
      skip,
      setPage,
      setPageSize: setLimit,
      pageIndex: page,
      total,
      pageCount,
    }),
    [skip, limit, page, pageCount, setLimit, setPage, total],
  );

  const filterProps = useMemo(
    () => ({
      removeAllFilters: resetFilters,
      filters,
    }),
    [filters, resetFilters],
  );

  const handleRowClick = ({ authdb_id, crm_id }: TParnersReferralsTable) => {
    if (authdb_id || crm_id) {
      window.open(
        `${PATHS.USERS}/${getApplicationIds(authdb_id, crm_id)}/info`,
      );
    }
  };

  const handleOnExport = () => {
    if (applicationId) {
      exportReferralsToEmail({ applicationId });
    }
  };

  useCallbackTriggerHandle({
    cb: logHandleTime,
    dataTrigger: referrals,
    processTrigger: !isLoading && isSuccess,
  });

  const additionalActions = [
    {
      key: 'refresh',
      component: (
        <RefreshButton
          onRefresh={refetch}
          disabled={isLoading}
          iconColor="secondary"
          title="Refresh table data"
        />
      ),
    },
    {
      key: 'export',
      component: (
        <IconButton
          onClick={handleOnExport}
          iconSize={24}
          disabled={isLoading || isLoadingExport}
          iconName="ExportIcon"
          iconColor="secondary"
          title="Export"
          data-test-id="referrals--export-button"
        />
      ),
    },
  ];

  return (
    <Table
      isPinnedHeader
      columns={COLUMNS}
      isLoading={isLoading}
      filtersExpanded
      isFlexLayout
      manualSortBy
      filteringProps={filterProps}
      data={referrals || []}
      tableId={TABLE_ID}
      hasPagination
      showTableInfo
      saveColumnOrder
      isHiddenColumnSelect
      saveViewParamsAfterLeave
      additionalActions={additionalActions}
      handleRowClick={handleRowClick}
      serverPaginationProps={serverPaginationProps}
    />
  );
};
