import { AuditLogsTable } from '../ActivityTab/components/AuditLogsTable';
import { NotificationsTable } from '../ActivityTab/components/NotificationsTable';
import { TradeTable } from '../ActivityTab/components/TradeTable';
import { TransactionsTable } from '../ActivityTab/components/TransactionsTable';
import { InfoTab } from '../InfoTab/InfoTab';
import { AffiliatedTab } from '../OnboardingTab/AffiliatedTab';
import { OnboardingSettingsTab } from '../OnboardingTab/OnboardingSettingsTab';
import { QualifiedLeadTab } from '../QualifiedLeadTab';
import { AccountPermissionsContainer } from '../UserSettingsTab/components/AccountPermissions';
import { NotificationsEditor } from '../UserSettingsTab/components/NotificationsEditor';
import { PasswordManagementContainer } from '../UserSettingsTab/components/PasswordManagement';

export const mapTabsComponents = {
  info: <InfoTab />,

  onboarding_settings_subtab: <OnboardingSettingsTab />,
  'affiliated-subtab': <AffiliatedTab />,
  // 'monitoring-subtab': '', // todo change
  // 'risk_scoring-subtab': '', // todo change

  'qualified-lead': <QualifiedLeadTab />,

  'account-permissions-subtab': <AccountPermissionsContainer />,
  'password-management-subtab': <PasswordManagementContainer />,
  'notifications-subtab': <NotificationsEditor />,

  'trades-subtab': <TradeTable />,
  'transactions-subtab': <TransactionsTable />,
  'notifications-activity-subtab': <NotificationsTable />,
  'audit-logs-subtab': <AuditLogsTable />,
};
