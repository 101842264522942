import { memo, useCallback, useEffect, useState } from 'react';
import { IconButton } from 'react-ui-kit-exante';

import { ActionWithConfirmation } from '~/components/ConfirmationComponents/ActionWithConfirmation';

import { StyledCommentInput } from './ActionButtonWithConfirmation.styled';
import { ActionButtonWithConfirmationProps } from './ActionButtonWithConfirmation.types';

const ON_HOLD_COMMENT_ERROR = 'Comment is required field';

const ActionButtonWithConfirmation = (
  props: ActionButtonWithConfirmationProps,
) => {
  const {
    onConfirm,
    onClose,
    withError = false,
    defaultTextValue,
    isLoadingActions,
    childrenStaticProps: childrenProps,
    contentStaticProps: contentProps,
    ...otherProps
  } = props;

  const [textValue, setTextValue] = useState<string | undefined>();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const withContentInput = typeof contentProps === 'object';

  const handleTextValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (withError && !e.target.value) {
        setErrorMessage(ON_HOLD_COMMENT_ERROR);
      }

      setTextValue(e.target.value);
    },
    [withError],
  );

  const onCloseHandler = useCallback(() => {
    setTextValue(undefined);
    setErrorMessage(undefined);

    if (onClose) {
      onClose();
    }
  }, [onClose]);

  const onConfirmHandler = useCallback(() => {
    if (withContentInput) {
      if (textValue) {
        onConfirm(textValue);
        onCloseHandler();
      }
    } else {
      onConfirm('');
      onCloseHandler();
    }
  }, [onCloseHandler, onConfirm, textValue, withContentInput]);

  useEffect(() => {
    if (defaultTextValue) {
      setTextValue(defaultTextValue);
    }
  }, [defaultTextValue]);

  const content = withContentInput ? (
    <StyledCommentInput
      {...contentProps}
      error={!!errorMessage}
      message={errorMessage}
      value={textValue}
      onChange={handleTextValue}
    />
  ) : (
    contentProps
  );

  return (
    <ActionWithConfirmation
      withCloseAfterConfirmation
      disabledConfirmBtn={!textValue && withContentInput}
      onConfirm={onConfirmHandler}
      closeHandler={onCloseHandler}
      content={content}
      {...otherProps}
    >
      <IconButton
        iconSize={32}
        disabled={isLoadingActions}
        {...childrenProps}
      />
    </ActionWithConfirmation>
  );
};

export const ActionButtonWithConfirmationMemoized = memo(
  ActionButtonWithConfirmation,
);
