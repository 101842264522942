import { FC, useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  ExportTableParams,
  Table,
  calculateCountOfPages,
  useTableData,
} from 'react-ui-kit-exante';

import { useLazyGetAuditLogsQuery } from '~/api';
import { TAuditLog } from '~/api/auditLogs/auditLogs.types';
import { RefreshButton } from '~/components/RefreshButton';
import { useCallbackTriggerHandle, useLogHandleTime } from '~/hooks';
import { TParams } from '~/router/router.types';
import { getDefaultPagination } from '~/utils/table';

import {
  DEFAULT_SORTING,
  DISPLAYED_COLUMNS_KEYS,
  EMPTY_AUDIT_LOGS,
  getDefaultFilters,
  TABLE_ID,
  useColumns,
} from './AuditLogs.constants';
import { prepareAuditLogParams } from './AuditLogs.helpers';
import { TFetchParams } from './AuditLogs.types';
import { useAccountsByUser } from './useAccountsByUser';

export const AuditLogsTable: FC = () => {
  const { setStartHandleTime, logHandleTime } = useLogHandleTime(
    `application-entry-${TABLE_ID}`,
  );
  const { id } = useParams<TParams>();

  const [
    fetchAuditLogs,
    { isLoading: isGetAuditLogsLoading, isSuccess: isGetAuditLogsSuccess },
  ] = useLazyGetAuditLogsQuery();

  const { accountsList, accountListLoading } = useAccountsByUser(String(id));

  useEffect(() => {
    if (isGetAuditLogsLoading) {
      setStartHandleTime();
    }
  }, [isGetAuditLogsLoading, setStartHandleTime]);

  const getAuditLogs = useCallback(
    async ({ params }: { params: TFetchParams }) => {
      if (!id || accountListLoading) {
        return EMPTY_AUDIT_LOGS;
      }
      const preparedParams = prepareAuditLogParams(id, accountsList, params);

      const response = await fetchAuditLogs(preparedParams);

      return response.data;
    },
    [id, accountListLoading, accountsList, fetchAuditLogs],
  );

  const exportTableParams = {
    type: 'server',
    onFetch: async (params: TFetchParams) => {
      if (!id || accountListLoading) {
        return EMPTY_AUDIT_LOGS;
      }

      const preparedParams = prepareAuditLogParams(id, accountsList, params);

      const response = await fetchAuditLogs({ ...preparedParams, offset: 0 });

      return response?.data?.logs;
    },
  } as ExportTableParams<TAuditLog>;

  const tableDataArgs = useMemo(
    () => ({
      tableId: TABLE_ID,
      data: {
        onFetch: getAuditLogs,
      },
      filters: {
        getDefaultFilters,
      },
      pagination: {
        getDefaultPagination,
      },
    }),
    [getAuditLogs],
  );

  const {
    data,
    limit,
    setLimit,
    setPage,
    page,
    skip,
    isLoading,
    resetFilters,
    filters,
    setSorting,
    setFilter,
    removeFilter,
    fetchData: refetch,
  } = useTableData(tableDataArgs);

  const total = data?.total ?? 0;

  const pageCount = useMemo(
    () => calculateCountOfPages(total, limit),
    [limit, total],
  );

  const serverPaginationProps = useMemo(
    () => ({
      pageSize: limit,
      skip,
      setPage,
      setPageSize: setLimit,
      pageIndex: page,
      total,
      pageCount,
    }),
    [skip, limit, page, pageCount, setLimit, setPage, total],
  );

  const filterProps = useMemo(
    () => ({
      removeAllFilters: resetFilters,
      filters,
    }),
    [filters, resetFilters],
  );

  const columns = useColumns({
    onFilter: setFilter,
    onRemove: removeFilter,
  });

  useCallbackTriggerHandle({
    cb: logHandleTime,
    dataTrigger: data?.logs,
    processTrigger: !isGetAuditLogsLoading && isGetAuditLogsSuccess,
  });

  const additionalActions = [
    {
      key: 'refresh',
      component: (
        <RefreshButton
          onRefresh={refetch}
          disabled={isLoading}
          iconColor="secondary"
          title="Refresh table data"
        />
      ),
    },
  ];

  return (
    <Table<TAuditLog>
      isPinnedHeader
      className="AuditLogsTable"
      columns={columns}
      exportTableParams={exportTableParams}
      displayedColumnKeys={DISPLAYED_COLUMNS_KEYS}
      isLoading={isLoading}
      filtersExpanded
      isFlexLayout
      manualSortBy
      hasFilters
      filteringProps={filterProps}
      data={data?.logs || []}
      tableId={TABLE_ID}
      hasPagination
      showTableInfo
      saveColumnOrder
      serverPaginationProps={serverPaginationProps}
      isHiddenColumnSelect
      saveViewParamsAfterLeave
      onSort={setSorting}
      defaultSortBy={DEFAULT_SORTING}
      additionalActions={additionalActions}
    />
  );
};
