import { styled } from 'react-ui-kit-exante';

export const StyledScoringTablesTitle = styled('div')`
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  margin-left: 24px;
  font-family: ${({ theme }) => theme?.font?.header};
`;

export const StyledScoringRiskLevel = styled('div')`
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  margin: 16px 24px;
  font-family: ${({ theme }) => theme?.font?.header};
`;

export const StyledScoringRiskTableFooter = styled('div')`
  display: flex;
  justify-content: space-between;
  padding: 8px 24px;
  background-color: ${({ theme }) => theme?.color.bg.basic};

  > div {
    font-size: 13px;
    font-weight: 500;
    color: ${({ theme }) => theme?.color?.typo?.primary};
  }
`;
