import { TCreateDepositBody } from '~/api/deposits/deposits.types';
import { FieldTypes } from '~/constants/common';
import { formatDateTime } from '~/utils/dates/formatDate';
import { mapChoiceOptionToText } from '~/utils/formatters/mapChoiceOptionToValue';
import { replaceZeroAfterNumber } from '~/utils/replaceZeroAfterNumber';

import { DEPOSIT_TYPES } from '../../DepositForm.constants';
import { TGetFieldsParams, TFieldConfig } from '../../DepositForm.types';

import { DEPOSIT_STATUS_TITLES } from './DepositCreateForm.constants';

export const availableStatusOptions = (deposit?: TCreateDepositBody) => {
  const { status, available_transitions: availableTransitions } = deposit || {};
  if (availableTransitions) {
    const available = availableTransitions
      .filter(({ disabled }) => !disabled)
      .map(({ key, title: label }) => ({ value: key, label }));

    return status
      ? [{ label: status.text, value: status.id }].concat(available)
      : available;
  }

  return Object.entries(DEPOSIT_STATUS_TITLES).map(([value, label]) => ({
    value,
    label,
  }));
};

export const getFields = (params?: TGetFieldsParams): TFieldConfig[] => {
  const { countryA, ccy, hiddenFields } = params || {};

  return [
    {
      name: 'deposit_type',
      title: 'Deposit type',
      type: FieldTypes.Select,
      formatter: mapChoiceOptionToText,
      subKey: 'id',
      optionsConfig: {
        options: Object.entries(DEPOSIT_TYPES).map(([value, label]) => ({
          value,
          label,
        })),
      },
    },
    {
      name: 'datetime',
      title: 'Date time',
      type: FieldTypes.DateTimeField,
      size: 'medium',
      formatter: formatDateTime,
    },

    {
      name: 'name',
      title: 'Name',
    },
    {
      name: 'ac_no',
      title: 'Account number',
    },
    {
      name: 'iban',
      title: 'IBAN',
    },
    {
      name: 'swift',
      title: 'SWIFT',
    },

    {
      name: 'country_a',
      title: 'Country (A)',
      subKey: 'id',
      formatter: mapChoiceOptionToText,
      type: FieldTypes.Autocomplete,
      optionsConfig: {
        options: countryA?.options || [],
        isLoading: !!countryA?.isLoading,
      },
    },
    {
      name: 'ccy',
      title: 'Currency',
      subKey: 'id',
      formatter: mapChoiceOptionToText,
      optionsConfig: {
        options: ccy?.options || [],
        isLoading: !!ccy?.isLoading,
      },
      type: FieldTypes.Autocomplete,
      required: true,
    },
    {
      name: 'amount',
      title: 'Amount',
      required: true,
      valueType: 'number',
      formatter: replaceZeroAfterNumber,
    },
    {
      name: 'detail',
      title: 'Details (client notes)',
      type: FieldTypes.Textarea,
    },
    {
      name: 'is_draft',
      title: 'Draft',
      type: FieldTypes.Checkbox,
    },
    {
      name: 'crypto_network',
      title: 'Crypto network',
    },
    {
      name: 'transaction_hash',
      title: 'Transaction hash',
    },
    {
      name: 'crypto_address_from',
      title: 'Crypto address from',
    },
    {
      name: 'crypto_address_to',
      title: 'Crypto address to',
    },
  ].filter((i) => {
    return !hiddenFields?.includes(i.name);
  });
};

export const getConfirmWireFields = (
  deposit: TCreateDepositBody,
): TFieldConfig[] => {
  return [
    {
      name: 'status',
      title: 'Status',
      type: FieldTypes.Select,
      formatter: mapChoiceOptionToText,
      subKey: 'id',
      optionsConfig: {
        options: availableStatusOptions(deposit),
      },
    },
  ];
};
