import { FC, useContext } from 'react';

import { ValueRow } from '~/components/ValueRow';
import { DEFAULT_DATE_TIME_FORMAT, FieldTypes } from '~/constants/common';
import { getFormField } from '~/utils/getFormField';

import { StyledSectionBody } from '../../../../components/EnrtyScreenStructure/SectionBody/SectionBody.styled';
import { ApplicationContext } from '../../../../contexts/ApplicationContext';

export const NextCommunicationDate: FC = () => {
  const { application } = useContext(ApplicationContext);

  const getNextCommunicationDateFields = () => {
    return [
      {
        name: 'next_communication_date',
        title: 'Date & Time',
        editable: true,
        type: FieldTypes.DateTimeField,
        dateFormat: DEFAULT_DATE_TIME_FORMAT,
      },
    ];
  };

  const timezoneOffset = application?.timezone_offset;

  const timezone = `${timezoneOffset ? 'UTC ' : ''}${timezoneOffset} ${
    application?.session_timezone
  }`;

  return (
    <StyledSectionBody className="SectionBody">
      {getNextCommunicationDateFields().map(
        ({ name, title, type, dateFormat }) => {
          return (
            <ValueRow
              key={name}
              label={title}
              valueNode={getFormField({
                name,
                type,
                dateFormat,
              })}
            />
          );
        },
      )}
      {/* by design */}
      <ValueRow label={timezone} valueNode={<span />} />
    </StyledSectionBody>
  );
};
