import { styled, Button } from 'react-ui-kit-exante';

export const StyledActionsRow = styled('div')`
  display: flex;
  align-items: center;
  gap: 24px;
`;

export const StyledFormField = styled('div')`
  min-width: 270px;

  // hack for checkboxes
  .MuiFormControlLabel-root {
    display: flex;
    justify-content: right;
  }
`;

export const StyledManagerLink = styled('a')`
  text-transform: none;
  font-weight: 500;
  color: ${({ theme }) => theme.color.typo.action};
  text-decoration: none;

  &:hover {
    color: ${({ theme }) => theme.color.typo.promo};
    text-decoration: none;
  }
`;

export const StyledManagerRow = styled('div')`
  justify-content: right;
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
`;

export const StyledButtonClientCard = styled(Button)`
  text-transform: none;
  font-weight: 500;
  display: contents;
`;
