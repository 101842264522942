import { DEPOSIT_STATUSES } from '~/api';
import { FieldTypes } from '~/constants/common';
import { formatDateTime } from '~/utils/dates/formatDate';
import { mapChoiceOptionToText } from '~/utils/formatters/mapChoiceOptionToValue';
import { linkAndClick } from '~/utils/linkAndClick';
import { replaceZeroAfterNumber } from '~/utils/replaceZeroAfterNumber';

import { DEPOSIT_TYPES } from '../../DepositForm.constants';
import { TGetFieldsParams, TFieldConfig } from '../../DepositForm.types';

import { StyledButtonClientCard } from './DepositEditForm.styled';

export const getFields = (params?: TGetFieldsParams): TFieldConfig[] => {
  const { countryA, ccy, deposit, hiddenFields } = params || {};

  const isBooked = deposit?.status?.id === DEPOSIT_STATUSES.BOOKED;
  const isFieldEditable = !isBooked;
  const transactionUrl = deposit?.transaction_url;

  return [
    {
      name: 'deposit_type',
      title: 'Deposit type',
      type: FieldTypes.Select,
      formatter: mapChoiceOptionToText,
      subKey: 'id',
      optionsConfig: {
        options: Object.entries(DEPOSIT_TYPES).map(([value, label]) => ({
          value,
          label,
        })),
      },
      editable: Boolean(deposit?.deposit_type) && isFieldEditable,
    },
    {
      name: 'datetime',
      title: 'Date time',
      type: FieldTypes.DateTimeField,
      formatter: formatDateTime,
      editable: isFieldEditable,
    },

    {
      name: 'name',
      title: "Sender's name",
      editable: isFieldEditable,
    },
    {
      name: 'ac_no',
      title: 'Senders Account number',
      editable: isFieldEditable,
    },
    {
      name: 'iban',
      title: 'Senders IBAN',
      editable: isFieldEditable,
    },
    {
      name: 'swift',
      title: 'SWIFT',
    },

    {
      name: 'country_a',
      title: 'Country (A)',
      subKey: 'id',
      formatter: mapChoiceOptionToText,
      type: FieldTypes.Autocomplete,
      optionsConfig: {
        options: countryA?.options || [],
        isLoading: !!countryA?.isLoading,
      },
      editable: isFieldEditable,
    },
    {
      name: 'ccy',
      title: 'Currency',
      subKey: 'id',
      formatter: mapChoiceOptionToText,
      optionsConfig: {
        options: ccy?.options || [],
        isLoading: !!ccy?.isLoading,
      },
      type: FieldTypes.Autocomplete,
      editable: isFieldEditable,
    },
    {
      name: 'amount',
      title: 'Amount',
      editable: isFieldEditable,
      required: isFieldEditable,
      valueType: 'number',
      formatter: replaceZeroAfterNumber,
    },
    {
      name: 'detail',
      title: 'Details (client notes)',
      editable: isFieldEditable,
      type: FieldTypes.Textarea,
    },
    {
      name: 'is_draft',
      title: 'Draft',
      type: FieldTypes.Checkbox,
      editable: isFieldEditable,
    },
    {
      name: 'custody',
      title: 'Custody',
      editable: isFieldEditable,
    },
    {
      name: 'custody_account',
      title: 'Custody account',
      editable: isFieldEditable,
    },
    {
      name: 'bank_name',
      title: 'Client custody',
    },
    {
      name: 'client_custody_account',
      title: 'Client custody account',
      editable: isFieldEditable,
    },
    {
      name: 'bank_deposit_id',
      title: 'Bank Deposit ID',
      editable: deposit?.source !== 'bank-service',
    },
    {
      name: 'internal_id',
      title: 'Internal Id',
      editable: false,
    },
    {
      name: 'crypto_network',
      title: 'Crypto network',
      editable: false,
    },
    {
      name: 'transaction_hash',
      title: 'Transaction hash',
      withCopyButton: true,
      editable: false,
      node: (
        <StyledButtonClientCard
          className="ButtonClientCard"
          color="transparent"
          textColor="action"
          onClick={() => transactionUrl && linkAndClick(transactionUrl, true)}
        >
          {deposit?.transaction_hash}
        </StyledButtonClientCard>
      ),
    },
    {
      name: 'crypto_address_from',
      title: 'Crypto address from',
      withCopyButton: true,
      editable: false,
    },
    {
      name: 'crypto_address_to',
      title: 'Crypto address to',
      withCopyButton: true,
      editable: false,
    },
  ].filter((i) => {
    return !(
      deposit?.hidden_edit_fields?.includes(i.name) ||
      hiddenFields?.includes(i.name)
    );
  });
};
