import { ActionIconButtonStaticProps } from '~/modules/actionButtonsBlock/ActionButton/components';
import { EApplicationActionsType } from '~/pages/ApplicationEntry/ApplicationEntry.constants';

import { ActionIconButtonEnum } from '../Header.types';

export const staticIconButtonPropsMap: Record<
  ActionIconButtonEnum,
  ActionIconButtonStaticProps
> = {
  sendFromPendingBtn: {
    iconName: 'CircleClockIcon',
    label: 'Send to onboarding',
    iconColor: 'action',
  },
  sendAppToOnboardingBtn: {
    iconName: 'CircleClockIcon',
    label: 'Send to onboarding',
    iconColor: 'action',
  },
  sendToClientSupportBtn: {
    iconName: 'CircleSendIcon',
    label: 'Send to Client support',
    iconColor: 'action',
  },
  sendAppToRegistrantBtn: {
    iconName: 'UserCircleIcon',
    label: 'Send to registrant',
    iconColor: 'action',
  },
  loginBtn: {
    iconName: 'LoginIcon',
    label: 'Login',
    iconColor: 'ghost',
  },
  convertBtn: {
    iconName: 'ArrowsClockwiseIcon',
    label: 'Convert to application',
    iconColor: 'action',
  },
  sendToQualifiedBtn: {
    iconName: 'CircleSendIcon',
    label: 'Send to qualified',
    iconColor: 'action',
  },
};

export const iconButtonKeysToActionsTypeMap: Record<
  Exclude<ActionIconButtonEnum, 'loginBtn' | 'convertBtn'>,
  EApplicationActionsType
> = {
  sendToClientSupportBtn: EApplicationActionsType.ToClientSupport,
  sendFromPendingBtn: EApplicationActionsType.FromPending,
  sendAppToOnboardingBtn: EApplicationActionsType.ToOnboarding,
  sendAppToRegistrantBtn: EApplicationActionsType.FromPendingToRegistrant,
  sendToQualifiedBtn: EApplicationActionsType.FromPendingToQualified,
};
