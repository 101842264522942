import { FC, useContext, useEffect, useMemo } from 'react';
import { IconButton } from 'react-ui-kit-exante';

import { useLazyGetTagsChoicesQuery } from '~/api';
import { ValueRow } from '~/components/ValueRow';
import { FieldTypes, getFieldType } from '~/constants/common';
import { getFormField } from '~/utils/getFormField';

import { getTagValue } from '../../../ApplicationEntry.helpers';
import { ApplicationContext } from '../../../contexts/ApplicationContext';
import { ApplicationFormContext } from '../../../contexts/FormContext';
import {
  StyledDeleteButtonOffset,
  StyledFormField,
} from '../SectionBody/SectionBody.styled';

import { TTagProps } from './Tag.types';

export const Tag: FC<TTagProps> = ({ tag, withoutText, tagKey }) => {
  const [getTagsChoices, tagChoicesState] = useLazyGetTagsChoicesQuery();
  const { application } = useContext(ApplicationContext);
  const { onRemoveField, newTags, tagsForDeleteIds } = useContext(
    ApplicationFormContext,
  );
  const {
    readonly,
    tagvalue_set: tagValueSet,
    id,
    title,
    name,
    choices,
    is_multiple: isMultiple,
    editable_choices: editableChoices,
    auto_assign: autoAssign,
  } = tag;
  const tValue = tagValueSet?.[0];

  const fieldType = getFieldType(tag);

  const isEditable = !(
    readonly ||
    tValue?.readonly ||
    tValue?.is_can_edit === false
  );

  const normalizedChoices = useMemo(() => {
    if (choices?.length) {
      return choices.split(',').map((i) => ({
        value: i,
        label: i,
      }));
    }
    return null;
  }, [choices]);

  useEffect(() => {
    const appId = application?.application?.id;
    if (appId && isEditable) {
      if (
        !normalizedChoices &&
        [FieldTypes.Select, FieldTypes.Autocomplete].includes(fieldType)
      ) {
        getTagsChoices({
          contentType: 22,
          objectId: appId,
          tagId: id,
        });
      }
    }
  }, [
    application?.application.id,
    normalizedChoices,
    fieldType,
    id,
    isEditable,
  ]);

  const onRemoveTag = () => {
    onRemoveField(id);
  };

  const isOtherGroupTag = tagKey === 'other';

  const isTagRemovable = useMemo(() => {
    if (isOtherGroupTag && tValue?.removable && !autoAssign) {
      return true;
    }

    return (
      newTags &&
      Object.values(newTags)
        .flat()
        .find((t) => t.id === id)
    );
  }, [tValue, newTags]);

  if (tagsForDeleteIds?.includes(id)) {
    return null;
  }

  if (!isEditable) {
    return withoutText ? (
      getFormField({
        type: fieldType,
        name,
        options: normalizedChoices ?? tagChoicesState.data,
        disabled: true,
        isMultiple,
        freeSolo: editableChoices,
        label: title,
        size: 'medium',
        clearable: true,
      })
    ) : (
      <ValueRow
        key={id}
        label={title || name}
        value={getTagValue(tag)}
        withRightOffset={isOtherGroupTag}
      />
    );
  }

  return (
    <div key={id}>
      {withoutText ? (
        getFormField({
          type: fieldType,
          name,
          options: normalizedChoices ?? tagChoicesState.data,
          disabled: tagChoicesState.isLoading,
          isMultiple,
          freeSolo: editableChoices,
          label: title,
          size: 'medium',
          clearable: true,
        })
      ) : (
        <ValueRow
          key={id}
          label={title || name}
          valueNode={
            <StyledFormField className="FormField">
              {getFormField({
                type: fieldType,
                name,
                options: normalizedChoices ?? tagChoicesState.data,
                disabled: tagChoicesState.isLoading,
                isMultiple,
                freeSolo: editableChoices,
                clearable: true,
              })}
              {isOtherGroupTag && (
                <StyledDeleteButtonOffset className="DeleteButtonOffset">
                  {isTagRemovable && (
                    <IconButton
                      iconName="DeleteIcon"
                      iconSize={18}
                      onClick={onRemoveTag}
                    />
                  )}
                </StyledDeleteButtonOffset>
              )}
            </StyledFormField>
          }
        />
      )}
    </div>
  );
};
