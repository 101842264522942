import {
  FilterType,
  IOnFetchArguments,
  TAdditionalFilter,
} from 'react-ui-kit-exante';

import {
  TAdditionalFilterOption,
  TAdditionalFiltersHandlers,
  TChoiceOption,
  TTableFilter,
  TTableFilters,
} from '~/types/table';

import { TFilter } from '../../api/personalSettings/personalSettings.types';
import { MAX_BIRTH_DATE, MIN_BIRTH_DATE } from '../../constants/common';
import { paramsSerializer } from '../apiRequest/helpers';
import { formatDate } from '../dates/formatDate';

const birthFilters = ['birthDate', 'tag_date_date of birth'];

export const mapFilterFieldTypes: Record<string, FilterType | ''> = {
  flag: 'checkbox',
  input: 'textInput',
  textarea: 'textInput',
  text: 'textInput',
  choice: 'multiSelect',
  event: 'multiSelect',
  date: 'dateRange',
  money: 'numberRange',
  manager: 'multiSelect',
  numeric: 'numberRange',
};

export const getUniqueKeyForFiltersChoices = (filter?: TTableFilter) => {
  return `${filter?.choice_url_relative}${
    Object.keys(filter?.choice_query_params || {})?.length
      ? paramsSerializer(filter?.choice_query_params as Record<string, unknown>)
      : ''
  }`;
};

export const mapChoiceToFilterOptions = (
  choices: string | TChoiceOption[] = [],
): { label: string; value: string }[] => {
  if (typeof choices === 'string' && choices.trim() !== '') {
    return choices.split(',').map((choice) => {
      const trimmedChoice = choice.trim();
      return { label: trimmedChoice, value: trimmedChoice };
    });
  }
  if (Array.isArray(choices)) {
    return choices.map((choice) => ({
      label: choice.text,
      value: choice.id,
    }));
  }
  return [];
};

export const getAdditionalFilters = ({
  onFilter,
  onRemove,
  filters,
  additionalOptions,
  withContextSearch,
}: TAdditionalFiltersHandlers & {
  filters?: TTableFilters;
  additionalOptions?: TAdditionalFilterOption;
  withContextSearch?: boolean;
}): TAdditionalFilter<Record<string, unknown>>[] => {
  const contextSearchFilter = {
    Header: 'Context search',
    accessor: 'search',
    type: mapFilterFieldTypes.input,
    onFilter,
    onRemove,
    negative: false,
  };

  return [
    ...(filters?.map((filter) => {
      const type = mapFilterFieldTypes[filter.type];

      const additionalProps: Record<string, any> = {};

      if (birthFilters.includes(filter.id)) {
        additionalProps.minDate = MIN_BIRTH_DATE;
        additionalProps.maxDate = MAX_BIRTH_DATE;
      }
      const uniqueKey = getUniqueKeyForFiltersChoices(filter);

      return {
        Header: filter.text || filter.title,
        accessor: filter.id,
        type,
        filterOptions: mapChoiceToFilterOptions(filter.choices),
        onFilter,
        onRemove,
        ...(type === 'dateRange' ? { formatting: 'dateTimeUTC' } : {}),
        ...(filter.choice_url_relative
          ? { filterOptions: additionalOptions?.[uniqueKey] }
          : {}),
        ...additionalProps,
      };
    }) || []),
    ...(withContextSearch ? [contextSearchFilter] : []),
  ];
};

export const getFilterParams = (
  params: Omit<
    IOnFetchArguments,
    'paginationParams' | 'params' | 'sortingParams'
  >,
  datesFields?: string[],
) => {
  const filtersParams = { ...params.filtersParams };

  datesFields?.forEach((item) => {
    if (filtersParams[item]) {
      filtersParams[item] = (filtersParams[item] as [])
        .map((value) => {
          if (value) {
            if (!(filtersParams[item] as unknown[])[0]) {
              return value ? `_${formatDate(value)}` : `_${value}`;
            }
            if (!(filtersParams[item] as unknown[])[1]) {
              return value ? `${formatDate(value)}_` : `${value}_`;
            }
          }

          return value ? formatDate(value) : value;
        })
        .filter(Boolean)
        .join('_');
    }
  });

  return { ...filtersParams };
};

export const transformFilterArraysToString = (
  filters: Record<string, unknown>,
  separator?: string,
): Record<string, unknown> => {
  return Object.entries(filters).reduce((acc, [key, value]) => {
    acc[key] = Array.isArray(value) ? value.join(separator || ',') : value;
    return acc;
  }, {} as Record<string, unknown>);
};

export const sortFiltersByName = (filters: TFilter[]) =>
  filters.slice().sort((a, b) => a.name.localeCompare(b.name));
