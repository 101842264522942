import { ActionButtonWithConfirmationWithSelectStaticProps } from '~/modules/actionButtonsBlock/ActionButton/components';
import { EApplicationActionsType } from '~/pages/ApplicationEntry/ApplicationEntry.constants';

import { ActionButtonWithConfirmationWithSelectEnum } from '../Header.types';

export const staticButtonWithConfirmationWithSelectPropsMap: Record<
  ActionButtonWithConfirmationWithSelectEnum,
  ActionButtonWithConfirmationWithSelectStaticProps
> = {
  blockBtn: {
    title: 'Close Relationship',
    confirmText: 'Close Relationship',
    autocompleteStaticProps: {
      isMultiple: true,
      disableClearable: true,
      placeholder: 'Closure reason',
    },
    commentStaticProps: {
      className: 'CommentInput',
      label: 'Comment',
      multiline: true,
      fullWidth: true,
      placeholder: 'Closure Details',
    },
    childrenStaticProps: {
      iconName: 'BlockAccountIcon',
      iconColor: 'radical',
      label: 'Close Relationship',
    },
  },
  sendAppToOnboardingWithSelectBtn: {
    title: 'Send to onboarding',
    confirmText: 'Send to onboarding',
    autocompleteStaticProps: {
      disableClearable: true,
      placeholder: 'Closure reason',
    },
    commentStaticProps: {
      className: 'CommentInput',
      label: 'Comment',
      multiline: true,
      fullWidth: true,
      placeholder: 'Closure Details',
    },
    childrenStaticProps: {
      iconName: 'CircleClockIcon',
      label: 'Send to onboarding',
      iconColor: 'action',
    },
  },
};

export const clientSupportOnboardingReasonOptions = [
  {
    label: 'Client-support check is completed',
    value: 'Client-support check is completed',
  },
  {
    label: 'Client-Support check is skipped - Large AUM',
    value: 'Client-Support check is skipped - Large AUM',
  },
  {
    label: 'Client-Support check is skipped - Complex case',
    value: 'Client-Support check is skipped - Complex case',
  },
  {
    label: 'Client-Support check is skipped - Golden case',
    value: 'Client-Support check is skipped - Golden case',
  },
];

export const buttonWithConfirmationWithSelectKeysToActionsTypeMap: Record<
  ActionButtonWithConfirmationWithSelectEnum,
  EApplicationActionsType
> = {
  blockBtn: EApplicationActionsType.Block,
  sendAppToOnboardingWithSelectBtn: EApplicationActionsType.FromPending,
};
