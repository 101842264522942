import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IconButton } from 'react-ui-kit-exante';

import {
  useApplicationActionsMutation,
  useLazyLoginActionQuery,
  useLazyConvertToApplicationQuery,
  useSendMessageToClientMutation,
  useLazyGetApplicationActionsInfoQuery,
  useLazyGetApplicationTagsQuery,
  useSaveApplicationMutation,
  useRejectApplicationMutation,
} from '~/api';
import { ActionWithConfirmation } from '~/components/ConfirmationComponents/ActionWithConfirmation';
import { ActionWithConfirmationWithSelect } from '~/components/ConfirmationComponents/ActionWithConfirmationWithSelect';
import { EntryScreenHeader } from '~/components/EntryScreen/EntryScreenHeader';
import { StyledAutocomplete } from '~/pages/ApplicationEntry/components/Managers/Managers.styled';
import { ApplicationContext } from '~/pages/ApplicationEntry/contexts/ApplicationContext';
import { PATHS } from '~/router';
import { IOption } from '~/types/form/options';

import { TLocationState } from '../../../../types/location';
import { paramsSerializer } from '../../../../utils/apiRequest/helpers';
import { EApplicationActionsType } from '../../ApplicationEntry.constants';
import { StyledCommentInput } from '../../ApplicationEntry.styled';

import {
  StyledApplicationClosedForm,
  StyledApplicationFilter,
  StyledApplicationFilters,
  StyledApplicationWrapper,
} from './Header.styled';

export const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { requestParams } = (location.state as TLocationState) || {};

  const {
    application,
    isLoadingApplication,
    activeLegalEntity,
    setActiveLegalEntity,
  } = useContext(ApplicationContext);

  const [applicationActions, stateApplicationActions] =
    useApplicationActionsMutation();
  const [loginAction, stateLoginAction] = useLazyLoginActionQuery();
  const [convertAction, stateConvertAction] =
    useLazyConvertToApplicationQuery();
  const [sendMessage] = useSendMessageToClientMutation();
  const [getActionsInfo, stateActionsInfo] =
    useLazyGetApplicationActionsInfoQuery();
  const [rejectApplication, stateRejectApplication] =
    useRejectApplicationMutation();
  const [getApplicationTagsQuery, state] = useLazyGetApplicationTagsQuery();
  const [saveApplication] = useSaveApplicationMutation();

  const [activeConfirm, setActiveConfirm] = useState('');
  const [blockBtnAnchorEl, setBlockBtnAnchorEl] = useState<null | HTMLElement>(
    null,
  );
  const [messageToClient, setMessageToClient] = useState('');
  const [commentForPending, setCommentForPending] = useState('');
  const [closureReason, setClosureReason] = useState<IOption[]>();
  const [closureDetails, setClosureDetails] = useState('');
  const [rejectReason, setRejectReason] = useState('');

  const onCloseEntry = () => {
    const { sorting, limit, skip, page, ...rest } = requestParams || {};
    navigate(`${PATHS.USERS}?${paramsSerializer(rest)}`);
  };

  const applicationData = application?.application;
  const applicationId = applicationData?.id || 0;
  const isLoadingActions =
    isLoadingApplication ||
    stateApplicationActions.isLoading ||
    stateLoginAction.isLoading ||
    stateConvertAction.isLoading ||
    stateRejectApplication.isLoading;

  const dataActions = stateActionsInfo.data?.actions;

  const infoHiddenActions: Record<string, boolean> =
    dataActions?.reduce(
      (prev, { name, hidden }) => ({ ...prev, ...{ [name]: hidden } }),
      {},
    ) || {};

  const pendingReasonData = state.data?.filter(
    ({ name }) => name === 'pending_reason',
  )[0]?.tags[0];
  const otherData =
    state.data?.filter(({ name }) => name === 'other')[0]?.tags || [];
  const closureReasonData = otherData.filter(
    (item) => item?.name === 'closure reason',
  )[0];
  const closureDetailsData = otherData.filter(
    (item) => item?.name === 'closure details',
  )[0];

  const closedOptions =
    closureReasonData?.choices
      ?.split(',')
      ?.map((item: string) => ({ value: item, label: item })) || [];

  const closureReasonTagValue = closureReasonData?.tagvalue_set[0];

  const defaultClosureReason =
    closureReasonTagValue &&
    Array.isArray(closureReasonTagValue.t_value) &&
    closureReasonTagValue.t_value.map(({ id }) => ({
      value: id,
      label: id,
    }));
  const defaultClosureDetails = closureDetailsData?.tagvalue_set[0]
    ?.t_value as string;
  const defaultPendingReason = pendingReasonData?.tagvalue_set[0]
    ?.t_value as string;

  const getTags = () => {
    if (applicationId) {
      getApplicationTagsQuery({
        objectId: applicationId,
        sectionName: 'other, pending_reason',
        legalEntity: activeLegalEntity,
      });
    }
  };

  useEffect(() => {
    if (applicationId) {
      getTags();
      getActionsInfo({ applicationId });
    }
  }, []);

  useEffect(() => {
    if (defaultClosureReason) {
      setClosureReason(defaultClosureReason);
    }
    if (defaultClosureDetails) {
      setClosureDetails(defaultClosureDetails);
    }
    if (defaultPendingReason) {
      setCommentForPending(defaultPendingReason);
    }
  }, [state.data]);

  const handleApprove = () =>
    applicationActions({
      applicationId,
      action: EApplicationActionsType.Approve,
      successMessage: 'Application approved',
    });

  const handleUnapprove = () =>
    applicationActions({
      applicationId,
      action: EApplicationActionsType.Unapprove,
      successMessage: 'Application unapproved',
    });

  const handleSendAppToOnboarding = () =>
    applicationActions({
      applicationId,
      action: EApplicationActionsType.ToOnboarding,
      successMessage: 'Application sent to onboarding',
    });

  const handleSendAppToPending = () => {
    applicationActions({
      applicationId,
      action: EApplicationActionsType.ToPending,
      successMessage: 'Application sent to pending',
    });

    if (pendingReasonData) {
      saveApplication({
        applicationId,
        params: {
          tags: [
            {
              id: pendingReasonData.tagvalue_set[0]?.id,
              tag_id: pendingReasonData?.id,
              text_value: commentForPending,
            },
          ],
        },
      });
    }
  };

  const handleOnPendingComment = (e: React.ChangeEvent<HTMLInputElement>) =>
    setCommentForPending(e.target.value);

  const handleSendAppToRegistrant = () =>
    applicationActions({
      applicationId,
      action: EApplicationActionsType.FromPendingToRegistrant,
      successMessage: 'Application sent to registrant',
    });

  const handleDelete = () =>
    applicationActions({
      applicationId,
      action: EApplicationActionsType.Delete,
      successMessage: 'Application deleted',
    });

  const redirectToLogin = async () => {
    const { data } = await loginAction({
      applicationId,
    });

    const url = data?.url;

    if (url) {
      window.open(url, '_blank');
    }
  };

  const openBlockConfirm = (event: React.MouseEvent<HTMLElement>) => {
    setActiveConfirm('block');
    setBlockBtnAnchorEl(event.currentTarget);
  };

  const closeBlockConfirm = () => {
    setActiveConfirm('');
    setBlockBtnAnchorEl(null);
  };

  const handleOnClosureReason = (_: any, value: IOption[]) =>
    setClosureReason(value);

  const handleClosureDetails = (e: React.ChangeEvent<HTMLInputElement>) =>
    setClosureDetails(e.target.value);

  const handleBlockBtn = () => {
    saveApplication({
      applicationId,
      params: {
        tags: [
          {
            id: closureReasonData?.tagvalue_set[0]?.id,
            tag_id: closureReasonData?.id,
            text_value: closureReason
              ?.map(({ value }: { value: string }) => value)
              .join(),
          },
          {
            id: closureDetailsData?.tagvalue_set[0]?.id,
            tag_id: closureDetailsData?.id,
            text_value: closureDetails,
          },
        ],
      },
    });

    applicationActions({
      applicationId,
      action: EApplicationActionsType.Block,
      successMessage: 'Application blocked',
    });

    setActiveConfirm('');
  };

  const handleUnblock = () =>
    applicationActions({
      applicationId,
      action: EApplicationActionsType.Unblock,
      successMessage: 'Application unblocked',
    });

  const handleConvert = () =>
    convertAction({
      applicationId,
    });

  const handleSendToQualified = () =>
    applicationActions({
      applicationId,
      action: EApplicationActionsType.FromPendingToQualified,
      successMessage: 'Application sent to qualified',
    });

  const handleSendMessage = () => {
    sendMessage({
      applicationId,
      params: {
        locale: applicationData?.current_locale || 'en',
        message_text: messageToClient,
      },
    });
    setMessageToClient('');
  };

  const handleMessage = (e: React.ChangeEvent<HTMLInputElement>) =>
    setMessageToClient(e.target.value);

  const handleOnRejectComment = (e: React.ChangeEvent<HTMLInputElement>) =>
    setRejectReason(e.target.value);

  const handleConfirmRejectApplication = () => {
    rejectApplication({
      applicationId,
      review_text: rejectReason,
    });

    setRejectReason('');
  };

  const handleOnRejectClose = () => setRejectReason('');

  const handleSendAppFromPending = () =>
    applicationActions({
      applicationId,
      action: EApplicationActionsType.FromPending,
      successMessage: 'Application sent from pending',
    });

  const sendFromPendingBtn = (
    <IconButton
      key="sendFromPendingBtn"
      iconSize={32}
      iconName="CircleClockIcon"
      label="Send to onboarding"
      iconColor="action"
      onClick={handleSendAppFromPending}
      disabled={isLoadingActions}
    />
  );

  const rejectBtn = (
    <ActionWithConfirmation
      key="rejectBtn"
      withCloseAfterConfirmation
      placement="bottom"
      title="Reject application"
      cancelButtonNameKey="Cancel"
      confirmButtonNameKey="Reject"
      disabledConfirmBtn={!rejectReason}
      onConfirm={handleConfirmRejectApplication}
      closeHandler={handleOnRejectClose}
      content={
        <StyledCommentInput
          multiline
          label="Comment"
          className="CommentInput"
          value={rejectReason}
          onChange={handleOnRejectComment}
        />
      }
    >
      <IconButton
        iconSize={32}
        iconName="ClearDateIcon"
        label="Reject"
        iconColor="radical"
        disabled={isLoadingActions}
      />
    </ActionWithConfirmation>
  );

  const approveBtn = (
    <ActionWithConfirmation
      key="approveBtn"
      withCloseAfterConfirmation
      placement="bottom"
      cancelButtonNameKey="Cancel"
      confirmButtonNameKey="Approve"
      onConfirm={handleApprove}
      title="Please, confirm"
      disabled={isLoadingActions}
    >
      <IconButton
        iconName="CircleCheckIcon"
        iconSize={32}
        iconColor="action"
        title="Approve"
        label="Approve"
        disabled={isLoadingActions}
      />
    </ActionWithConfirmation>
  );

  const handleUnapproveBtn = (
    <ActionWithConfirmation
      key="handleUnapproveBtn"
      withCloseAfterConfirmation
      placement="bottom"
      cancelButtonNameKey="Cancel"
      confirmButtonNameKey="Unapprove"
      onConfirm={handleUnapprove}
      title="Please, confirm"
      disabled={isLoadingActions}
    >
      <IconButton
        iconName="CircleCloseIcon"
        iconColor="radical"
        label="Unapprove"
        iconSize={32}
        disabled={isLoadingActions}
      />
    </ActionWithConfirmation>
  );

  const sendAppToOnboardingBtn = (
    <IconButton
      key="sendAppToOnboardingBtn"
      iconSize={32}
      iconName="CircleClockIcon"
      label="Send to onboarding"
      iconColor="action"
      onClick={handleSendAppToOnboarding}
      disabled={isLoadingActions}
    />
  );

  const sendAppToPendingBtn = (
    <ActionWithConfirmation
      key="sendAppToPendingBtn"
      withCloseAfterConfirmation
      placement="bottom"
      cancelButtonNameKey="Cancel"
      confirmButtonNameKey="Send to pending"
      disabledConfirmBtn={!commentForPending}
      onConfirm={handleSendAppToPending}
      title="Pending reason"
      content={
        <StyledCommentInput
          multiline
          label="Comment"
          className="CommentInput"
          value={commentForPending}
          onChange={handleOnPendingComment}
        />
      }
    >
      <IconButton
        iconSize={32}
        iconName="ClockIcon"
        label="Send to pending"
        iconColor="warning"
        disabled={isLoadingActions}
      />
    </ActionWithConfirmation>
  );

  const sendAppToRegistrantBtn = (
    <IconButton
      key="sendAppToRegistrantBtn"
      iconSize={32}
      iconName="UserCircleIcon"
      label="Send to registrant"
      iconColor="action"
      onClick={handleSendAppToRegistrant}
      disabled={isLoadingActions}
    />
  );

  const deleteBtn = (
    <ActionWithConfirmation
      key="deleteBtn"
      withCloseAfterConfirmation
      placement="bottom"
      cancelButtonNameKey="Cancel"
      confirmButtonNameKey="Delete"
      onConfirm={handleDelete}
      title="Do you want to delete the user?"
      disabled={isLoadingActions}
    >
      <IconButton
        iconName="DeleteIcon"
        iconColor="radical"
        label="Delete"
        iconSize={32}
        disabled={isLoadingActions}
      />
    </ActionWithConfirmation>
  );

  const loginBtn = (
    <IconButton
      key="loginBtn"
      iconSize={32}
      iconName="LoginIcon"
      label="Login"
      iconColor="ghost"
      onClick={redirectToLogin}
      disabled={isLoadingActions}
    />
  );

  const renderBlockConfirm = (
    <StyledApplicationClosedForm className="ApplicationClosedForm">
      <StyledAutocomplete
        isMultiple
        disableClearable
        options={closedOptions}
        placeholder="Closure reason"
        value={closureReason}
        onChange={handleOnClosureReason}
      />
      <StyledCommentInput
        className="CommentInput"
        label="Comment"
        onChange={handleClosureDetails}
        value={closureDetails}
        multiline
        fullWidth
        placeholder="Closure Details"
      />
    </StyledApplicationClosedForm>
  );

  const blockBtn = (
    <ActionWithConfirmationWithSelect
      key="onRejectBtn"
      open={activeConfirm === 'block'}
      anchorEl={blockBtnAnchorEl}
      onClose={closeBlockConfirm}
      title="Close Relationship"
      content={renderBlockConfirm}
      confirmText="Close Relationship"
      handleConfirm={handleBlockBtn}
      handleClose={closeBlockConfirm}
      disabledConfirm={isLoadingActions}
    >
      <IconButton
        iconName="BlockAccountIcon"
        iconColor="radical"
        label="Close Relationship"
        iconSize={32}
        disabled={isLoadingActions}
        onClick={openBlockConfirm}
      />
    </ActionWithConfirmationWithSelect>
  );

  const unblockBtn = (
    <ActionWithConfirmation
      key="unblockBtn"
      withCloseAfterConfirmation
      placement="bottom"
      cancelButtonNameKey="Cancel"
      confirmButtonNameKey="Unblock"
      onConfirm={handleUnblock}
      content="Do you want to unblock and restore the permissions to the site and ATP and restore the demo access?"
      disabled={isLoadingActions}
    >
      <IconButton
        iconName="ReadyForActivationIcon"
        iconColor="action"
        label="Unblock"
        iconSize={32}
        disabled={isLoadingActions}
      />
    </ActionWithConfirmation>
  );

  const convertBtn = (
    <IconButton
      key="convertBtn"
      iconSize={32}
      iconName="ArrowsClockwiseIcon"
      label="Convert to application"
      iconColor="action"
      onClick={handleConvert}
      disabled={isLoadingActions}
    />
  );

  const sendToQualifiedBtn = (
    <IconButton
      key="sendToQualifiedBtn"
      iconSize={32}
      iconName="CircleSendIcon"
      label="Send to qualified"
      iconColor="action"
      onClick={handleSendToQualified}
      disabled={isLoadingActions}
    />
  );

  const sendMessageBtn = (
    <ActionWithConfirmation
      key="sendMessageBtn"
      withCloseAfterConfirmation
      placement="bottom"
      cancelButtonNameKey="Cancel"
      confirmButtonNameKey="Send"
      disabledConfirmBtn={!messageToClient}
      onConfirm={handleSendMessage}
      title="Send message"
      content={
        <StyledCommentInput
          multiline
          className="CommentInput"
          label="Comment"
          onChange={handleMessage}
          value={messageToClient}
        />
      }
    >
      <IconButton
        iconSize={32}
        iconName="SendMessageIcon"
        label="Send message"
        iconColor="ghost"
        disabled={isLoadingActions}
      />
    </ActionWithConfirmation>
  );

  const actions = [
    infoHiddenActions.approve ? null : approveBtn,
    infoHiddenActions.unapprove ? null : handleUnapproveBtn,
    infoHiddenActions.send_to_pending ? null : sendAppToPendingBtn,
    infoHiddenActions.send_to_onboarding ? null : sendAppToOnboardingBtn,
    infoHiddenActions.send_from_pending ? null : sendFromPendingBtn,
    infoHiddenActions.send_to_registrant ? null : sendAppToRegistrantBtn,
    infoHiddenActions.convert_to_application ? null : convertBtn,
    infoHiddenActions.send_to_qualified ? null : sendToQualifiedBtn,
    infoHiddenActions.reject ? null : rejectBtn,
    infoHiddenActions.send_message ? null : sendMessageBtn,
    infoHiddenActions.login ? null : loginBtn,
    infoHiddenActions.delete ? null : deleteBtn,
    infoHiddenActions.unblock ? null : unblockBtn,
    infoHiddenActions.close_relationship ? null : blockBtn,
  ].filter(Boolean);

  const title = (
    <StyledApplicationWrapper className="ApplicationWrapper">
      {application?.full_name || applicationData?.username}
      <StyledApplicationFilters className="ApplicationFilters">
        {applicationData?.legal_entities.map((item) => {
          return (
            <StyledApplicationFilter
              className="ApplicationFilter"
              key={item}
              isActive={activeLegalEntity === item}
              onClick={() =>
                setActiveLegalEntity(activeLegalEntity === item ? '' : item)
              }
            >
              {item}
            </StyledApplicationFilter>
          );
        })}
      </StyledApplicationFilters>
    </StyledApplicationWrapper>
  );

  return (
    <EntryScreenHeader
      title={title}
      onClose={onCloseEntry}
      actions={dataActions ? actions : []}
    />
  );
};
