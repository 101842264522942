import { FC, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { EntryScreenHeader } from '~/components/EntryScreen/EntryScreenHeader';
import { PATHS } from '~/router';
import { TLocationState } from '~/types/location';
import { paramsSerializer } from '~/utils/apiRequest/helpers';

import { TDepositHeaderProps } from './DepositHeader.types';
import { useGetActions } from './hooks/useGetActions';

export const DepositHeader: FC<TDepositHeaderProps> = ({ deposit }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { requestParams } = (location.state as TLocationState) || {};

  const isCreate = location.state?.fromApplication;

  const onCloseEntry = useCallback(() => {
    if (isCreate) {
      return navigate(-1);
    }

    const { sorting, limit, skip, page, ...rest } = requestParams || {};

    navigate(`${PATHS.DEPOSITS}?${paramsSerializer(rest)}`);
  }, [navigate, requestParams]);

  const actions = useGetActions(deposit, onCloseEntry);
  return (
    <EntryScreenHeader
      title={deposit?.username}
      onClose={onCloseEntry}
      actions={actions}
    />
  );
};
