import { useContext, useEffect } from 'react';

import { useLazyGetApplicationTagsQuery } from '~/api';
import { ApplicationContext } from '~/pages/ApplicationEntry/contexts/ApplicationContext';

export const useGetApplicationTagsData = () => {
  const { application, activeLegalEntity } = useContext(ApplicationContext);
  const applicationId = application?.application?.id;

  const [getApplicationTagsQuery, { data: tagsData }] =
    useLazyGetApplicationTagsQuery();

  useEffect(() => {
    if (applicationId) {
      getApplicationTagsQuery({
        objectId: applicationId,
        sectionName: 'other, pending_reason',
        legalEntity: activeLegalEntity,
      });
    }
  }, [activeLegalEntity, applicationId, getApplicationTagsQuery]);

  const pendingReasonData = tagsData?.find(
    ({ name }) => name === 'pending_reason',
  )?.tags[0];

  const otherData = tagsData?.find(({ name }) => name === 'other')?.tags ?? [];

  const closureReasonData = otherData.find(
    (item) => item?.name === 'closure reason',
  );
  const closureDetailsData = otherData.find(
    (item) => item?.name === 'closure details',
  );

  const closureReasonTagValue = closureReasonData?.tagvalue_set[0]?.t_value;
  const closureReasonId = closureReasonData?.tagvalue_set[0]?.id;
  const closureReasonTagId = closureReasonData?.id;

  const defaultClosureReason =
    Array.isArray(closureReasonTagValue) &&
    closureReasonTagValue.map(({ id }) => ({
      value: id,
      label: id,
    }));
  const defaultClosureDetails = closureDetailsData?.tagvalue_set[0]?.t_value;
  const defaultClosureDetailsId = closureDetailsData?.tagvalue_set[0]?.id;
  const defaultClosureDetailsTagId = closureDetailsData?.id;

  const defaultPendingReason = pendingReasonData?.tagvalue_set[0]?.t_value;
  const defaultPendingReasonId = pendingReasonData?.tagvalue_set[0]?.id;
  const defaultPendingReasonTagId = pendingReasonData?.id;

  const closedOptions =
    closureReasonData?.choices
      ?.split(',')
      .map((item: string) => ({ value: item, label: item })) ?? [];

  return {
    closedOptions,
    closureReasonId,
    closureReasonTagId,
    defaultClosureDetailsId,
    defaultClosureDetailsTagId,
    defaultClosureDetails,
    defaultClosureReason,
    defaultPendingReason,
    defaultPendingReasonTagId,
    defaultPendingReasonId,
  };
};
