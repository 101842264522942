export const DATE_ACCESSORS = new Set([
  'fromTo',
  'fromDate',
  'toDate',
  'date',
  'creationDate',
  'createdAt',
  'created',
  'time',
]);
