export const mapTabsUrls = {
  // actions: 'info',

  // 'app-onboarding': 'onboarding',
  // onboarding_settings_subtab: 'onboarding_settings_subtab',
  // 'affiliated-subtab': 'affiliated-subtab',
  // 'monitoring-subtab': 'monitoring-subtab',
  // 'risk_scoring-subtab': 'risk_scoring-subtab',

  'qualified-lead': 'qualified-lead',

  user_settings: 'user_settings',
  // 'access-rights-subtab': 'access-rights-subtab',
  'account-permissions-subtab': 'account-permissions-subtab',
  'password-management-subtab': 'password-management-subtab',
  'notifications-subtab': 'notifications-subtab',

  activity: 'activity',
  'trades-subtab': 'trades-subtab',
  'transactions-subtab': 'transactions-subtab',
  'notifications-activity-subtab': 'notifications-activity-subtab',
  'audit-logs-subtab': 'audit-logs-subtab',
};
