import { object, string } from 'yup';

import { TTableSectionColumn } from '~/api/applications/applications.types';

export const getValidationScheme = (fields?: TTableSectionColumn[]) => {
  const values: Record<string, any> = {};

  const expectedDepositWithdrawalsField = fields?.find(
    (field) => field.title === 'Expected deposits/ withdrawals',
  );
  if (expectedDepositWithdrawalsField) {
    return object({
      [expectedDepositWithdrawalsField.id]: string().required(
        'Expected deposits/ withdrawals is a required field',
      ),
    });
  }

  return object({ ...values });
};
